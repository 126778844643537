/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import Step from '../../view/shared/step';

/**
 * Check div#sales-flow-stepper (rendered by middleman or opentext) and resolve current step
 * While sales flow is dispatched based on current step, this class is not part of frontend
 *
 * Animates, too
 */
export default class Stepper extends Renderable<string> {

    private _element: JQuery;

    protected _currentStep: string;
    private _previousStep: string;

    protected _stepNames: string[] = [];

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-stepper');

        /**
         * Before setting the new active step, set the previous from value of the storage var
         */
        const storage = injector.getStorage();
        this._previousStep = storage.getItem('lastStep');
        storage.setItem('lastStep', '' + this.getInjector().getRouting().getCurrentPage());

        this._currentStep = this.getInjector().getRouting().getCurrentPage();

    }

    get previousStep (): string {
        return this._previousStep;
    }

    protected setDeviceDetailUrl (url: string) {

        this._element.find('a[data-name="device_detail"]').attr('href', url);

    }

    protected getData (): { steps: Step[], basket: Step } {

        let activeIndex: number|null = null;

        if (!this.getInjector().getStorage().hasItem('wasOnRecommendationPage') || this.getInjector().getStorage().getItem('wasOnRecommendationPage') !== 'true') {
            this._stepNames = this._stepNames.filter((name, index) => {
                return name !== 'vvl_recommendation';
            });
        }

        const steps: Step[] = this._stepNames.map((name, index) => {

            const text = this.getInjector().getStrings().get('stepper.' + name);
            let href = this.getInjector().getOptions().get(name + '_url');

            if ('device_detail' === name) {
                href = this.getInjector().getOptions().get(name + '_prefix');
            }

            let status;
            if (this._currentStep === this._stepNames[index]) {
                activeIndex = index;
                status = 'active';
            }

            if (activeIndex !== null && activeIndex < index) {
                status = 'inactive';
            }

            if (activeIndex > index || activeIndex === null) {
                status = 'completed';
            }

            return new Step(name, text, href, status, index + 1);

        });

        const text = this.getInjector().getStrings().get('stepper.basket');
        const href = this.getInjector().getOptions().get('basket_url');
        let active = 'inactive';
        if (this._currentStep === 'basket') {
            active = 'active';
        }
        const basket = new Step('basket', text, href, active, this._stepNames.length + 1);

        return {steps: steps, basket: basket};

    }

    public render (exposed = ''): void {

        const data = this.getData();

        this._element.addClass(this._currentStep);

        // Adding custom Css Class to stepper via options
        const stepperClass = this.getInjector().getOptions().get('stepper_class');
        this._element.addClass(stepperClass);

        this._element.html(this.getInjector().getTemplates().render('stepper', data));

        if (false === this.getInjector().getStorage().canPersist()) {

            this._element.before(this.getInjector().getTemplates().render('cookie_warning', {}));

        }

    }

    public events (): void {

        // make inactive links unclickable
        this._element.find('a').click((evt: JQueryEventObject) => {
            const myColor = $(evt.target).css('color');
            if ((true === $(evt.target).parent().hasClass('inactive')) || ('rgb(204, 204, 204)' === myColor) || ('#CCCCCC' === myColor) || ('#cccccc' === myColor)) {
                evt.preventDefault();

                return;

            }
        });

    }

    /**
     * For Simonly we use the connectmore/x-sell page to
     */
    public updateStepperForSimonlyUnlimitedWorkaround () {

        this._element.addClass('unlimited-simonly');

    }

}
