(function() {
    'use strict';
    vf['scroll-to-hash'] = {
        settings: {
            target: '.mod-scroll-to-hash',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {

                var defaultSprungMarke = 'tab-highlights';
                $('a[href*="#vfpre"]:not([href="#"])').click(function() {

                    var tHash = $(this).attr('data-hash');
                    $('#tab-' + tHash).trigger('click');

                    var hash = $(this).attr('href').replace('vfpre', '');
                    if ('#' === hash) {
                        if (0 === tHash.length) {
                            hash += defaultSprungMarke;
                        } else {
                            hash += 'tab-' + tHash;
                        }
                    }

                    // @TOD BPe -> Script anpassen fuer Srollen in TAB innerhalb eines Overlays

                    var target = $(hash);
                    if (target.length) {
                        if (0 === target.parents('.overlay').length) {
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, 800);
                        } else {
                            $('.overlay-content').scrollTo(target, 800, {offset: -80});
                        }
                        return false;
                    }
                    return false;
                });
            });
        },
    };
}(vf));
