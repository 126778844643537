/**
 * @module SalesFlow/controller-evolved
 */

declare var $: JQueryStatic;
declare var vf: any;

import { ControllerEvolvedFlowBase } from 'controller-evolved/controller-evolved--flow-base';

import VluxOffer from 'model/type/offer';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';

import { ViewEvolvedElementVvlContractData } from 'view-evolved/element/vvl/view-evolved--element-vvl--contract-data';
import { ViewEvolvedElementVvlSubscriptionRecommendation } from 'view-evolved/element/vvl/view-evolved--element-vvl--subscription-recommendation';
import { ViewEvolvedInlifeCtasRecommendation } from 'view-evolved/ctas/inlife/view-evolved--ctas--inlife-recommendation';

import { SalesChannelName, ContractPeriodType } from 'core/ids';

import Customer from 'shopbackend/customer';
import Subscription from 'model/type/subscription';
import Pricebox from 'view/element/shared/pricebox';
import Injector from 'core/injector';
import { Constants } from 'core/constants';
import InlifeOffer from 'view/view/shared/offer/inlife-offer';
import { NotificationContractPeriodType } from 'view-evolved/notifications/inlife/notification-contract-period-type';
import Offer from 'view/view/shared/offer/offer';

/**
 * @internal
 */
export class InlifeRecommendationController extends ControllerEvolvedFlowBase {

    protected _customer: Customer;

    // We just need the cheapestSubLevelSubscription from the router
    protected _mainSubscriptionGroup: Subscription;
    protected _mainSubscriptionId: number;
    protected _subLevelSubscription: VluxOffer;
    protected _subLevelSubscriptionId: number;

    protected _subLevelAmount: number;
    protected _viewCustomersData: ViewEvolvedElementVvlContractData;
    protected _viewSubscriptionSelection: ViewEvolvedElementVvlSubscriptionRecommendation;
    protected _notificationContractPeriodType: NotificationContractPeriodType;
    protected _offer: Offer;

    private _salesChannel: SalesChannelName;
    private _pricebox: Pricebox;
    private _element: JQuery;

    constructor (
        salesChannel: SalesChannelName,
        mainSubscriptionGroup: Subscription,
        subLevelSubscription: VluxOffer,
        subLevelAmount: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super (
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('Recommendation Controller Inlife ....');
        }

        /**
         * In inlife we need the main subscription group id (for example: All Red M subscriptions have an id = Constans.RedM_Id) and the sub level subscription id. Following the Red M example, a Red M subscription with 10 euros sub level has an id of 516.
         */

        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        this._mainSubscriptionGroup = mainSubscriptionGroup;
        this._mainSubscriptionId = this._mainSubscriptionGroup.id;
        this._subLevelSubscription = subLevelSubscription;
        this._subLevelSubscriptionId = this._subLevelSubscription.subscriptionId;
        this._subLevelAmount = subLevelAmount;
        this._viewCustomersData = new ViewEvolvedElementVvlContractData(injector);
        this._viewSubscriptionSelection = new ViewEvolvedElementVvlSubscriptionRecommendation(this._mainSubscriptionId, injector);

        this._salesChannel = salesChannel;
        this._pricebox = new Pricebox(injector);
        this._element = $('#nsf-subscription-detail-wrapper');
        this._ctas = this.createCtas();

    }

    protected getSalesChannel (): SalesChannelName {

        return this._salesChannel;

    }

    protected createCtas (): ViewEvolvedInlifeCtasRecommendation {
        let showRedXLNotification: boolean = false;
        if (true === this.isRedXLRecommendedTariff() && undefined !== this._customer.hasExistingRedPlus && true === this._customer.hasExistingRedPlus) {
            showRedXLNotification = true;
        }

        return new ViewEvolvedInlifeCtasRecommendation (this._injector, showRedXLNotification);
    }

    protected render (data?: any): void {
        /**
         * Set 'recommendation-page' class to the module belt to to define specific styles for pricebox and cost overview
         * Set 'inlife-recommendation-page' id to the module belt to define specific styles for inlife inside device-tile.scss
         */
        $('body').find('.module-belt').addClass('recommendation-page');
        $('body').find('.module-belt').attr('id', 'inlife-recommendation-page');

        this.createTariffBenefits();

        this.setContractPeriodType(this._customer, this._subLevelSubscription.subscriptionId);

        this.createWarningNotificationForContractPeriodType();

    }

    protected createTariffBenefits (): void {
        const HBData = this.getBenefitsHBData();

        $('#nsf-subscription-detail-wrapper-main').append(this.getInjector().getTemplates().render('nsf-benefits-inlife', HBData));

    }

    protected getBenefitsHBData () {
        const mainSubscriptionGroupId = this._mainSubscriptionGroup.subscriptionGroupId;
        const benefitsTitle = this.getInjector().getStrings().get('inlife-benefits.title');
        const mainSubscriptionId = this._mainSubscriptionId;

        let benefits;

        if (Constants.Smart_Group_Id === mainSubscriptionGroupId) {
            benefits = this.getInjector().getStrings().get('inlife-benefits.smart');
        } else if (Constants.Red_Group_Id === mainSubscriptionGroupId && Constants.RedXS_Id !== mainSubscriptionId) {
            benefits = this.getInjector().getStrings().get('inlife-benefits.red');
        } else if (Constants.Red_Group_Id === mainSubscriptionGroupId && Constants.RedXS_Id === mainSubscriptionId) {
            benefits = this.getInjector().getStrings().get('inlife-benefits.redXS');
        } else if (Constants.Unlimited_Group_Id === mainSubscriptionGroupId) {
            benefits = this.getInjector().getStrings().get('inlife-benefits.redXL');
        }

        return {
            title: benefitsTitle,
            benefits: benefits
        };
    }

    protected createWarningNotificationForContractPeriodType () {

        this._notificationContractPeriodType = new NotificationContractPeriodType(
            this.getInjector(),
            this._contractPeriodType
        );

    }

    public events () {
        this.getInjector().getEvent().listen('costoverview@updated', this.handleContractPeriodTypeFootnotes);
    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this._offer,
                pageName: 'personal offer',
                pageType: 'product detail',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this._injector.getFlowStateWithSalesChannel().customer
            }
        );
    }

    protected getRecommendedOffer (): InlifeOffer {

        const inlifeOfferView = new InlifeOffer(
            this._mainSubscriptionGroup,
            this._subLevelSubscription,
            [],
            [],
            this._subLevelAmount);

        return inlifeOfferView;
    }

    protected showRecommendedOffer (inlifeOffer: any) {
        this.setHeadline(inlifeOffer);

        const viewInlifeOffers: InlifeOffer[] = [];
        viewInlifeOffers.push(inlifeOffer);

        this._viewSubscriptionSelection.getSubscriptionIds(this._mainSubscriptionId);
        this._viewSubscriptionSelection.bind(viewInlifeOffers);
    }

    /**
     * We set part of the headline and editorial the other part. For example, this function sets "RED M 4 GB" inside the subscription tile (the red letter).
     *
     */
    private setHeadline (inlifeOffer: any) {
        let headline = inlifeOffer.subscriptionName + ' ';

        isNaN(inlifeOffer.fullDataVolume) ? headline += 'mit unbegrenztem Datenvolumen' : headline += inlifeOffer.fullDataVolume + ' GB';

        $('h2#subscriptions span').html(headline);
    }

    private isRedXLRecommendedTariff (): boolean {
        let _isRedXLRecommendedTariff = false;

        if (-1 < Constants.TariffchangeRedXLSubLevelTariffIds.lastIndexOf(this._subLevelSubscriptionId)) {
            _isRedXLRecommendedTariff = true;
        }

        return _isRedXLRecommendedTariff;
    }

    public bind (): void {

        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this._subLevelSubscriptionId);

        this._offer = this.getRecommendedOffer();

        this.showRecommendedOffer(this._offer);

        this._viewCustomersData.bind(this._customer);

        if (undefined !== this._offer) {
            this.getInjector().getLoadingIndicator().hide();
        }

        this.render();
        this.events();

        this._pricebox.bind(this._offer);

        if (undefined !== this._ctas) {
            this._ctas.bind(this._offer);
        }

        this.handleContractPeriodTypeFootnotes();

        if (undefined !== this._notificationContractPeriodType) {
            this._notificationContractPeriodType.bind();
        }

        this.tracking();
    }

    /**
     * Arrow function to reduce anonymous function in the event listener
     */
    private handleContractPeriodTypeFootnotes = () => {

        if ('R' === this._contractPeriodType) {
            const tileAsterisks = document.querySelectorAll('.tariff-module-tile-vvl .asterisk-text');

            Array.from(tileAsterisks).forEach((tileAsterisk: HTMLElement) => {
                tileAsterisk.innerHTML = tileAsterisk.innerHTML
                    .replace(/Mindestlaufzeit 24 Monate(\.?)|initialized/g, '')
                    .trim();

                if (tileAsterisk.innerHTML.length === 0) {
                    const asterisk: HTMLElement = tileAsterisk.parentElement.querySelector('.asterisk');

                    tileAsterisk.style.display = 'none';
                    asterisk.style.display = 'none';
                } else {
                    vf.footnotes.init();
                }
            });

            $('.footnote-min-term, .asterisk-min-term').hide();
        }
    }
}
