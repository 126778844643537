/**
 * @module SalesFlow/model/repo
 */

import {BusinessTransactionContext, GigakombiVorteilName} from 'core/ids';

declare var $: JQueryStatic;

declare var vf: any;

import {Repo} from 'model/repo/repo';
import DeviceRepo from 'model/repo/device-repo';
import PurchasableDeviceRepo from 'model/repo/purchasable-device-repo';
import PurchasableAccessoryRepo from 'model/repo/purchasable-accessory-repo';
import SubscriptionRepo from 'model/repo/subscription-repo';
import ServiceRepo from 'model/repo/service-repo';
import AttributesRepo from 'model/repo/attribute-repo';
import AccessoryRepo from 'model/repo/accessory-repo';
import SimCardRepo from 'model/repo/simcard-repo';
import {Constants} from 'core/constants';
import DependencyRepo from './repo/dependency-repo';
import Injector from 'core/injector';

interface LoadingData {
    [index: string]: JQueryXHR;
}

interface DeviceRepos {
    [index: string]: DeviceRepo;
}

interface PurchasableDeviceRepos {
    [index: string]: PurchasableDeviceRepo;
}

/**
 * Load vlux json data and use this data to instantiate a repo
 * @TODO Suport for different rows
 * @TODO This class cries for a refactory ;)
 */
export default class Repos {

    private readonly _vluxPath: string;

    private readonly _btx: BusinessTransactionContext;

    private _subscriptionGroup: string = 'consumer';

    private _deviceRepo: DeviceRepos = {};
    private _purchasableDeviceRepo: PurchasableDeviceRepos = {};
    private _purchasableAccessoryRepo: PurchasableAccessoryRepo;
    private _subscriptionRepo: SubscriptionRepo;
    private _serviceRepo: ServiceRepo;
    private _discountRepo: ServiceRepo;

    private _attributesRepo: AttributesRepo;
    private _accessoryRepo: AccessoryRepo;

    private _simCardRepo: SimCardRepo;

    private _dependencyRepo: DependencyRepo;

    private queue: LoadingData = {};

    constructor (vluxPath = '/api-static/ng/ecommerce', btx: BusinessTransactionContext = Constants.BTX_BNT) {
        this._vluxPath = vluxPath;
        this._btx = btx;
    }

    public getSubscriptionGroup (): string {
        return this._subscriptionGroup;
    }

    /**
     * Get business transaction context
     */
    public getBtx (): BusinessTransactionContext {
        return this._btx;
    }

    public setSubscriptionGroup (subscriptionGroup: string): void {

        // easy subscptions and their offers are in same jsons then consumer
        if ('easy' === subscriptionGroup) {
            subscriptionGroup = 'consumer';
        }

        this._subscriptionGroup = subscriptionGroup;
    }

    /**
     * @TODO Add last changes.json support
     */
    private loadData (name: string, url: string): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        const requestKey: string = name + '--' + url;

        if (undefined !== this.queue[requestKey]) {

            this.queue[requestKey].done((data) => {

                delete this.queue[requestKey];

                deferred.resolve(data);

            });
        }
        else {
            const checkURL = new RegExp('^(?:[a-z]+:)?//', 'i');
            const isAbsolute = checkURL.test(url);

            if (false === isAbsolute && 'object' === typeof vf && 'object' === typeof vf.devhelper && 'function' == typeof vf.devhelper.serviceUrl) {
                url = vf.devhelper.serviceUrl() + url;
            }

            this.queue[requestKey] = $.ajax({
                dataType: 'json',
                url: url,
                data: {},
                success: (data) => {

                    delete this.queue[requestKey];

                    deferred.resolve(data);

                },
                error: (msg) => {
                    deferred.reject();
                }
            });

        }

        return deferred.promise();

    }

    /**
     * @TODO param for global or other "row"
     */
    private getDeviceRepo (type: string): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._deviceRepo[type]) {

            deferred.resolve(this._deviceRepo[type]);

        }
        else {

            this.loadData('hardwaremobile-' + type, this._vluxPath + '/products/hardwaremobile-' + type + '.json')
                .done((data: any) => {

                    if (undefined !== this._deviceRepo[type]) {

                        deferred.resolve(this._deviceRepo[type]);

                    }
                    else {

                        this._deviceRepo[type] = new DeviceRepo(data);
                        deferred.resolve(this._deviceRepo[type]);

                    }

                }).fail(() => {

                deferred.reject();

            });
        }

        return deferred.promise();

    }

    /**
     * @TODO param for global or other "row"
     */
    public getPurchasableDeviceRepo (injector: Injector, type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        const deferred = $.Deferred<Repo>();

        if (undefined === this._purchasableDeviceRepo[this._subscriptionGroup]) {

            $.when(
                this.getDeviceRepo(type)
            ).done((deviceRepo: DeviceRepo) => {

                this._purchasableDeviceRepo[this._subscriptionGroup] = new PurchasableDeviceRepo(
                    deviceRepo,
                    injector.getGeneralSalesObjectInterface(),
                    this._btx,
                    injector.getFlowState().getSalesChannel());

                deferred.resolve(this._purchasableDeviceRepo[this._subscriptionGroup]);

            }).fail(() => {

                deferred.reject();

            });

        }
        else {

            deferred.resolve(this._purchasableDeviceRepo[this._subscriptionGroup]);

        }

        return deferred.promise();
    }

    /**
     * Only used in vvl
     */
    public getMultiPurchasableDeviceRepo (injector: Injector, type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        const deferred = $.Deferred<Repo>();

        if (undefined === this._purchasableDeviceRepo[this._subscriptionGroup]) {

            $.when(
                this.getDeviceRepo(type)
            ).done((deviceRepo: DeviceRepo) => {

                this._purchasableDeviceRepo[this._subscriptionGroup] = new PurchasableDeviceRepo(
                    deviceRepo,
                    injector.getGeneralSalesObjectInterface(),
                    this._btx,
                    injector.getFlowState().getSalesChannel());

                deferred.resolve(this._purchasableDeviceRepo[this._subscriptionGroup]);

            }).fail(() => {

                deferred.reject();

            });

        }
        else {

            deferred.resolve(this._purchasableDeviceRepo[this._subscriptionGroup]);

        }

        return deferred.promise();
    }

    public getPurchasableAccessoryRepo (injector: Injector): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined === this._purchasableAccessoryRepo) {

            $.when(
                this.getAccessoryRepo()
            ).done((accessoryRepo: AccessoryRepo) => {

                this._purchasableAccessoryRepo = new PurchasableAccessoryRepo(
                    accessoryRepo,
                    injector.getGeneralSalesObjectInterface(),
                    injector.getFlowState().getSalesChannel()
                    );

                deferred.resolve(this._purchasableAccessoryRepo);

            }).fail(() => {

                deferred.reject();

            });

        }
        else {

            deferred.resolve(this._purchasableAccessoryRepo);

        }

        return deferred.promise();

    }

    public getSubscriptionRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._subscriptionRepo) {

            deferred.resolve(this._subscriptionRepo);

        }
        else {

            this.loadData('tariffmobile', this._vluxPath + '/subscriptions/tariffmobile.json')
                .done((data: any) => {

                    if (undefined !== this._subscriptionRepo) {
                        deferred.resolve(this._subscriptionRepo);
                    }
                    else {
                        this._subscriptionRepo = new SubscriptionRepo(data);
                        deferred.resolve(this._subscriptionRepo);
                    }

                }).fail(() => {
                deferred.reject();
            });

        }

        return deferred.promise();

    }

    public getServiceRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._serviceRepo) {

            deferred.resolve(this._serviceRepo);

        }
        else {

            this.loadData('servicemobile', this._vluxPath + '/subscriptions/servicemobile.json')
                .done((data: any) => {

                    if (undefined !== this._serviceRepo) {
                        deferred.resolve(this._serviceRepo);
                    }
                    else {
                        this._serviceRepo = new ServiceRepo(data);
                        deferred.resolve(this._serviceRepo);
                    }

                }).fail(() => {

                deferred.reject();

            });

        }

        return deferred.promise();
    }

    public getDiscountRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._discountRepo) {

            deferred.resolve(this._discountRepo);

        }
        else {

            this.loadData('discountmobile', this._vluxPath + '/subscriptions/discountmobile.json')
                .done((data: any) => {

                    if (undefined !== this._discountRepo) {
                        deferred.resolve(this._discountRepo);
                    }
                    else {
                        this._discountRepo = new ServiceRepo(data);
                        deferred.resolve(this._discountRepo);
                    }

                }).fail(() => {

                deferred.reject();

            });

        }

        return deferred.promise();
    }

    public getAttributeRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._attributesRepo) {

            deferred.resolve(this._attributesRepo);

        }
        else {

            this.loadData('attributes', this._vluxPath + '/attributes/attributes.json')
                .done((data: any) => {

                    if (undefined !== this._attributesRepo) {

                        deferred.resolve(this._attributesRepo);

                    }
                    else {

                        this._attributesRepo = new AttributesRepo(data);
                        deferred.resolve(this._attributesRepo);

                    }

                }).fail(() => {

                deferred.reject();

            });

        }

        return deferred.promise();

    }

    public getAccessoryRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._accessoryRepo) {

            deferred.resolve(this._accessoryRepo);

        }
        else {

            this.loadData('accessorymobile', this._vluxPath + '/products/accessorymobile-global.json')
                .done((data: any) => {

                    if (undefined !== this._accessoryRepo) {

                        deferred.resolve(this._accessoryRepo);

                    }
                    else {

                        this._accessoryRepo = new AccessoryRepo(data);
                        deferred.resolve(this._accessoryRepo);

                    }

                }).fail(() => {

                deferred.reject();

            });

        }

        return deferred.promise();

    }

    public getSimCardRepo (): JQueryPromise<Repo> {
        const deferred = $.Deferred<Repo>();

        if (undefined !== this._simCardRepo) {
            deferred.resolve(this._simCardRepo);
        } else {
            this.loadData('simmobile', this._vluxPath + '/products/simmobile-global.json').done((data: any) => {
                if (undefined !== this._simCardRepo) {
                    deferred.resolve(this._simCardRepo);
                } else {
                    this._simCardRepo = new SimCardRepo(data);
                    deferred.resolve(this._simCardRepo);
                }
            }).fail(() => {
                deferred.reject();
            });

        }

        return deferred.promise();
    }

    public getDependencyRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        if (undefined !== this._dependencyRepo) {
            deferred.resolve(this._dependencyRepo);
        } else {
            this.loadData('dependency', this._vluxPath + '/dependencies/dependencies.json').done((data: any) => {
                if (undefined !== this._dependencyRepo) {
                    deferred.resolve(this._dependencyRepo);
                } else {
                    this._dependencyRepo = new DependencyRepo(data);
                    deferred.resolve(this._dependencyRepo);
                }
            }).fail(() => {
                deferred.reject();
            });

        }

        return deferred.promise();
    }

}
