/**
 * @module SalesFlow/core
 */

export abstract class AnDeviceId {

    protected type: string;
    private id: number;

    constructor (id: number) {
        this.id = id;
    }

    public get (): number {
        return this.id;
    }

    public getType (): string {
        return this.type;
    }

}

export class DeviceId extends AnDeviceId {
    protected type: string = 'deviceId';

}

export class AtomicDeviceId extends AnDeviceId {
    protected type: string = 'atomicDeviceId';
}

// @TODO When salechannels are implemented into bnt as well, SubscriptionGroupName only indicates which radio button was clicked.
// All logic vlux should be implemented with sales channel
export type SubscriptionGroupName = 'consumer' | 'young' | 'easy' | 'soho' | 'familyfriends' | 'red' | 'in';

/**
 * @TODO when all changes are done, the wron values consumer and soho should be removed
 */
// export type SubscriptionGroupName = 'red' | 'young' | 'easy';

/*
 * Valid sales channels are 'consumer' | 'young' | 'soho' | 'familyfriends'
 * familyfriends is not yet implemented
 */
export type SalesChannelName = 'consumer' | 'young' | 'soho' | 'familyfriends';

/**
 * The Tariff-Group reflects the radio buttons above the tariff slider. It's a
 * combination of SalesChannel and Subscription-Grpup
 */
export type TariffGroupName = 'young' | 'easy' | 'soho' | 'red' | 'in';

export type DeviceFilterValue = 'all' | 'apple' | 'samsung';

export type BusinessTransactionContext = 'bnt' | 'vvl' | 'multisim' | 'hardware' | 'gigakombi' | 'familyfriends' | 'inlife' | 'secondcard';

/** Denglish while GigakombiVorteil is the wording when comunicating with business */
export type GigakombiVorteilName = 'IP' | 'TV' | 'BR5' | 'IPandBR5' | 'IPandTV' | 'Unity';

export interface SubscriptionIdPerSalesChannel {
    [key: string]: number;
    'consumer'?: number;
    'young'?: number;
    'soho'?: number;
    'familyfriends'?: number;
}

/**
 * There are two different contract period types in the allowed tariffs returned by the NBA call:
 * N: "Neue Laufzeit" - New duration of a contract. When a customer selects the allowed tariff where the contract period is N, the contract will start again with 24 months.
 * R: "Restlaufzeit" - Remaining duration of a contract. When a user selects the allowed tariff where the contract period is R, the duration of the contract will continue without any change.
 */
export type ContractPeriodType = 'N' | 'R';

export type InlifeFlowType = 'INL' | 'INL24';

/**
 * Customer Groups for winback campaign
 */
export type WinbackCampaignGroup = '01_WEB_I_WBVCGA_R3_P' | '01_WEB_I_WBVCGC_R3_P';

/**
 * Groups for winback campaign
 */
export interface IWinbackGroupMapping {
    readonly [index: string]: WinbackCampaignGroup;
}

/**
 * Tariff-Discount mapping for winback campaign
 * @TODO do the I before interface
 */
export interface ITariffDiscountMapping {
    readonly [index: string]: string;
}
