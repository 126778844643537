import {Constants} from 'core/constants';

/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import Customer from 'shopbackend/customer';
import Subscription from 'model/type/subscription';
import Injector from 'core/injector';
import {Renderable} from 'view/renderable';
import {SalesChannelName} from 'core/ids';

export class EvolvedProlongationInCurrentSubscription extends Renderable<any> {

    protected _element: JQuery;

    protected _customer: Customer;
    protected _currentSubscription: Subscription;
    protected _currentSubscriptionTile: JQuery;

    private _subscriptionTilesSelector: string = '#nsf-subscription-list-slide .tariff-module-tile';

    private _salesChannel: SalesChannelName;

    private _isOldPortfolio: boolean = false;

    constructor (
        customer: Customer,
        currentSubscription: Subscription,
        injector: Injector
    ) {
        super(injector);

        this._element = $('#nsf-prolongation-in-current-tarif');

        this._customer = customer;
        this._currentSubscription = currentSubscription;

        this.removeUnusedOldPortfolioTiles();

    }

    private removeUnusedOldPortfolioTiles () {

        if (undefined !== this._currentSubscription) {
            this._currentSubscriptionTile = $(this._subscriptionTilesSelector + '[data-subscription-id="'  + this._currentSubscription.id + '"][data-tariff-group="' + this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup() + '"]');

        }
        // This case is valid only if the tarif doesnot exist inside the tarifmobile.json for some reason so currentSubscription in also undefined
        else {
            this._currentSubscriptionTile = undefined;

        }

        if (undefined !== this._currentSubscriptionTile) {
            // if current subscription is from current portfolio; Remove all subscription tiles where data-old-portfolio="true"
            if (false === this._currentSubscriptionTile.data('old-portfolio') || undefined === this._currentSubscriptionTile.data('old-portfolio')) {

                const oldUnsedSubscriptionTiles = $(this._subscriptionTilesSelector + '[data-old-portfolio="true"]');

                oldUnsedSubscriptionTiles.remove();

            }
            // if current subscription is from old portfolio: remove every subscription tile where data-old-portfolio="true" and not current subscription_id
            else if (true === this._currentSubscriptionTile.data('old-portfolio')) {

                const oldUnsedSubscriptionTiles = $(this._subscriptionTilesSelector + '[data-subscription-id!="' + this._currentSubscription.id + '"][data-old-portfolio="true"]');

                oldUnsedSubscriptionTiles.remove();

                // Before showing the tile, let's make sure it is in the first position of the slider
                this._currentSubscriptionTile.detach();
                $('.tariff-module-tiles').prepend(this._currentSubscriptionTile);

                this._isOldPortfolio = true;

            }

            const salesChannel = this._currentSubscriptionTile.data('tariff-group');

            this._salesChannel = salesChannel;

        }
        // if the currentSubscription is undefined then also the currentSubscriptionTile is undefined so we just need to remove all old portfolio subscription tiles
        // This case is valid only if the tarif doesnot exist inside the tarifmobile.json for some reason
        else {
            const oldUnsedSubscriptionTiles = $(this._subscriptionTilesSelector + '[data-old-portfolio="true"]');

            oldUnsedSubscriptionTiles.remove();
        }

    }

    public render (): void {
        let isAllowedToProlong = true;

        if (undefined !== this._currentSubscription) {
            /** Customer is over 28 and has currently an young subscription
             * We disallowed it here. (MVP -> Would be allowed to prolong in young subscription without young discount)
             */
            if (false === this._customer.isYoungCustomer && 'young' === this._currentSubscription.subscriptionGroupName) {
                isAllowedToProlong = false;
            }
        } else {
            isAllowedToProlong = false;
        }

        if (false === isAllowedToProlong) {

            this._element.find('.nsf-prolongation-in-current-tarif-cta').hide();
            this._element.find('.current-tariff-not-eligible').show();

            return;

        }

        // @TODO What does this loop do
        // If the current tariff is already visible in the slider, button should be disabled
        // $('.tariff-module-tiles').children().each((index: number, tile: JQuery) => {
        //     if (this._customer.tariffSwapNecessary === false && undefined !== this._currentSubscription) {
        //         if ($(tile).data('subscription-id') === this._currentSubscription.id) {
        //             if ($(tile).hasClass('selected')) {
        //                 this._element.find('.nsf-prolongation-in-current-tarif-cta').attr('disabled', 'disabled');
        //             }
        //         } else {
        //             if ('true' === $(tile).attr('data-old-portfolio')) {
        //                 $(tile).remove();
        //             }
        //         }
        //     } else {
        //         if ('true' === $(tile).attr('data-old-portfolio')) {
        //             $(tile).remove();
        //         }

        //         this._element.find('.nsf-prolongation-in-current-tarif-cta').hide();
        //         this._element.find('.current-tariff-not-eligible').show();
        //     }
        // });

        let tariffTileCount = 0;
        let foundTariff = false;
        // @TODO What does this loop do
        // If the current tariff is already visible in the slider, button should be disabled
        $('.tariff-module-tiles').children().each((index: number, tile: JQuery) => {
            if (this._customer.tariffSwapNecessary === false && undefined !== this._currentSubscription) {
                if ($(tile).data('subscription-id') === this._currentSubscription.id) {
                    foundTariff = true;
                    if ($(tile).hasClass('selected')) {
                        this._element.find('.nsf-prolongation-in-current-tarif-cta').attr('disabled', 'disabled');
                    }
                } else {
                    if ('true' === $(tile).attr('data-old-portfolio')) {
                        $(tile).remove();
                    }
                    tariffTileCount++;
                }
            } else {
                if ('true' === $(tile).attr('data-old-portfolio')) {
                    $(tile).remove();
                }
                tariffTileCount++;

            }
        });
        if ( 0 <= tariffTileCount && !foundTariff) {
            this._element.find('.nsf-prolongation-in-current-tarif-cta').hide();
            this._element.find('.current-tariff-not-eligible').show();
        }
    }

    protected showCurrentSubscriptionTile () {

        if (true === this._isOldPortfolio) {

            this._currentSubscriptionTile.removeClass('hide');
            this._currentSubscriptionTile.removeAttr('data-old-portfolio');

        }

        let tileIndex = 0;

        // when old portfolio we attached the tile as very first one, so we don't care about it's index
        // but when current portfolio whe have to get the index
        if (false === this._isOldPortfolio) {

            $(this._subscriptionTilesSelector + '[data-tariff-group="' +  this._salesChannel + '"]:not(.hide)').each((index: number, item: any) => {

                if ($(item).data('subscription-id') === this._currentSubscription.id) {

                    tileIndex = index;

                    return false;

                }

                return true;

            });

        }

        // @TODO rewrite subscriptionList and add a event there to tigger instead of clicking here
        this._currentSubscriptionTile.find('.selectionRadio').click();

        this.getInjector().getEvent().trigger('currentTariff@selected', {tileIndex: tileIndex});

    }

    public events (): void {

        this._element.on('click', '.nsf-prolongation-in-current-tarif-cta', (evt) => {

            // If no tarifSwap is necessary, the customer may prolong the tariff
            if (this._customer.tariffSwapNecessary === false) {

                // we have to switch salesChannel first
                if (this._salesChannel !== this.getInjector().getFlowStateWithSalesChannel().getSalesChannel()) {

                    this.getInjector().getEvent().once('SalesChannel@loaded', (eventObject: JQueryEventObject, salesChannel: SalesChannelName) => {

                        if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

                            return undefined;

                        }

                        this.showCurrentSubscriptionTile();

                    });

                    this.getInjector().getEvent().trigger('SalesChannelName@changing', this._salesChannel);

                }
                else {

                    this.showCurrentSubscriptionTile();

                }

            }
        });

        this._element.on('click', '.current-tariff-not-eligible a', (evt) => {
            this._element.find('.message-tooltip').show();
        });

        this._element.on('click', '.message-tooltip .close', (evt) => {
            this._element.find('.message-tooltip').hide();
        });
    }

    public bind (): void {

        this.render();
        this.events();
    }
}
