/**
 * @module SalesFlow/router
 */

import Injector from 'core/injector';

import Routing from 'router/shared/routing';
import BntRouting from 'router/bnt/routing';
import GigakombiRouting from 'router/gigakombi/routing';
import VvlRouting from 'router/vvl/routing';
import MultisimRouting from 'router/multisim/routing';
import InlifeRouting from 'router/inlife/routing';
import { SohoVvlRouting } from 'router-evolved/soho-vvl/router-evolved--soho-vvl--routing';
import { FamilyFriendsVvlRouting } from 'router-evolved/familyfriends-vvl/router-evolved--familyfriends-vvl--routing';
import {logError} from 'tracking/logError';
import {Constants} from 'core/constants';

/**
 * A little Routing Factory
 * This makes only sense as long as the btx can not be switched in runtime
 */
export function createRouting (injector: Injector): Routing {

    /**
     * Test implementation of logError
     */
    try {

        const error = injector.getGetParameter().getLocationSearchParameter('error');

        if (undefined !== error) {

            logError({
                type: 'technical',
                message: 'Test implementation of logError ' + error,
                code: 'vlux'
            });

        }

    }
    catch (e) {

    }

    const salesChannel = injector.getOptions().get('default_sales_channel');

    let btx = injector.getBtx();

    /**
     * Gigakombi is later injected by valid token
     * For now we go with get parameter
     *
     * If bnt is set by data attribute it might be gigakombi when token is present and valid
     */
    if (Constants.BTX_BNT === btx) {

        // only route gigakombi if get parameter token is set or backurl or giga=kombi
        let routeGigakombi = false;

        // @TODO move marker into gigakombi and allow only if token oder backurl is stored on cookie
        const backUrl = injector.getGetParameter().getLocationSearchParameter('backUrl');
        const token = injector.getGetParameter().getLocationSearchParameter('token');
        const umCase = injector.getGetParameter().getLocationSearchParameter('umCase');
        const marker = injector.getGetParameter().getLocationSearchParameter('giga');

        const page = $('#nsf-dispatcher').data('page');

        if (undefined !== backUrl || undefined !== token || undefined !== umCase || 'kombi' === marker) {
            routeGigakombi = true;
        }

        if (true === routeGigakombi) {
            const gigakombi = injector.getGigakombi();
            // start gigakombi
            if (true === gigakombi.isGigakombi()) {
                btx = injector.updateBtx(Constants.BTX_GIGAKOMBI);
            }
        }
    }

    switch (btx) {

        case Constants.BTX_BNT:
            return new BntRouting(injector);
            break;

        case Constants.BTX_GIGAKOMBI:
            return new GigakombiRouting(injector);
            break;

        case Constants.BTX_VVL:
            if (Constants.SALESCHANNEL_SOHO === salesChannel) {

                return new SohoVvlRouting(injector);

            }
            if (Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {

                return new FamilyFriendsVvlRouting(injector);

            }
            else {

                return new VvlRouting(injector);

            }
            break;

        case Constants.BTX_MULTISIM:
            return new MultisimRouting(injector);
            break;

        case Constants.BTX_INLIFE:
            return new InlifeRouting(injector);
            break;

    }

    return undefined;

}
