/**
 * @module SalesFlow/view
 */

import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';
import VluxOfferService from 'model/type/offer-service';
import ViewOfferTariffCosts from '../offer-tariff-costs';

import DeviceOffer from './device-offer';
import SimOnlyOffer from './sim-only-offer';
import SimCardOffer from './simcard-offer';
import InlifeOffer from './inlife-offer';
import {Constants} from 'core/constants';

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendDeviceOffer
 */
export default abstract class Offer {

    public abstract readonly type: string;

    protected _subscription: Subscription;
    protected _offer: VluxOffer;

    public _offerTariffCost: ViewOfferTariffCosts;

    /**
     * @TODO: überlegen, wie man das mit RedPlus besser machen kann
     * kann vom Typ ViewDeviceOffer oder ViewSimOnlyOffer sein
     */
    protected _redPlusOffers: DeviceOffer[];
    /* in case, user selects Red+ */

    protected _optionalServices: number[] = [];
    protected _optionalDiscounts: number[] = [];

    protected _monthlyDiscountSum: number;
    protected _monthlyDiscountPrice: number;
    protected _monthlyRegularPrice: number;

    protected _onetimePrice: number;
    protected _connectionFee: number = 0;

    constructor (
        subscription: Subscription,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = []
    ) {

        this._subscription = subscription;
        this._offer = offer;
        this._redPlusOffers = redPlusOffers;

        // @TODO if insurance is included, than skip it here
        this._optionalServices = optionalServices;

        if (undefined !== subscription) {

            this._offerTariffCost = new ViewOfferTariffCosts(this._offer, this._subscription);

        }

        this.setMonthlyPrices();

    }

    protected setMonthlyPrices (): void {

        if (undefined === this._offerTariffCost) {
            return;
        }

        this._monthlyDiscountPrice = this._offerTariffCost.discountPrice;
        this._monthlyRegularPrice = this._offerTariffCost.regularPrice;

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {

            this._monthlyDiscountPrice += redPlusOffer.offerTariffCosts.discountSimOnlyPrice;
            this._monthlyRegularPrice += redPlusOffer.offerTariffCosts.regularSimOnlyPrice;

            /**
             * update "Ab dem x. Monat...."
             */
            this._offerTariffCost.updateRuntimeArray(redPlusOffer.offerTariffCosts.discountSimOnlyPrice, 24);

            /**
             * subsidization for red+ device
             */

            if ('HmK' === redPlusOffer.offer.offerType) {

                this._monthlyDiscountPrice += redPlusOffer.offerTariffCosts.subsidization;
                this._monthlyRegularPrice += redPlusOffer.offerTariffCosts.subsidization;

                /**
                 * update "Ab dem x. Monat...."
                 */
                this._offerTariffCost.updateRuntimeArray(redPlusOffer.offerTariffCosts.subsidization, 24);

            }

        }

        /**
         * optionalServices
         */
        for (const optionalService of this.optionalServices) {

            try {
                this._monthlyDiscountPrice += optionalService.monthlyPrice.value;
                this._monthlyRegularPrice += optionalService.monthlyPrice.value;

                /**
                 * update "Ab dem x. Monat...."
                 */
                this._offerTariffCost.updateRuntimeArray(optionalService.monthlyPrice.value, 24);
            } catch (e) {}

        }

        /**
         * mandatoryServices
         */
        for (const optionalService of this.mandatoryServices) {

            this._monthlyDiscountPrice += optionalService.monthlyPrice.value;
            this._monthlyRegularPrice += optionalService.monthlyPrice.value;

        }
    }

    public addUnlimitedCharingSoc (): void {
        this._offer.addUnlimitedCharingSoc();
    }

    public removeUnlimitedCharingSoc (): void {
        this._offer.removeUnlimitedCharingSoc();
    }

    get hasUnlimitedChargingSoc (): boolean {
        return this._offer.hasUnlimitedChargingSoc;
    }

    protected setOnetimePrice (): void {

        this._onetimePrice = 0;

        if ('HoK' !== this._offer.offerType) {
            this._connectionFee += this._offerTariffCost.connectionFee;
        }

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {

            if ('HoK' !== this._offer.offerType) {
                this._connectionFee += redPlusOffer._offerTariffCost.connectionFee;
            }

        }

        this._onetimePrice += this._offer.devicePrice[0].value;

    }

    abstract get subscriptionId (): number;

    /**
     * Cumulated monthly price inluding optional services
     * excluding mandatory services and discounts
     */
    get monthlyDiscountPrice (): number {
        return 0;
    }

    /**
     * cumulated regular price without any discounts/mandatory services
     */
    get monthlyRegularPrice (): number {
        return 0;
    }

    /**
     * get all services where the customer has the choice to selec
     */
    abstract get optionalServices (): VluxOfferService[];

    /**
     * get all services where the customer has no choice
     */
    abstract get mandatoryServices (): VluxOfferService[];

    /**
     * get all discounts where the customer has the choice to select
     */
    abstract get optionalDiscounts (): VluxOfferService[];

    /**
     * get all discounts where the customer has no choicet
     */
    abstract get mandatoryDiscounts (): VluxOfferService[];

    /**
     * get all discounts where the customer has the choice to select
     */
    abstract get privatePricingDiscounts (): VluxOfferService[];

    /**
     * While all subscriptions durate 2years, just a constant value
     * @return duration of a contract in month
     */
    get duration (): number {
        return 24;
    }

    abstract get subscription (): Subscription;

    abstract get subscriptionName (): string;

    abstract get offer (): VluxOffer

    abstract get offerTariffCosts (): ViewOfferTariffCosts;

    get redPlusOffers (): DeviceOffer[] {
        return [];
    }

    /**
     * get list of all included services and discounts, that has a benefit for the customer
     */
    abstract get includedServicesAndDiscounts (): any[];

    abstract get includedServiceAndDiscountIds (): number[];

    /**
     * headline in  Vorteile Overlay
     */
    abstract get labelMainProposition (): string;

    /**
     * Headline in pricebox. In this case logic out of template, because of further expansions (Voice / Data)
     */
    get labelHeaderOnetime (): string {
        return '';
    }

    public isDevice (): this is DeviceOffer {
        return 'device' === this.type;
    }

    public isHardwareOnly (): this is DeviceOffer {
        return 'hardwareonly' === this.type;
    }

    public isSimOnly (): this is SimOnlyOffer {
        return 'simonly' === this.type;
    }

    public isSimCard (): this is SimCardOffer {
        return 'simcard' === this.type;
    }

    public isInlife (): this is InlifeOffer {
        return 'inlife' === this.type;
    }

    /**
     * Get active gigakombi discounts in offer
     */
    public getGigakombiDiscountIDs (): number[] {

        return this.offer.getGigakombiDiscountIDs();

    }

    get isGigakombi (): boolean {

        return (0 !== this.getGigakombiDiscountIDs().length);

    }

    /**
     * @TODO This returns always 15, at least in unit tests
     * Get the sum of all monthly gigakombi discounts
     */
    get monthlyDiscountGigakombiSum (): number {

        if (false === this.isGigakombi) {
            return 0;
        }

        const gigakombiDiscountIDs = this.getGigakombiDiscountIDs();

        if (0 === gigakombiDiscountIDs.length) {
            return 0;
        }

        let discountSum = 0;

        for (const service of this.offer.getIncludedDiscounts()[0].services) {

            if (-1 === gigakombiDiscountIDs.lastIndexOf(service.id)) {
                continue;
            }

            discountSum += -1 * service.monthlyPrice.value;
        }

        return discountSum;
    }

    private getDataVolumeGigakombi (fullVolume: boolean): number {

        /**
         * Red xl has unlimited data volume. So do nothing for Red xl;)
         */
        if (this.subscriptionId === Constants.RedXL_Id) {
            return undefined;
        }

        let gigakombiFullDataVolume = 0;
        let gigakombiExtraDataVolume = 0;
        const gigakombiDiscountIDs = this.getGigakombiDiscountIDs();
        const GIGAKOMBI_ADDITIONAL_DATAVOLUME = 5;

        gigakombiFullDataVolume = this.subscription.dataVolume;

        for (let i = 0; i < gigakombiDiscountIDs.length; i++) {
            switch (gigakombiDiscountIDs[i]) {
                case Constants.Gigakombi_IP:  // CO-7624: Only 5 GB with new tariff portfolio
                    gigakombiFullDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME ;
                    gigakombiExtraDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    break;
                case Constants.Gigakombi_TV: // TV
                    gigakombiFullDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    gigakombiExtraDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    break;
                case Constants.Gigakombi_BR5:  // BR5 is treated the same as TV regarding data volume
                    gigakombiFullDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    gigakombiExtraDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    break;
                case Constants.Gigakombi_Unity: // Unity
                    gigakombiFullDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    gigakombiExtraDataVolume += GIGAKOMBI_ADDITIONAL_DATAVOLUME;
                    break;
            }
        }

        gigakombiFullDataVolume += this.getAddidtionalDataVolume();

        if (fullVolume) {
            return gigakombiFullDataVolume;
        }

        return gigakombiExtraDataVolume;
    }

    private getDataBoost (): number {
        let dataBoostVolume = 0;

        const offerServiceGroups = this.offer.getIncludedDiscounts();
        for (const servicegroup of offerServiceGroups) {
            for (const service of servicegroup.services) {
                switch (service.id) {
                    case 1124:
                        return dataBoostVolume = 1;
                    case 1125:
                        return dataBoostVolume = 6;
                    case 1126:
                        return dataBoostVolume = 10;
                    default:
                        return dataBoostVolume;
                }
            }
        }
    }

    private getAddidtionalDataVolume (): number {

        let additionalDataVolume: number = Number(0);
        const offerServiceGroups = this.offer.getIncludedDiscounts();
        for (const servicegroup of offerServiceGroups) {
            for (const service of servicegroup.services) {
                if (undefined !== service.dataVolume) {
                    additionalDataVolume += Number(service.dataVolume);
                }
            }
        }

        return additionalDataVolume;

    }

    /**
     * //dj
     * Sumarize the device datavolume and a possible additional datavolume from services
     */
    get fullDataVolume (): number {

        if (true === this.isGigakombi) {
            const gigakombiDataVolume: number = this.getDataVolumeGigakombi(true);

            if (undefined !== gigakombiDataVolume) {
                return gigakombiDataVolume;
            }

        }

        const additionalDataVolume: number = this.getAddidtionalDataVolume();

        return Number(this.subscription.dataVolume) + Number(additionalDataVolume);
    }

    /**
     * returns only the additional datavolume from services
     */
    get extraDataVolume (): number {

        if (true === this.isGigakombi) {
            const gigakombiDataVolume: number = this.getDataVolumeGigakombi(false);

            if (undefined !== gigakombiDataVolume) {
                return gigakombiDataVolume;
            }

        }

        return 0;
    }

    /**
     * create Datavolume Text with included discount datavolume
     */
    get fullDataVolumeText (): string {
        if (this.subscription.isRedXLUnlimited()
            || undefined === this.subscription.dataVolume
            || 0 === this.subscription.dataVolume) {

            return this.subscription.dataVolumeText;
        }

        return this.fullDataVolume + ' GB Datenvolumen';
    }

    get onetimeDevicePrice (): number {
        return this._offer.devicePrice[0].value;
    }

    /**
     *  Device regular onetime price
     */
    get onetimeDeviceStrikePrice (): number {
        return this._offer.devicePrice[0].strikePrice;
    }

    /**
     * Cumulated Onetime Price inluding connection Fee
     */
    get onetimePrice (): number {

        return 0;
    }

    /**
     * Cumulated Onetime Price excluding connection Fee
     */
    get onetimePriceWithoutConnectionFee (): number {

        return 0;

    }

    get monthyDiscountHint (): string {
        return '';
    }

    /**
     * For now it's a hardcoded solution for double data in
     * All simonly offers in young and all red
     */
    get hasPromo (): boolean {

        return false;

    }

    get promoLabel (): string {

        return '';

    }

    get promoText (): string {

        return '';

    }

    get hasPrivatePricingDiscount (): boolean {
        return false;
    }

}
