/**
 * @module SalesFlow/evolved/view
 */

/**
 * @TODO No any here use proper typing for bxslider in combination with jQuery
 */
declare var $: any;

import Injector from 'core/injector';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';
import {Renderable} from 'view/renderable';
import {ViewEvolvedElementVvlInsuranceDetailOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--insurance-detail-overlay';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import {ModelEvolvedRepoPurchasableDevice} from 'model-evolved/repo/model-evolved--repo--purchasable-device';

export class ViewEvolvedElementVvlDetailDetail extends Renderable<DeviceOffer> {

    protected _detailHbs = 'vvl_device_detail';

    private _element: JQuery;

    private _purchasableDeviceRepo: ModelEvolvedRepoPurchasableDevice;

    private _deviceOffer: DeviceOffer;

    // Text of the selected bundle, not the value, because the value(AtomicId) is diffeerent in every device
    private _selectedBundleText: string;

    private _insuranceDetailOverlay: ViewEvolvedElementVvlInsuranceDetailOverlay;

    constructor (injector: Injector) {

        super(injector);
        this._element = $('#nsf-device-detail');
        this._insuranceDetailOverlay = new ViewEvolvedElementVvlInsuranceDetailOverlay(injector);
    }

    /**
     * @TODO Don't use repo in a view!!
     * @param purchasableDeviceRepo
     */
    set purchasableDeviceRepo (purchasableDeviceRepo: ModelEvolvedRepoPurchasableDevice) {
        this._purchasableDeviceRepo = purchasableDeviceRepo;
    }

    public getElement (): JQuery {
        return this._element;
    }

    private setDeviceHeadline (deviceOffer: DeviceOffer) {

        const headline = deviceOffer.subscriptionName + ' mit ' + deviceOffer.deviceName;

        $('h2#subscriptions span').html(headline);
        $('.nsf-mobile-device-name').html(deviceOffer.deviceName);

    }

    private displayDeliveryAdditionalInfo (deviceOffer: DeviceOffer) {

        const isAlreadyVisible: boolean = $('.deliveryAddInfo').is(':visible');

        const threshold = this.getInjector().getOptions().get('delivery-threshold') || 14;

        /**
         * if deliveryDate is less than 28 days, no hint to display
         */

        if (threshold > deviceOffer.atomicDevice.deliveryTimeInDays) {
            if (true === isAlreadyVisible) {
                $('.deliveryAddInfo').animate({
                    height: 'toggle'
                }, 1000, function () {
                    $('.deliveryAddInfoText').html('');
                });
            }

            return;
        }

        /**
         * First check, if there are sufficient Atomics in Device
         */

        let alternativeDevices: AtomicDevice[] = deviceOffer.atomicDevice.alternativeDevices;
        if (0 < alternativeDevices.length) {
            alternativeDevices = deviceOffer.atomicDevice.alternativeDevices;
        } else {
            /**
             * if no alternatives in current device, then check other devices by same vendor
             */
            alternativeDevices = deviceOffer.atomicDevice.device.getAlternativeDevicesEvolved(
                this._purchasableDeviceRepo, deviceOffer.atomicDevice,
                this.getInjector().getFlowState().getSalesChannel()
            );
        }

        if (0 < alternativeDevices.length) {

            let lbl1: String = 'Apple' === deviceOffer.atomicDevice.attributes.vendor ? 'iPhone' : deviceOffer.atomicDevice.attributes.vendor;
            let lbl2: String = 'ist';
            if ((1 < alternativeDevices.length)) {
                lbl1 += 's';
                lbl2 = 'sind';
            }

            const headline: String = lbl1 + ' mit einer schnelleren Lieferzeit ' + lbl2 + ': </br>';
            let txt: String = '';
            for (let i = 0; i < alternativeDevices.length; i++) {
                const atomicDevice = alternativeDevices[i];
                txt += '<li>' + atomicDevice.device.name + ' - ' + atomicDevice.attributes.color + ' - ' + atomicDevice.attributes.internalMemory + ' GB </li>';
            }

            $('.deliveryAddInfoText').html(headline + '<ul class="bullet-list">' + txt + '</ul>');

            if (false === isAlreadyVisible) {
                $('.deliveryAddInfo').animate({
                    height: 'toggle'
                }, 1000, function () {
                });
            }

        } else {
            if (true === isAlreadyVisible) {
                $('.deliveryAddInfo').animate({
                    height: 'toggle'
                }, 1000, function () {
                    $('.deliveryAddInfoText').html('');
                });
            }

        }

    }

    public update (deviceOffer: DeviceOffer): void {

        $('#nsf-color-picker').html(
            this.getInjector().getTemplates().render('colors', deviceOffer.colors, 'partials')
        );

        $('#nsf-size-picker').html(
            this.getInjector().getTemplates().render('sizes', deviceOffer.sizes, 'partials')
        );

        $('#nsf-bundle-picker').html(
            this.getInjector().getTemplates().render('bundles', deviceOffer.bundles, 'partials')
        );

        $('#nsf-device-images').html(
            this.getInjector().getTemplates().render('vvl-device-images-slider', deviceOffer, 'partials')
        );

        $('.deliveryText').html(deviceOffer.atomicDevice.attributes.deliveryTime);

        this._element.find('.device-price-detail.vvl .price-info').parent().remove();
        this._element.find('.device-price-detail .price-info').parent().before(this.getInjector().getTemplates().render('vvl_device_detail_price', deviceOffer));

        this.displayDeliveryAdditionalInfo(deviceOffer);

        $('.deliveryText').html(deviceOffer.atomicDevice.attributes.deliveryTime);

        $('.insuranceBox').html(
            this.getInjector().getTemplates().render('vvl-insurance-device-detail', deviceOffer, 'partials')
        );

        this._deviceOffer = deviceOffer;

    }

    public render (deviceOffer: DeviceOffer): void {

        this._element.html(this.getInjector().getTemplates().render('vvl_device_detail', deviceOffer));

        this.setDeviceHeadline(deviceOffer);

        if (1 < deviceOffer.nfsLargeImages.length) {
            $(this._element).find('.bxslider').bxSlider({
                pagerCustom: '#bx-pager'
            });
        }

        if (this.getInjector().getFlow().hasDeviceInsurance()) {
            this._element.find('.insuranceBox .checkBox').addClass('selected');
        }

        this.displayDeliveryAdditionalInfo(deviceOffer);

        this.getInjector().getEvent().trigger('atomicDevice@detailsDisplayed', {});

        this.update(deviceOffer);
        this._deviceOffer = deviceOffer;

    }

    public events (): void {

        this._element.on('click', '.picker .valueBox', (evt) => {
            /**
             * If the picker is disabled or already selected we don't need to update the deviceOffer
             */
            if (true === $(evt.currentTarget).hasClass('disabled') || true === $(evt.currentTarget).hasClass('selected')) {
                return;
            }

            const atomicDeviceId = parseInt($(evt.currentTarget).data('atomicdeviceid'), 10);

            if (true === isNaN(atomicDeviceId)) {
                return;
            }

            let atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);

            const subscription: Subscription = this._deviceOffer.subscription;

            // If an bundles has been selected, there must be only one simmilar device. use this instead of device without chosen bundle
            if (this._selectedBundleText !== undefined && this._selectedBundleText !== '') {
                const attributeQuery: any = {
                    color: atomicDevice.attr.color,
                    internalMemory: atomicDevice.attr.internalMemory,
                    bundledWith: this._selectedBundleText
                };

                // Get the device with the same attributes, for example "mit Starter kit"
                const atomicDevicesWithSameAttr = this._deviceOffer.atomicDevice.device.getAtomicDevicesByAttrs(attributeQuery);
                // If there is more than one simmilar device, something is wrong so do nothing
                if (atomicDevicesWithSameAttr.length === 1) {
                    atomicDevice = atomicDevicesWithSameAttr[0];
                }

            }

            this.getInjector().getEvent().trigger('device-detail@atomicIdChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription
            });
        });

        this._element.on('change', 'select', (evt) => {

            // Set the selected text
            this._selectedBundleText = $(evt.currentTarget).find(':selected').text();

            const atomicDeviceId = parseInt($(evt.currentTarget).val(), 10);

            if (true === isNaN(atomicDeviceId)) {
                return;
            }

            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);
            const subscription: Subscription = this._deviceOffer.subscription;

            this.getInjector().getEvent().trigger('device-detail@atomicIdChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription
            });

        });

        this._element.on('click', '.insuranceBox .checkBox .i-check, .insuranceBox .checkBox .pseudo-checkbox-label', (evt) => {

            const target = $(evt.currentTarget).parents('.checkBox');

            if (target.hasClass('fixed')) {
                return;
            }

            const deviceInsuranceId = this._deviceOffer.offer.getHandyInsuranceServiceId();

            if (target.hasClass('selected')) {

                this.getInjector().getFlow().optionalServiceIds.removeElement(deviceInsuranceId);

            } else {

                /**
                 * save information in flow
                 */
                this.getInjector().getFlow().optionalServiceIds.addElement(deviceInsuranceId);

            }

            $('.insuranceBox .checkBox').toggleClass('selected');

            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice;

            const subscription: Subscription = this._deviceOffer.subscription;

            this.getInjector().getEvent().trigger('device-detail@insuranceChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription
            });

        });

        this._element.on('click', '.device-details', (evt) => {

            this.getInjector().getEvent().trigger('device-detail@openDetailOverlay', {
                deviceOffer: this._deviceOffer
            });

        });

        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.offer;

            this.update(deviceOffer);

        });

        this.getInjector().getEvent().listen('device-detail@offerChanged:' + this._deviceOffer.atomicDevice.device.id, (eventObject: JQueryEventObject, data: any) => {

            this._deviceOffer = data.deviceOffer;

            this.update(this._deviceOffer);

        });

    }

    public bind (deviceOffer: DeviceOffer): void {

        this.render(deviceOffer);
        this.events();

        this._insuranceDetailOverlay.bind();

    }

}
