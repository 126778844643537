/**
 * @module SalesFlow/controller
 */

import {FlowCtas} from 'view/ctas/shared/flow-ctas';

declare var $: JQueryStatic;

import { Constants } from 'core/constants';

import Injector from 'core/injector';
import Pricebox from 'view/element/shared/pricebox';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';

import Subscription from 'model/type/subscription';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

import Offer from 'model/type/offer';

import ViewAlertWarning from 'view/view/shared/alert-warning';
import ViewOverlay from 'view/view/shared/overlay';

import Customer from 'shopbackend/customer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {ViewEvolvedCtasXSell} from 'view-evolved/ctas/vvl/view-evolved--ctas--x-sell';
import {ViewEvolvedElementVvlAdditionalCard} from 'view-evolved/element/vvl/view-evolved--element-vvl--additional-card';
import {ViewEvolvedElementVvlFafStepper} from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper-faf';
import {ViewEvolvedElementSharedExitIntent} from 'view-evolved/element/shared/view-evolved--element-shared--exit-intent-overlay';

export class ControllerEvolvedFamilyFriendsVvlXSell extends ControllerEvolvedFlowBase {

    private _salesChannel: SalesChannelName;
    private _atomicDeviceId: number;
    private _subscriptionId: number;

    private _subscription: Subscription;
    private _offer: Offer;

    private _additionalCard: JQuery;
    private _pricebox: Pricebox;

    private _maxCards: number = 4;
    protected _exitIntentOverlay: ViewEvolvedElementSharedExitIntent;
    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {
        super(
            reposSupervisor,
            injector
        );

        /**
         * In case of family friends use other Stepper
         */
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {
            this._stepper = new ViewEvolvedElementVvlFafStepper(injector);
        }

        this._exitIntentOverlay = new ViewEvolvedElementSharedExitIntent(injector);
        this._exitIntentOverlay.bind();

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('VvlXSellController....');
        }

        this._salesChannel = salesChannel;
        this._atomicDeviceId = atomicDeviceId;
        this._subscriptionId = subscriptionId;

        this._additionalCard = $('.additional-card-tile[data-type]');
        const vacantRedPlusSlots = this.getInjector().getFlowStateWithSalesChannel().customer.vacantRedPlusSlots;
        if (vacantRedPlusSlots > 0 && vacantRedPlusSlots < 4) {
            this._maxCards = this.getInjector().getFlowStateWithSalesChannel().customer.vacantRedPlusSlots;
        }
        this._pricebox = new Pricebox(injector);
        this._generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();
    }

    protected getGeneralSalesObject (): GeneralSalesObjectInterface {
        return this._generalSalesObjectInterface;
    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected createCtas (): FlowCtas {

        return new ViewEvolvedCtasXSell(
            this.getInjector()
        );

    }

    private createUnlimitedOverlay (direction: 'from' | 'into'): void {

        let content: string;

        const offer = this.getInjector().getOfferCollection().getActiveOffer();

        $('#nsf-subscription-name-in-overlay').html(
            offer.subscriptionName
        );

        if ('into' === direction) {
            content = $('#nsf-red-plus-into-unlimited-content').html();
        }
        else {
            content = $('#nsf-red-plus-from-unlimited-content').html();
        }

        const viewOverlay = new ViewOverlay('', '', content);

        this.getInjector().getOverlay().open(viewOverlay);

        // Add css class to red plus unlimited overlay to hide close icon and sub-title
        $('#nsf-overlay').addClass('nsf-red-plus-unlimited-content');

    }

    private createUnlimitedNotification (direction: 'from' | 'into'): void {

        const alert = new ViewAlertWarning(
            this.getInjector().getStrings().get('vvl.' + direction + '-ulimited-red-warning.content'),
            this.getInjector().getStrings().get('vvl.' + direction + '-ulimited-red-warning.headline')
        );

        this._additionalCard.first().before(
            this.getInjector().getTemplates().render('alert',
                alert
            ));

    }

    private createUnlimitedNotificationAndOverlay (direction: 'from' | 'into'): void {

        // Show overlay only once
        if (false === this.getInjector().getStorage().hasItem('red-xl-red-plus-overlay')) {

            this.getInjector().getStorage().setItem('red-xl-red-plus-overlay', 'true');
            this.createUnlimitedOverlay(direction);

        }

        this.createUnlimitedNotification(direction);

    }

    protected getSubscriptionId (): number {

        return this._subscriptionId;

    }

    protected getAtomicDevicId (): number {
        return this._atomicDeviceId;
    }

    protected render (): void {

        /**
         * If switching from or to red unlimited:
         * Show message that explains that price and condition for red cards will change
         */

        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;

        if (customer.isYoungCustomer && this.getSubscriptionId() !== Constants.RedXL_Id) {
            const youngTitle = this.getInjector().getStrings().get('vvl.x-sell-page.headline');

            $($('.belt h1').get(0)).text(youngTitle);
            $('.show-young-content, #nsf-explain-young').show();
            $('#nsf-explain-other-then-unlimited').hide();
        } else {
            // there are not differnt image-text: One explains unlimited and one explains all other subscriptions
            // by css the unlimited one is hidden. Toogle when unlimited
            if (this.getSubscriptionId() === Constants.RedXL_Id) {

                $('#nsf-explain-other-then-unlimited').hide();
                $('#nsf-explain-unlimited, .show-unlimited-content').show();
                $('body').addClass('nsf-unlimited');
            }
        }

        if (true === customer.hasExistingRedPlus) {

            // customer has currently red unlimited and is switching into another subscription
            if (-1 !== customer.tariffKiasCode.lastIndexOf('RED4UL')) {

                if (this.getSubscriptionId() !== Constants.RedXL_Id) {

                    this.createUnlimitedNotificationAndOverlay('from');

                }
            }
            // customer is switching into red unlimited
            else if (this.getSubscriptionId() === Constants.RedXL_Id) {

                if (true === customer.hasExistingRedPlus) {

                    this.createUnlimitedNotificationAndOverlay('into');

                }

            }
        }

        this.setRedplusCards(customer);
    }

    protected setRedplusCards (customer: Customer) {
        const selectedRedPlusAllnet = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.length;
        const selectedRedPlusKids = this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements.length;
        const selectedRedPlusData = this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.length;
        const totalRedPlusCards = this.getCurrentSelectedRedPlusCards();
        const that = this;

        this.getGeneralSalesObject().redPlusOfferService.setMainTariffId(this._offer.subscriptionId.toString());

        this._additionalCard.each((index, element) => {

            const dataType = $(element).data('type');
            let length = 0;
            switch (dataType) {
                case Constants.RedPlus_Kids:
                    length = selectedRedPlusKids;
                    break;
                case Constants.RedPlus_Data:
                    length = selectedRedPlusData;
                    break;
                default:
                    length = selectedRedPlusAllnet;
            }

            const redPlusOffer = that.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(dataType, this._salesChannel);

            if (0 < length) {
                $(element).data('value', length);
                $(element).find('.btn-count[data-count=' + length + ']').addClass('selected');
                $(element).find('.add-cards-header').addClass('active');
            }

            $(element).find('.btn-count').each(function () {
                if (($(this).data('count') - $(element).data('value')) > totalRedPlusCards) {
                    $(this).addClass('disabled');
                }
            });

            $(element).find('.Price .priceVlux').html(
                this.getInjector().getTemplates().render(
                    'price-element',
                    redPlusOffer.subcriptionPriceMonthly.value,
                    'partials'
                )
            );

        });
    }

    protected handleEventRedPlusCardCountChanged (): void {
        let currentCount = 0;
        this._additionalCard.each(function () {
            currentCount = currentCount + parseInt($(this).data('value'), 10);
        });

        const totalRedPlusCards = this._maxCards - currentCount;

        const that = this;
        this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusData.resetAllElements();

        this._additionalCard.each(function () {

            const there = this;
            $(this).find('.btn-count').each(function () {
                $(this).removeClass('disabled');
                if (($(this).data('count') - $(there).data('value')) > totalRedPlusCards) {
                    $(this).addClass('disabled');
                }
            });

            const val = $(this).data('value');
            const dataType = $(this).data('type');

            const simOnlyOffer = that.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(dataType, that._salesChannel);

            for (let i = 0; i < val; i++) {
                switch (dataType) {
                    case Constants.RedPlus_Allnet:
                        that._injector.getFlow().redPlusAllnet.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.RedPlus_Kids:
                        that._injector.getFlow().redPlusKids.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.RedPlus_Data:
                        that._injector.getFlow().redPlusData.addElement(simOnlyOffer.offerId);
                        break;
                    default:
                        that._injector.getFlow().redPlusAllnet.addElement(simOnlyOffer.offerId);
                }
            }
        });

        this._injector.getEvent().trigger('redPlusCards@changed');

        if (totalRedPlusCards < this._maxCards) {
            this._pricebox.setAnnotationPriceOnce('show');
        } else {
            this._pricebox.setAnnotationPriceOnce('hide');
        }

    }

    protected handleEventRedPlusCardsChanged (): void {
        this.fillOfferOfferCollection();

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: this.getInjector().getOfferCollection().getActiveOffer()
        });

    }

    protected events (): void {

        this._injector.getEvent().listen('redPlusCardCount@changed', () => {
            this.handleEventRedPlusCardCountChanged();
        });

        this._injector.getEvent().listen('redPlusCards@changed', () => {
            this.handleEventRedPlusCardsChanged();
        });

    }

    /**
     * For x sell page there is always one subscription, on active offer and none (simonly) or one device
     */
    private fillOfferOfferCollection (): void {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this._subscriptionId
        );

        let offer;
        let atomicDevice;

        // Subscription with device
        if (undefined !== this._atomicDeviceId) {
            /**
             * Hardware plus Tariff
             */

            atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                this._atomicDeviceId,
                this.getSalesChannel(),
                subscription
            );

            offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                atomicDevice.id,
                subscription.id,
                this._btx,
                this.getSalesChannel()
            );

        } else  {
            /**
             * SIM Only
             */
            offer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(
                this._subscriptionId,
                this._btx,
                this.getSalesChannel()
            );
        }

        // @TODO Great return ;) But what happens then
        if (undefined === offer) {
            return;
        }

        this._offer = offer;

        /**
         *  Did the user opts in a RedPlus Option
         */
        const offersRedPlus: any[] = [];
        const allRedPlusOptions = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.concat(
            this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.concat(
                this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements
            )
        );

        if (0 < allRedPlusOptions.length) {

            for (const offerId of allRedPlusOptions) {

                const redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                    offerId,
                    this.getSalesChannel());
                const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
                    redPlusOffer.subscriptionId
                );

                offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
            }
        }

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        if (undefined === this._atomicDeviceId) {
            const simOnlyOffer = new SimOnlyOffer(
                subscription,
                offer,
                offersRedPlus,
                optionalServiceIds
            );

            this.getInjector().getOfferCollection().setActiveOffer(
                simOnlyOffer
            );

        }
        else {
            const deviceOffer = new DeviceOffer(
                atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId),
                subscription,
                offer,
                offersRedPlus,
                optionalServiceIds
            );

            this.getInjector().getOfferCollection().setActiveOffer(
                deviceOffer
            );

        }

    }

    protected tracking (): void {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this.getInjector().getOfferCollection().getActiveOffer(),
                pageName: 'extras',
                pageType: 'product listing',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            }
        );
    }

    private getCurrentSelectedRedPlusCards (): number {
        const selectedRedPlusAllnet = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.length;
        const selectedRedPlusKids = this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements.length;
        const selectedRedPlusData = this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.length;

        return (this._maxCards - (selectedRedPlusAllnet + selectedRedPlusKids + selectedRedPlusData));
    }

    public bind (): void {

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());
        const atomicDeviceId = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();
        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;

        // @TODO What is this and why here and not in a router? This shoule be moved into router
        if (undefined === this._subscription || (undefined === atomicDeviceId && !customer.isSimOnlyVvlAllowed) ||
            Constants.SmartS_Id === this._subscription.id || Constants.SmartM_Id === this._subscription.id ||
            Constants.SmartS_2013_Id === this._subscription.id || Constants.SmartM_2013_Id === this._subscription.id) {
            window.location.href = this.getInjector().getOptions().get('faf_vvl_tariff_device_url');
        }

        this.fillOfferOfferCollection();

        this._stepper.bind();

        const redPlusAllnet = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.RedPlus_Allnet
        );
        redPlusAllnet.bind();

        /*
         * No red plus kids for young and SOHO customers
        */
        if ('young' === this._subscription.subscriptionGroupName || Constants.SALESCHANNEL_SOHO === this.getInjector().getFlowStateWithSalesChannel().getSalesChannel()) {

            this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
            $('.additional-card-tile[data-type="kids"]').remove();
        }
        else {

            const redPlusKids = new ViewEvolvedElementVvlAdditionalCard(
                this._injector,
                Constants.RedPlus_Kids
            );
            redPlusKids.bind();

        }

        const redPlusData = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.RedPlus_Data
        );
        redPlusData.bind();

        this._pricebox.bind(this.getInjector().getOfferCollection().getActiveOffer());

        // @TODO CTAS should always be here. Check and then remove the if
        if (undefined !== this._ctas) {
            this._ctas.bind(
                this.getInjector().getOfferCollection().getActiveOffer()
            );
        }

        this.render();
        this.events();

        const totalRedPlusCards = this.getCurrentSelectedRedPlusCards();
        if (totalRedPlusCards < this._maxCards) {
            this._pricebox.setAnnotationPriceOnce('show');
        }

        this.tracking();
        this.getInjector().getLoadingIndicator().hide();
    }

}
