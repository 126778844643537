/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../../renderable';

import DeviceOffer from '../../../view/shared/offer/device-offer';

import FilterOption from '../../../view/shared/filter-option';

import FilterMatch from './filter-match';

/**
 * These Filters has both: Logic and view attributes
 */
export default class FilterBase extends Renderable<DeviceOffer[]> {

    protected _shouldRemoveUnmatchedFilters: boolean = true;

    protected _element: JQuery;

    protected _name: string;
    protected _title: string;
    protected _button: string;

    protected _filterOptions: FilterOption[] = [];

    constructor (data: JQuery, injector: Injector) {

        super(injector);

        this._name = data.data('name');
        this._title = data.data('title');
        this._button = data.data('button');

        data.find('.option').each((index, element) => {
            this._filterOptions.push(new FilterOption($(element)));
        });

        this.load();

        this.checkPreset();

    }

    /**
     * There are two ways to preset an filter:
     * Via options e.g on seodevice overview pages
     * Via get for linking
     *
     * @TODO What should happen, when filter Presets are set by Storage, Option and/or GET
     */
    protected checkPreset () {

        for (const filterOption of this._filterOptions) {

            const presetByOption = this.getInjector().getOptions().get('filter_' + filterOption.attribute);

            if (undefined !== presetByOption) {
                if (filterOption.value === presetByOption) {
                    filterOption.setActive(true);
                }
            }
        }

        for (const filterOption of this._filterOptions) {

            let presetByGet = this.getInjector().getGetParameter().getLocationSearchParameter(filterOption.attribute);

            if (undefined !== presetByGet) {

                // @TODO Implement other types !== string as well
                if ('number' === filterOption.type) {
                    presetByGet = parseInt(presetByGet, 10);
                }

                if (filterOption.value === presetByGet) {
                    filterOption.setActive(true);
                }
            }

        }
    }

    protected load (): void {

        let storedValues = this.getInjector().getStorage().getItem('DeviceFilter:' + this._name);

        if (null === storedValues) {
            return;
        }

        storedValues = JSON.parse(storedValues);

        if (undefined === storedValues.length || 0 === storedValues.length) {
            return;
        }

        for (const storedValue of storedValues) {

            for (const filterOption of this._filterOptions) {
                if (storedValue === filterOption.value) {
                    filterOption.setActive(true);
                }
            }

        }
    }

    protected updateActive (): void {

        for (const filterOption of this._filterOptions) {

            const active: boolean = this._element.find('[data-attribute="' + filterOption.attribute + '"][data-value="' + filterOption.value + '"]').hasClass('selected');

            filterOption.setActive(active);

        }

        this.save();
    }

    protected save (): void {

        const data: any[] = [];

        for (const filterOption of this._filterOptions) {

            if (true === filterOption.getActive()) {
                data.push(filterOption.value);
            }

        }

        this.getInjector().getStorage().setItem('DeviceFilter:' + this._name, JSON.stringify(data));
    }

    /**
     * Loop all _filterOptions and match aginst viewOffers and remove all conditions that match no device
     */
    private removeUnusedFilterOptions (viewOffers: DeviceOffer[]): void {
        if (false === this._shouldRemoveUnmatchedFilters) {
            return;
        }

        for (const filterOption of this._filterOptions) {

            let atLeastOneHit: boolean = false;

            for (const viewOffer of viewOffers) {

                if (false === viewOffer.atomicDevice.getDevice().getIsMatchingFilter()) {
                    continue;
                }

                const match = FilterMatch.exec(filterOption, viewOffer);

                if (true === match) {
                    atLeastOneHit = true;
                    break;
                }
            }

            if (false === atLeastOneHit) {
                filterOption.setUmmatched(true);

                if (true === this.getInjector().getOptions().get('debug')) {
                    const cnsl = console;
                    cnsl.log('Remove ' + filterOption.title + ' while no corosponding device');
                }

            }

        }

        this._filterOptions = this._filterOptions.filter((filterOption) => {
            return false === filterOption.getUnmatched();
        });

    }

    private unselectOnefilter (unselect: FilterOption): void {

        for (const filterOption of this._filterOptions) {

            if (filterOption.type !== unselect.type) {
                continue;
            }
            if (filterOption.attribute !== unselect.attribute) {
                continue;
            }
            if (filterOption.value !== unselect.value) {
                continue;
            }
            if (filterOption.condition !== unselect.condition) {
                continue;
            }

            filterOption.setActive(false);

            this._element.find('.selected[data-attribute="' + filterOption.attribute + '"][data-value="' + filterOption.value + '"]').removeClass('selected');

            this.save();
            break;

        }

        this.getInjector().getEvent().trigger('filter@changed', {});

    }

    public render (viewOffers: DeviceOffer[]): string {

        // @this point all filteroptions from dom are already parsed and now we have also all devices in their ViewOffer
        // So i can do now: Remove all filteroptions that are set by editorial but
        this.removeUnusedFilterOptions(viewOffers);

        return '';

    }

    public events (): void {

        this.getInjector().getEvent().listen('filter@unselect', (eventObject: JQueryEventObject, filterOption: FilterOption) => {
            this.unselectOnefilter(filterOption);
        });

    }

    protected closeDropdown (): void {
        this._element.find('.scrollContainer').removeClass('active');
    }

    protected reset (evt: Event): void {
        // this._element.find('.selected, .checked').removeClass('selected').removeClass('checked');

    }

    get name (): string {
        return this._name;
    }

    get title (): string {
        return this._title;
    }

    get button (): string {
        return this._button;
    }

    get options (): FilterOption[] {
        return this._filterOptions;
    }

}
