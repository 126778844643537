/**
 * @module SalesFlow/core
 */
import {BusinessTransactionContext, SalesChannelName, SubscriptionGroupName, IWinbackGroupMapping, ITariffDiscountMapping} from 'core/ids';
import {GigakombiVorteilName} from 'core/ids';
import { SalesChannelNameBackend, BusinessTransactionContextBackend, RedPlusTypes } from '@vfde-fe/general-sales-objects';

interface GigakombiDiscountsMapping {
    [index: string]: number[];
}

interface StringMap {
    [index: string]: GigakombiVorteilName;
}

interface SalesChannelMapping {
    [index: string]: SalesChannelNameBackend;
}

interface BtxMapping {
    [index: string]: BusinessTransactionContextBackend;
}

export class Constants {

    /**
     * Some SubscriptionIds to cover e.g. no Red for Easy or no SimOnly for Black
     */
    public static readonly EasyS_Id: number = 426;
    public static readonly EasyM_Id: number = 427;

    public static readonly SmartS_Id: number = 477;
    public static readonly SmartM_Id: number = 478;
    public static readonly SmartS_2013_Id: number = 123;
    public static readonly SmartM_2013_Id: number = 124;

    /**
     * Red 2019 Portfolio IDs
     */
    public static readonly RedXS_Id: number = 1044;
    public static readonly RedS_Id: number = 1045;
    public static readonly RedM_Id: number = 1046;
    public static readonly RedL_Id: number = 1047;
    public static readonly RedXL_Id: number = 1048;
    public static readonly RedXL_2017_Id: number = 502;

    /**
     * Young 2019 Portfolio IDs
     */
    public static readonly YoungXS_Id: number = 1083;
    public static readonly YoungS_Id: number = 1084;
    public static readonly YoungM_Id: number = 1085;
    public static readonly YoungL_Id: number = 1086;
    public static readonly YoungXL_Id: number = 1087;

    public static readonly Black_Id: number = 115;

    /**
     * SubscriptionId of RedPlus
     */
    public static readonly RedData_SimOnlyId: number = 81;
    public static readonly RedAllnet_SimOnlyId: number = 77;
    public static readonly RedKids_SimOnlyId: number = 448;

    public static readonly RedData_MainId: number = 141;
    public static readonly RedAllnet_MainId: number = 142;
    public static readonly RedKids_MainId: number = 451;

    public static readonly Gigakombi_RedData_Id: number = 141;
    public static readonly Gigakombi_RedAllnet_Id: number = 142;
    public static readonly Gigakombi_RedKids_Id: number = 451;

    public static readonly RedUnlimitedCharging_Id: number = 876;

    /**
     * RedPlus Types
     */
    public static readonly RedPlus_Allnet: RedPlusTypes = 'allnet';
    public static readonly RedPlus_Data: RedPlusTypes = 'data';
    public static readonly RedPlus_Kids: RedPlusTypes = 'kids';

    /**
     * SubscriptionGroupIds of supported SubscriptionGroups
     */
    public static readonly RedPlus_Group_Id: number = 140;
    public static readonly Easy_Group_Id: number = 425;
    public static readonly Black_Group_Id: number = 138;
    public static readonly Red_Group_Id: number = 1043;
    // Now we use Red XL tariff ID as there is no Red XL group ID in Red portfolio 2019
    public static readonly Unlimited_Group_Id: number = 1048;

    public static readonly Young_2017_Group_Id: number = 504;
    public static readonly Young_Group_Id: number = 1082;
    public static readonly Smart_Group_Id: number = 476;
    public static readonly Smart_2013_Group_Id: number = 122;

    /**
     * Available Business Transaction Contexts
     */
    public static readonly BTX_BNT: BusinessTransactionContext = 'bnt';
    public static readonly BTX_VVL: BusinessTransactionContext = 'vvl';
    public static readonly BTX_MULTISIM: BusinessTransactionContext = 'multisim';
    public static readonly BTX_HARDWARE: BusinessTransactionContext = 'hardware';
    public static readonly BTX_GIGAKOMBI: BusinessTransactionContext = 'gigakombi';
    public static readonly BTX_FAMILY_FRIENDS: BusinessTransactionContext = 'familyfriends';
    public static readonly BTX_INLIFE: BusinessTransactionContext = 'inlife';

    /**
     * Available Sales Channels
     */
    public static readonly SALESCHANNEL_CONSUMER: SalesChannelName = 'consumer';
    public static readonly SALESCHANNEL_YOUNG: SalesChannelName = 'young';
    public static readonly SALESCHANNEL_SOHO: SalesChannelName = 'soho';
    public static readonly SALESCHANNEL_FAMILY_FRIENDS: SalesChannelName = 'familyfriends';

    /**
     * Available Sales Channels (Backend Notation)
     */
    public static readonly SALESCHANNEL_BACKEND_CONSUMER: SalesChannelNameBackend = 'Online.Consumer';
    public static readonly SALESCHANNEL_BACKEND_YOUNG: SalesChannelNameBackend = 'Online.Young';
    public static readonly SALESCHANNEL_BACKEND_SOHO: SalesChannelNameBackend = 'Online.SoHo';
    public static readonly SALESCHANNEL_BACKEND_FAMILY_FRIENDS: SalesChannelNameBackend = 'Online.FamilyFriends';

    public static readonly SalesChannelGeneralOfferObjectMapping: SalesChannelMapping = {
        consumer: Constants.SALESCHANNEL_BACKEND_CONSUMER,
        young: Constants.SALESCHANNEL_BACKEND_YOUNG,
        soho: Constants.SALESCHANNEL_BACKEND_SOHO,
        familyfriends: Constants.SALESCHANNEL_BACKEND_FAMILY_FRIENDS
    };

    /**
     * Available Business Transaction Contexts (Backend Notation)
     */
    public static readonly BTX_BACKEND_BNT: BusinessTransactionContextBackend = 'newContract';
    public static readonly BTX_BACKEND_VVL: BusinessTransactionContextBackend = 'contractProlongation';
    public static readonly BTX_BACKEND_MULTISIM: BusinessTransactionContextBackend = 'multicard';
    public static readonly BTX_BACKEND_HARDWARE: BusinessTransactionContextBackend = 'hardware';
    public static readonly BTX_BACKEND_GIGAKOMBI: BusinessTransactionContextBackend = 'gigakombi';
    public static readonly BTX_BACKEND_INLIFE: BusinessTransactionContextBackend = 'tariffChange';
    public static readonly BTX_BACKEND_SECONDCARD: BusinessTransactionContextBackend = 'secondCard';

    public static readonly BtxGeneralOfferObjectMapping: BtxMapping = {
        bnt: Constants.BTX_BACKEND_BNT,
        vvl: Constants.BTX_BACKEND_VVL,
        multisim: Constants.BTX_BACKEND_MULTISIM,
        hardware: Constants.BTX_BACKEND_HARDWARE,
        gigakombi: Constants.BTX_BACKEND_BNT,
        familyfriends: Constants.BTX_BACKEND_BNT,
        inlife: Constants.BTX_BACKEND_INLIFE
    };

    /**
     * Available subscription groups
     */
    public static readonly SUBSCRIPTION_GROUP_CONSUMER: SubscriptionGroupName = 'consumer';
    public static readonly SUBSCRIPTION_GROUP_RED: SubscriptionGroupName = 'red';
    public static readonly SUBSCRIPTION_GROUP_YOUNG: SubscriptionGroupName = 'young';
    public static readonly SUBSCRIPTION_GROUP_EASY: SubscriptionGroupName = 'easy';
    public static readonly SUBSCRIPTION_GROUP_SOHO: SubscriptionGroupName = 'soho';
    public static readonly SUBSCRIPTION_GROUP_FF: SubscriptionGroupName = 'familyfriends';
    public static readonly SUBSCRIPTION_GROUP_IN: SubscriptionGroupName = 'in';

    /**
     * Single Tarif IDs
     */
    public static readonly REDXL_TARIF_IDS: number[] = [1064, 1065, 1066, 1067, 1068, 1305];
    public static readonly REDL_TARIF_IDS: number[] = [1049, 1051, 1052, 1053, 1306, 1050];

    /**
     * Current Portfolio Subscription Group Ids
     */
    public static get CurrentSubscriptionGroupIdsOfCurrentPortfolio (): number[] {

        const ids: number[] = [
            this.Red_Group_Id,
            this.Unlimited_Group_Id,
            this.Black_Group_Id,
            this.Young_Group_Id,
            this.Easy_Group_Id
        ];

        return ids;

    }

    /**
     * Redirect URL [VGOF-5450] [INC-22805]
     */
    public static readonly CUSTOMER_SALES_PAGE: string = 'opweb2.vfd2-testnet.de/privat';
    public static readonly CUSTOMER_SALES_MOBILE_PAGE: string = 'https://opweb2.vfd2-testnet.de/privat/mobilfunk.html';

    /**
     * SubscriptionIds to ignore
     *
     * Ignore this id because there more than one red XL tariff and we only want the unlimited group one
     */
    public static get Subscription_Ids_To_Ignore (): number[] {

        const ids: number[] = [
            this.RedXL_2017_Id
        ];

        return ids;

    }

    public static get AllRedAndAllYoungSubscriptionGroupIds (): number[] {

        const ids: number[] = [
            this.Red_Group_Id,
            this.Young_Group_Id,
            this.Young_2017_Group_Id

        ];

        return ids;
    }

    public static get AllYoungSubscriptionGroupIds (): number[] {

        const ids: number[] = [
            this.Young_Group_Id,
            this.Young_2017_Group_Id
        ];

        return ids;
    }

    public static get AllYoungSubscriptionMainIds (): number[] {

        const ids: number[] = [
            this.YoungXS_Id,
            this.YoungS_Id,
            this.YoungM_Id,
            this.YoungL_Id,
            this.YoungXL_Id
        ];

        return ids;
    }

    /**
     * Private Pricing Discount Ids
     */
    public static readonly PPDiscountIds: number[] = [587, 588, 589, 590, 591, 592];

    public static readonly Gigakombi_IP: number = 177;
    public static readonly Gigakombi_TV: number = 612;
    public static readonly Gigakombi_BR5: number = 618;
    public static readonly Gigakombi_Unity: number = 1637;

    /**
     * IP = DSL discount
     * TV = TV discount
     * BR5 = Discount provided on Kabel side, therefore less discount our side
     * IPandTV = Combination of DSL and TV
     * IPandBR5 = Combination of DSL and BR5
     * Unity = Unitymedia discount
     */
    public static readonly GigakombiDiscounts: GigakombiDiscountsMapping = {
            IP: [177],
            TV: [612],
            BR5: [618],
            IPandTV: [177, 612],
            IPandBR5: [177, 618],
            Unity: [1637]
    };

    public static readonly Giga11PromoIdRedM: number = 865;
    public static readonly Giga11PromoIdRedL: number = 866;
    public static readonly Giga11PromoIdYoungL: number = 867;

    public static readonly Data50Percent7Gb: number = 416;

    public static get GigakombiDiscountIDs (): number[] {

        const ids: number[] = [];

        for (const discounts in this.GigakombiDiscounts) {

            for (const id of this.GigakombiDiscounts[discounts]) {

                if (-1 !== ids.lastIndexOf(id)) {
                    continue;
                }

                ids.push(id);
            }

        }

        return ids;

    }

    public static readonly TariffchangeRedXLSubLevelTariffIds: number[] = [851, 852, 853, 854, 855];

    /**
     * Available Business Transaction Contexts
     */
    public static get AvailableBusinessTransactionContexts (): BusinessTransactionContext[] {

        const btxs: BusinessTransactionContext[] = [
            this.BTX_BNT,
            this.BTX_VVL,
            this.BTX_MULTISIM,
            this.BTX_HARDWARE,
            this.BTX_GIGAKOMBI,
            this.BTX_FAMILY_FRIENDS,
            this.BTX_INLIFE
        ];

        return btxs;

    }

    /**
     * Available Main Subscription IDs Contexts
     */
    public static get AvailableMainSubscriptionIDs (): number[] {

        return [
            this.RedXS_Id,
            this.RedS_Id,
            this.RedM_Id,
            this.RedL_Id,
            this.RedXL_Id
        ];

    }

    public static readonly GigakombiVorteil: StringMap = {
        DSL: 'IP',
        TV: 'TV',
        Kabel: 'BR5',
        DSLandTV: 'IPandTV',
        DSLandKabel: 'IPandBR5',
        Unity: 'Unity'
    };

    public static get SubscriptionIdsToIgnoreInGigakombi (): number[] {
        return [
            this.YoungS_Id
        ];
    }

    /**
     * Eligable sales channel for winback landing pages
     * @TODO eligible
     */
    public static get EligibleSalesChannelForWinbackVoucher (): SalesChannelName [] {
        return [
           this.SALESCHANNEL_CONSUMER
         ];
       }

    /**
     * Winback Customers Group mapping
     */
    public static readonly WinbackGroup: IWinbackGroupMapping = {

        GROUP_A: '01_WEB_I_WBVCGA_R3_P',
        GROUP_C: '01_WEB_I_WBVCGC_R3_P'
     };

     /**
      * Group A Discounts Mapping. The tariff name is used as key to map it to the class name in the tariffs,
      * if changed here should be changed in the cms too.
      */
     public static readonly GroupADiscounts:  ITariffDiscountMapping = {

         RedXS: '5,00',
         RedS: '5,00',
         RedM: '2,50'
      };

     /**
      * Group C Discounts Mapping,The tariff name is used as key to map it to the class name in the tariffs,
      * if changed here should be changed in the cms too.
      */
     public static readonly GroupCDiscounts:  ITariffDiscountMapping = {

         RedXS: '2,50',
         RedS: '5,00',
         RedM: '2,50'
      };

    /**
     * win back service cases
     */
    public static readonly WINBACK_VOUCHER_REDEEMED: string = 'voucherredeemed';
    public static readonly WINBACK_VOUCHER_NOT_PART_OF_CAMPAIGN: string = 'notpartofcampaign';
    public static readonly WINBACK_VOUCHER_CAMPAIGN_OVER: string = 'campaignover';
    public static readonly WINBACK_VOUCHER_DEFAULT: string = 'genericmessage';
    public static readonly WINBACK_LANDING_PAGE: string = 'vvl_winback_landing_page';
}
