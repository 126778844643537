/**
 * @module SalesFlow/evolved/router
 */

import {AtomicDeviceId} from 'core/ids';

import {RouterEvolvedBntRouter} from 'router-evolved/bnt/router-evolved--bnt-router';
import {SubscriptionIdPerSalesChannel} from 'core/ids';
import {ControllerEvolvedBntDeviceDetail} from 'controller-evolved/bnt/controller-evolved--bnt-device-detail';

export class RouterEvolvedBntDeviceDetail extends RouterEvolvedBntRouter {

    /**
     * Get getFocusSubscriptionId in router to be able to used it as fallback for validating device
     *
     */
    public getFocusSubscriptionId (): number {

        const focusSubscriptionIds = this.getFocusSubscriptionIds();

        return focusSubscriptionIds[this.getSalesChannel()];
    }

    public getFocusSubscriptionIds (): SubscriptionIdPerSalesChannel {

        const subscriptionIds: SubscriptionIdPerSalesChannel = {
            consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined,
            young: this.getInjector().getOptions().get('default_young_subscription_id') || undefined,
            soho: this.getInjector().getOptions().get('default_soho_subscription_id') || undefined,
            easy: this.getInjector().getOptions().get('default_easy_subscription_id') || undefined
        };

        // set recommended subscription and on focus if there is one
        if (undefined !== this.getInjector().getFlowState().getRecommendedSubscriptionId()) {

            const recommendedSubscriptionId = this.getInjector().getFlowState().getRecommendedSubscriptionId();

            // recommendedSubscriptionId could be consumer or young from current portfolio
            // so let's find out for which salesChannel we want the recommended id in focus

            const recommendedSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(recommendedSubscriptionId);
            subscriptionIds[recommendedSubscription.subscriptionGroupName] = recommendedSubscriptionId;

        }

        return subscriptionIds;
    }

    private isAtomicOfDevice (checkId: number, deviceId: number): boolean {

        let device = this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
            deviceId,
            this.getSalesChannel()
        );

        if (undefined === device) {
            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());
            device = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            ).device;
        }

        return (undefined !== device.getAtomicDeviceById(checkId));
    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per get parameter
     * 2) Per storage
     * 3) option Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getIncomingDeviceIds (): number[] {

        // [GET, Storage, Option]
        const incomingDeviceIds: number[] = [];

        // @TODO What is the propose of the default_device_id ;)
        const deviceIdFromDefaultOption: number = this.getInjector().getOptions().get('default_device_id');

        if (undefined !== deviceIdFromDefaultOption) {
            incomingDeviceIds.push(deviceIdFromDefaultOption);
        }

        const deviceIdFromOption: number = this.getInjector().getOptions().get('page-device-id');

        if (undefined !== deviceIdFromOption) {
            incomingDeviceIds.push(deviceIdFromOption);
        }

        // @TODO Validate that deviceIdFromStorage is an atomicDevice from page-device-id
        const deviceIdFromStorage: number = this.getInjector().getFlow().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {

            // when uat is testing we have only one device detail page
            // so no validating on page-device-id
            if (true === this.getInjector().getOptions().get('uat')) {
                incomingDeviceIds.push(deviceIdFromStorage);
            }
            else {
                if (true === this.isAtomicOfDevice(deviceIdFromStorage, deviceIdFromOption)) {
                    incomingDeviceIds.push(deviceIdFromStorage);
                }
                else if (true === this.getInjector().getOptions().get('debug')) {
                    const cnsl = console;
                    cnsl.log(deviceIdFromStorage + ' has been ignored while it\'s not an atomic of ' + deviceIdFromOption);
                }

            }

        }

        // @TODO Validate that deviceIdGetParam is an atomicDevice from page-device-id
        const deviceIdGetParam: number = this.getInjector().getGetParameter().getDeviceIdGetParam();
        if (undefined !== deviceIdGetParam) {
            if (true === this.isAtomicOfDevice(deviceIdGetParam, deviceIdFromOption)) {
                incomingDeviceIds.push(deviceIdGetParam);
            }
            else if (true === this.getInjector().getOptions().get('debug')) {
                const cnsl = console;
                cnsl.log(deviceIdGetParam + ' has been ignored while it\'s not an atomic of ' + deviceIdFromOption);
            }

        }

        return incomingDeviceIds.reverse();

    }

    /**
     *
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveDeviceId (incomingDeviceIds: number[]): number {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this.resolveSubscriptionId(this.getSubscriptionIds())
        );

        incomingDeviceIds = incomingDeviceIds.map((deviceId) => {

            const checkDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            // Id is not an deviceId
            if (undefined === checkDevice) {
                return deviceId;
            }

            return checkDevice.getAtomicDeviceByIndex(0).id;

        });

        const validatedDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const checkAtomicDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            const checkDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDeviceIds.length) {
            return undefined;
        }

        return validatedDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<RouterEvolvedBntRouter> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            /** Only set the sub ID if it is not hardwareOnly */
            if (false === this.getInjector().getFlowState().getHardwareOnly()) {
                this.getInjector().getFlowState().setSubscriptionId(this._subscriptionId);
            }

            const atomicDeviceId = new AtomicDeviceId(this._atomicDeviceId);
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedBntDeviceDetail(
                    atomicDeviceId,
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();

    }

}
