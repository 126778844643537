/**
 * @module SalesFlow/evolved/router
 */

import { Constants } from 'core/constants';

import Injector from 'core/injector';

import { BusinessTransactionContext, SalesChannelName, SubscriptionGroupName, TariffGroupName } from 'core/ids';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';

/**
 * @TODO Route focus id
 */
export abstract class RouterEvolvedBaseClassBase {

    private _injector: Injector;

    protected _reposSupervisor: ModelEvolvedRepoSupervisor;

    private _currentPage: string;

    protected _salesChannel: SalesChannelName;

    protected _subscriptionGroup: SubscriptionGroupName;

    protected _subscriptionId: number;

    protected _atomicDeviceId: number;

    protected _btx: BusinessTransactionContext;

    protected _useStorage: boolean;

    constructor (injector: Injector) {

        this._injector = injector;

        this._btx = injector.getBtx();

        this._reposSupervisor = this.getInjector().getReposSupervisor();

        this._currentPage = this.getInjector().getRouting().getCurrentPage();

        this._useStorage = this.getUseStorage();

        // salesChanel [consumer, young, soho] must be determined so early while repos depend on it
        this.setSalesChannel();
        this.setSubscriptionGroup();

        this.resetStoredPassId();
        this.resetStoredData();

        this.getInjector().getFlowState().setTariffGroup(this.getSalesChannel(), this.getSubscriptionGroup(), true);

        /**
         * In most cases, when we start a new flow, the store is not set. So we have
         * to save it manually
         */
        this.getInjector().getFlowState().initializeToStore();

    }

    public getInjector (): Injector {
        return this._injector;
    }

    public getReposSupervisor () {
        return this._reposSupervisor;
    }

    /**
     * Checks, if SalesChannel and subscriptionGroup shall be taken from store or from default
     */
    private getUseStorage (): boolean {

        /**
         * Value set in NSF Page options needer to determine, if data is taken from store or from page-default
         */
        const salesChannelFromOption: SalesChannelName = this.getInjector().getOptions().get('default_sales_channel');

        /**
         * per default page "subscription_overview" is considered as a landingpage. On load, the flow state is already set so it can decide if it needs to get the default options or not.
         *
         * - We need to keep the user's selection in case he comes back
         * - We need to remember the user's selection on page refresh
         *
         */
        let useStorage = false;
        const pagesToUseStorage = [
            'subscription_overview',
            'device_overview',
            'device_detail',
            'connectmore'
        ];

        if ('subscription_overview' === this._currentPage) {

            const storage = this.getInjector().getStorage();

            /**
             * here, we have to read directly from storage, as the flow is
             * not set yet
             */
            const lastStep = storage.getItem('lastStep');

            if ('subscription_overview' === lastStep) {

                const lastLP = storage.getItem('lastLP');

                if (salesChannelFromOption === lastLP) {
                    useStorage = true;
                }
            }

        } else if (-1 !== pagesToUseStorage.indexOf(this._currentPage)) {

            useStorage = true;

        }

        return useStorage;
    }

    /**
     * resets subscriptionIds and atomic
     */
    protected resetStoredData (): void {

        /**
         * just if page is not reloaded
         */
        if (false === this._useStorage) {

            /**
             * reset subscriptionsIds without raising an event
             */
            this.getInjector().getFlow().clearSubscriptionId(true);

            /**
             * reset atomic device without raising an event
             */
            this.getInjector().getFlow().setAtomicDeviceId(undefined, true);
        }

    }

    protected setSalesChannel (): void {

        /**
         * Default value
         */
        let salesChannel: SalesChannelName = Constants.SALESCHANNEL_CONSUMER;

        /**
         * Value set in NSF Page options
         */
        const salesChannelFromOption: SalesChannelName = this.getInjector().getOptions().get('default_sales_channel');

        if (undefined !== salesChannelFromOption && this.getInjector().getFlowState().isValidSalesChannel(salesChannelFromOption)) {
            salesChannel = salesChannelFromOption;
        }

        if (true === this._useStorage) {

            /**
             * Value set Storage
             */

            const salesChannelFromStorage: SalesChannelName = this.getInjector().getFlowState().getSalesChannel();

            if (undefined !== salesChannelFromStorage && this.getInjector().getFlowState().isValidSalesChannel(salesChannelFromStorage)) {
                salesChannel = salesChannelFromStorage;
            }

        }

        /**
         * Value from GET Parameter
         */
        const salesChannelFromGet: string = this.getInjector().getGetParameter().getSalesChannelGetParam();

        if (undefined !== salesChannelFromGet) {

            if (this.getInjector().getFlowState().isValidSalesChannel(salesChannelFromGet)) {
                salesChannel = <SalesChannelName>salesChannelFromGet;
            }

        } else {

            /**
             * if subscriptionGroup not set, but subscriptionId we can get salesChannel implicit.
             *
             * But only, if we're not on a so-calles landingpage (subscription_overview). Because
             * in this case, when we have a get parameter, the page is called directly.
             * so we use the sales channel that is set in nsf options
             */
            const subscriptionIdFromGet: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();

            if (undefined !== subscriptionIdFromGet) {

                if ('subscription_overview' !== this._currentPage) {
                    const isYoungSubscriptionId = [Constants.YoungXS_Id, Constants.YoungS_Id, Constants.YoungM_Id, Constants.YoungL_Id, Constants.YoungXL_Id];

                    if (-1 !== isYoungSubscriptionId.indexOf(subscriptionIdFromGet)) {
                        salesChannel = Constants.SALESCHANNEL_YOUNG;
                    } else {
                        salesChannel = Constants.SALESCHANNEL_CONSUMER;
                    }
                } else {

                    salesChannel = salesChannelFromOption;

                }

            }
        }

        this._salesChannel = salesChannel;

    }

    protected setSubscriptionGroup (): void {

        /**
         * As default salesChannel is '"consumer", the default value is "red"
         */
        let subscriptionGroup: SubscriptionGroupName = 'red';

        /**
         * overwrite default by salesChannel
         */
        if (Constants.SALESCHANNEL_YOUNG === this._salesChannel) {
            subscriptionGroup = 'young';
        } else if (Constants.SALESCHANNEL_SOHO === this._salesChannel) {
            subscriptionGroup = 'soho';
        }

        /**
         * Value set in NSF Page options
         */
        const subscriptionGroupFromOption: SubscriptionGroupName = this.getInjector().getOptions().get('default_subscription_group');

        if (undefined !== subscriptionGroupFromOption && this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroupFromOption)) {
            subscriptionGroup = subscriptionGroupFromOption;
        }

        if (true === this._useStorage) {

            /**
             * Value set Storage
             */

            const subscriptionGroupFromStorage: SubscriptionGroupName = this.getInjector().getFlowState().getSubscriptionGroup();

            if (undefined !== subscriptionGroupFromStorage && this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroupFromStorage)) {
                subscriptionGroup = subscriptionGroupFromStorage;
            }

        }

        /**
         * Value from GET Parameter
         */
        const subscriptionGroupFromGet: string = this.getInjector().getGetParameter().getSubscriptionGroupGetParam();

        if (undefined !== subscriptionGroupFromGet) {

            if (this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroupFromGet)) {
                subscriptionGroup = <SubscriptionGroupName>subscriptionGroupFromGet;
            }

        } else {

            /**
             * if subscriptionGroup not set, but subscriptionId we can get subscriptionGroup and SalesChannel implicit.
             *
             * But only, if we're not on a so-calles landingpage (subscription_overview). Because
             * in this case, when we have a get parameter, the page is called directly.
             * so we use the sales channel that is set in nsf options
             */
            const subscriptionIdFromGet: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();

            if (undefined !== subscriptionIdFromGet) {

                if ('subscription_overview' !== this._currentPage) {

                    const isYoungSubscriptionId = [Constants.YoungXS_Id, Constants.YoungS_Id, Constants.YoungM_Id, Constants.YoungL_Id, Constants.YoungXL_Id];
                    const isEasySubscriptionId = [Constants.EasyS_Id, Constants.EasyM_Id];

                    if (-1 !== isYoungSubscriptionId.indexOf(subscriptionIdFromGet)) {

                        subscriptionGroup = Constants.SUBSCRIPTION_GROUP_YOUNG;

                    } else if (-1 !== isEasySubscriptionId.indexOf(subscriptionIdFromGet)) {

                        subscriptionGroup = Constants.SUBSCRIPTION_GROUP_EASY;

                    } else {

                        subscriptionGroup = Constants.SUBSCRIPTION_GROUP_RED;

                    }

                } else {

                    subscriptionGroup = subscriptionGroupFromOption;

                }

            }
        }

        this._subscriptionGroup = subscriptionGroup;

    }

    /**
     * when a landing page is called directly, the stored passId should be
     * removed. E.g. when you have been to young landing page before and
     * then call soho LP, the socialpass, which has to be preselected in
     * young LP has to be removed in soho LP. As on this page, no pass should
     * be preselcted
     */
    protected resetStoredPassId (): void {

        if (false === this._useStorage) {
            this._injector.getStorage().removeItem('passId');
        }

    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected getSubscriptionGroup (): SubscriptionGroupName {
        return this._subscriptionGroup;
    }

    protected getTariffGroup (): TariffGroupName {

        let tariffGroupName: string = this.getSalesChannel();

        if (Constants.SALESCHANNEL_CONSUMER === this.getSalesChannel() && Constants.SUBSCRIPTION_GROUP_EASY == this.getSubscriptionGroup()) {
            tariffGroupName = this.getSubscriptionGroup();
        }

        return tariffGroupName as TariffGroupName;

    }

    /**
     * Return incoming unvalidated subscriptionIds in order
     * 1) Per option
     * 2) Per storage
     * 3) Per get parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overrule option
     *
     */
    public getSubscriptionIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromOption: number = this.getInjector().getOptions().get('default_' + this.getSalesChannel() + '_subscription_id');

        if (undefined !== subscriptionIdFromOption) {
            incomingSubscriptionIds.push(subscriptionIdFromOption);
        }

        const subscriptionIdFromStorage: number = this.getInjector().getFlowState().getSubscriptionId();

        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {
            incomingSubscriptionIds.push(subscriptionIdGetParam);
        }

        return incomingSubscriptionIds.reverse();

    }

    /**
     *
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveSubscriptionId (incomingSubscriptionIds: number[]): number {

        const validatedSubscriptionIds = incomingSubscriptionIds.filter((tariffId) => {

            return undefined !== this.getReposSupervisor().getSubscriptionRepo().getSubscription(tariffId);

        });

        if (0 === validatedSubscriptionIds.length) {
            return undefined;
        }

        return this.getReposSupervisor().getSubscriptionRepo().getSubscriptionMainId(validatedSubscriptionIds[0]);

    }

    /**
     * Get subscription id -> This can invoked after supervisor is loaded and validating is called, so
     * @TODO Make this callable only when set.
     */
    protected getSubscriptionId (): number {

        return this._subscriptionId;
    }

    /**
     * Get atomicDeviceId -> This can invoked after supervisor is loaded and validating is called, so
     * @TODO Make this callable only when set.
     */
    public getAtomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    public getFocusSubscriptionIds (): any {

        return {
            easy: this.getInjector().getOptions().get('center_tariff_tile_easy_tariff_id') || this.getInjector().getOptions().get('default_easy_subscription_id') || 0,
            consumer: this.getInjector().getOptions().get('center_tariff_tile_consumer_tariff_id') || this.getInjector().getOptions().get('default_consumer_subscription_id') || 0,
            young: this.getInjector().getOptions().get('center_tariff_tile_young_tariff_id') || this.getInjector().getOptions().get('default_young_subscription_id') || 0,
            soho: this.getInjector().getOptions().get('center_tariff_tile_soho_tariff_id') || this.getInjector().getOptions().get('default_soho_subscription_id') || 0
        };

    }

    /**
     * Vlux files that should be loaded in a inherited router into supervisor after it has been initialLoaded
     * @TODO Find a good way to prevent that is invoked before reposSupervisor.initialLoadingDone
     */
    protected abstract loadVluxDataIntoReposSupervisor (): void;

    /**
     * The only async method of a controller should be getting loadReposSupervisor
     * When all xhr are fetched the router creates the controller and most following can be  synchronous
     */
    protected abstract loadReposSupervisor (): JQueryPromise<ModelEvolvedRepoSupervisor>;

    public abstract validateIncoming (): void;

    public abstract createController (): JQueryPromise<any>;

    /**
     * After reading the VLUX JSON Data it has to be send to the General Offer Service
     */
    protected prepareGeneralSalesObject (): void {

        /**
         * get General Sales Object Interface and add loaded vlux data
         */
        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        generalSalesObjectInterface.initOfferServicesEvolved (
            this.getSalesChannel(),
            this._btx,
            this.getReposSupervisor()
        );

    }

}
