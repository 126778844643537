/**
 * @module SalesFlow/shopbackend
 */

/* tslint:disable:cyclomatic-complexity */

import AllowedTariffs from '../model/type/allowed-tariffs';
import {SubscriptionGroupName, ContractPeriodType, InlifeFlowType} from 'core/ids';
import AllowedTariffDefinition from 'model/type/allowed-tariff-definition';
import { Constants } from 'core/constants';

export default class Customer {

    private _isLoggedIn: boolean = false;
    private _salutation: string;
    private _firstname: string;
    private _lastname: string;
    private _msisdn: string;
    private _customerSegment: string;
    private _tariffname: string;
    private _tarifftype: string;
    private _tariffKiasCode: string;
    private _hasBlackTariff: boolean;
    private _hasDataTariff: boolean;
    private _hasFF: boolean;
    private _isYoungCustomer: boolean;
    private _isSohoCustomer: boolean;
    private _isVvlEligible: boolean;
    private _vvlType: string;
    private _vvlCellcode: string;
    private _inlifeCellcode: string;
    private _vvlCamptype: string;
    private _vacantRedPlusSlots: number;
    private _currentTariffSupportsRedPlus: boolean;
    private _redPlusMemberType: string;
    private _numberOfExistingMembers: number;
    private _hasPrivatePricing: boolean;
    private _isSimOnlyVvlAllowed: boolean;
    private _isInlifeEligible: boolean;
    private _hasHandyInsurance: boolean;
    private _tariffSwapNecessary: boolean;
    private _multisimVacantSlots: number;
    private _isMultisimEligible: boolean;
    private _esimSlotsInUse: number;
    private _physicalSimSlotsInUse: number;
    private _tariffCompatibleWithMultisim: boolean;
    private _socCompatibleWithMultisim: boolean;
    private _allowedTariffs: AllowedTariffs;
    private _contractPeriodType: ContractPeriodType;
    private _inlifeFlowType: InlifeFlowType;
    private _isGigaKombiCustomer: false;
    private _isWinbackCustomer: false;

    constructor (nbaJson: any) {

        if (nbaJson.salutation) {
            this._salutation = nbaJson.salutation;
            this._isLoggedIn = true;
        }

        if (nbaJson.firstName) {
            this._firstname = nbaJson.firstName;
        }

        if (nbaJson.lastName) {
            this._lastname = nbaJson.lastName;
        }

        if (nbaJson.MSISDN) {
            this._msisdn = nbaJson.MSISDN.replace('GSM', '+49 ');
            this._isLoggedIn = true;
        }

        if (nbaJson.customerSegment) {
            this._customerSegment = nbaJson.customerSegment;
        }

        if (nbaJson.currentTariffName) {
            this._tariffname = nbaJson.currentTariffName;
        }

        if (nbaJson.currentTariffType) {
            this._tarifftype = nbaJson.currentTariffType;
            this._hasDataTariff = (Constants.RedPlus_Data === this._tarifftype) ? true : false;
        }

        if (nbaJson.currentTariff) {
            this._tariffKiasCode = nbaJson.currentTariff;

        }

        if (nbaJson.hasBlackTariff) {
            this._hasBlackTariff = ('Y' === nbaJson.hasBlackTariff) ? true : false;
        }

        if (nbaJson.hasFF) {
            this._hasFF = ('Y' === nbaJson.hasFF) ? true : false;
        }

        if (nbaJson.isYoungCustomer) {
            this._isYoungCustomer = ('Y' === nbaJson.isYoungCustomer) ? true : false;
        }

        if (nbaJson.accountType) {
            this._isSohoCustomer = ('B' === nbaJson.accountType || 'S' === nbaJson.accountType || this.hasSohoSOC(nbaJson.currentServices)) ? true : false;
        }

        if (nbaJson.vvlEligibility) {
            if (nbaJson.vvlEligibility.eligiblity) {
                this._isVvlEligible = false;
                const eligibility = nbaJson.vvlEligibility.eligiblity;
                for (const type in eligibility) {
                    if ('Y' === eligibility[type]) {
                        this._isVvlEligible = true;
                        this._vvlType = type;
                        break;
                    }
                }
            }

            /**
             * We check private pricing cell code in the vvlEligibility object if the user is VVL eligible
             */
            if (this._isVvlEligible && nbaJson.vvlEligibility.cellcode) {
                this._vvlCellcode = nbaJson.vvlEligibility.cellcode;
                this._hasPrivatePricing = ('' !== this._vvlCellcode) ? true : false;
            }

            // Young Customer doesn't get Private Pricing Discount
            if (true === this._isYoungCustomer) {
                this._hasPrivatePricing = false;
            }

            // Disable Private Pricing for VVL as long as the promo for story CO-3266 is running
            // @TODO remember to set it back to true and remove this code after promo is over !!!
            this._hasPrivatePricing = false;

            this._vvlCamptype = nbaJson.vvlEligibility.camptype;

            if (nbaJson.vvlEligibility.simOnlyAllowed) {
                this._isSimOnlyVvlAllowed = ('Y' === nbaJson.vvlEligibility.simOnlyAllowed) ? true : false;
            }
        }

        if (nbaJson.redplusEligibility) {

            if (nbaJson.redplusEligibility.currentTariffSupportsRedPlus) {
                this._currentTariffSupportsRedPlus = ('Y' === nbaJson.redplusEligibility.currentTariffSupportsRedPlus);
            }

            if (nbaJson.redplusEligibility.redPlusMemberType) {
                this._redPlusMemberType = nbaJson.redplusEligibility.redPlusMemberType;
            }
            if (nbaJson.redplusEligibility.numberOfExistingMembers) {
                this._numberOfExistingMembers = nbaJson.redplusEligibility.numberOfExistingMembers;
            }

            if (nbaJson.redplusEligibility.vacantSlots) {
                if (4 < nbaJson.redplusEligibility.vacantSlots) {
                    this._vacantRedPlusSlots = 4;
                } else {
                    this._vacantRedPlusSlots = nbaJson.redplusEligibility.vacantSlots;
                }
            } else {
                this._vacantRedPlusSlots = 0;
            }
        }

        if (nbaJson.inlifeTariffChangeEligibility) {
            this._isInlifeEligible = ('Y' === nbaJson.inlifeTariffChangeEligibility.eligiblity) ? true : false;

            if (nbaJson.inlifeTariffChangeEligibility.allowedTariffs && 0 < nbaJson.inlifeTariffChangeEligibility.allowedTariffs.length) {
                this._allowedTariffs = new AllowedTariffs(nbaJson.inlifeTariffChangeEligibility.allowedTariffs);
            }

            /**
             * If tariffchange eligibility object has a cellcode, then this user have private pricing
             */
            if (this._isInlifeEligible && nbaJson.inlifeTariffChangeEligibility.cellcode) {
                this._inlifeCellcode = nbaJson.inlifeTariffChangeEligibility.cellcode;
                this._hasPrivatePricing = ('' !== this._inlifeCellcode) ? true : false;
            }
        }

        if (nbaJson.hasHandyVersicherung) {
            this._hasHandyInsurance = ('Y' === nbaJson.hasHandyVersicherung) ? true : false;
        }

        if (nbaJson.vvlEligibility && nbaJson.vvlEligibility.tariffSwapNecessary) {
            this._tariffSwapNecessary = ('Y' === nbaJson.vvlEligibility.tariffSwapNecessary) ? true : false;
        }

        if (nbaJson.ultracardEligibility) {
            if (nbaJson.ultracardEligibility.eligibility) {
                this._isMultisimEligible = false;
                const multisimEligibility = nbaJson.ultracardEligibility;
                /*
                 * if eligibility is present, set _isMultisimEligible true and set count to vacantslots
                 */
                if ('Y' === multisimEligibility.eligibility) {
                    this._isMultisimEligible = true;
                }
                this._multisimVacantSlots = multisimEligibility.vacantSlots;
                this._esimSlotsInUse = multisimEligibility.eSlotsInUse;
                this._physicalSimSlotsInUse = multisimEligibility.pSlotsInUse;
                this._tariffCompatibleWithMultisim = multisimEligibility.tariffCompat === 'Y' ? true : false;
                this._socCompatibleWithMultisim = multisimEligibility.SocCompat === 'Y' ? true : false;
            }

        }

         /**
          * [CO-9456] Checks if there is the gigakombi flag and winback flag to identify
          * if the user is gigakombi or has canceled their contract socalled winback customers
          */
         if (nbaJson.customerDetails) {
            if (nbaJson.customerDetails.hasOwnProperty('isGigaKombiCustomer')) {
                this._isGigaKombiCustomer = nbaJson.customerDetails.isGigaKombiCustomer;

            }
            if (nbaJson.customerDetails.hasOwnProperty('isWinbackCustomer')) {
                this._isWinbackCustomer = nbaJson.customerDetails.isWinbackCustomer;

            }
        }

}

    public fromFlow (flowJson: any) {
        this._isLoggedIn = flowJson._isLoggedIn;
        this._salutation = flowJson._salutation;
        this._firstname = flowJson._firstname;
        this._lastname = flowJson._lastname;
        this._msisdn = flowJson._msisdn;
        this._customerSegment = flowJson._customerSegment;
        this._tariffname = flowJson._tariffname;
        this._tarifftype = flowJson._tarifftype;
        this._tariffKiasCode = flowJson._tariffKiasCode;
        this._hasBlackTariff = flowJson._hasBlackTariff;
        this._hasDataTariff = flowJson._hasDataTariff;
        this._hasFF = flowJson._hasFF;
        this._isYoungCustomer = flowJson._isYoungCustomer;
        this._isSohoCustomer = flowJson._isSohoCustomer;
        this._isVvlEligible = flowJson._isVvlEligible;
        this._vvlType = flowJson._vvlType;
        this._vvlCellcode = flowJson._vvlCellcode;
        this._inlifeCellcode = flowJson._inlifeCellcode;
        this._vvlCamptype = flowJson._vvlCamptype;
        this._vacantRedPlusSlots = flowJson._vacantRedPlusSlots;
        this._currentTariffSupportsRedPlus = flowJson._currentTariffSupportsRedPlus;
        this._redPlusMemberType = flowJson._redPlusMemberType;
        this._hasPrivatePricing = flowJson._hasPrivatePricing;
        this._isSimOnlyVvlAllowed = flowJson._isSimOnlyVvlAllowed;
        this._isInlifeEligible = flowJson._isInlifeEligible;
        this._hasHandyInsurance = flowJson._hasHandyInsurance;
        this._isMultisimEligible = flowJson._isMultisimEligible;
        this._multisimVacantSlots = flowJson._multisimVacantSlots;
        this._allowedTariffs = flowJson._allowedTariffs;
    }

    private hasSohoSOC (currentServices: any): boolean {
        let hasSOC = false;
        for (const i in currentServices) {
            if ('SOHOV15_1' === currentServices[i].backendId || 'SOHOV15' === currentServices[i].backendId) {
                hasSOC = true;
                break;
            }
        }

        return hasSOC;
    }

    /**
     * What subscriptionGroupName should be show to user
     *
     * @memberof Customer
     */
    public getSubscriptionGroupName (): SubscriptionGroupName {

        /**
         * [VGOF-5736]
         * Return familyfriends in case user is Family & Friends?
         */
        if (this.hasFF) {
            return 'familyfriends';
        }

        /**
         * If customer has currently a red subscription
         * He/she should be offered a red subscription
         */
        if (0 === this._tariffKiasCode.lastIndexOf('RED')) {
            return 'consumer';
        }

        if (true === this.isYoungCustomer) {
            return 'young';
        }

        return 'consumer';

    }

    public setContractPeriodType (subscriptionId: number): ContractPeriodType {

        const allowedTariffs = this.allowedTariffs.allowedTariffDefinition;
        const allowedTariffIdFound = this.getAllowedTariffId(allowedTariffs, subscriptionId);

        if (undefined !== allowedTariffIdFound) {
            this._contractPeriodType = allowedTariffIdFound.contractPeriod;
        }

        return this._contractPeriodType;

    }

    protected getAllowedTariffId (allowedTariffs: AllowedTariffDefinition[], subscriptionId: number): any {
        let i = 0;
        const l = allowedTariffs.length;

        for (; i < l; i++) {
            const allowedTariff = allowedTariffs[i];
            if (allowedTariff.subscriptionId === subscriptionId) {
                return allowedTariff;
            }
        }

        return undefined;
    }

    public setTariffchangeFlowType () {

        switch (this._contractPeriodType) {
            case 'N':
                this._inlifeFlowType = 'INL24';
                break;
            case 'R':
                this._inlifeFlowType = 'INL';
                break;
            default:
                this._inlifeFlowType = undefined;
                break;
        }
    }

    get inlifeFlowType (): InlifeFlowType {
        this.setTariffchangeFlowType();

        return this._inlifeFlowType;
    }

    get contractPeriodType (): ContractPeriodType {
        return this._contractPeriodType;
    }

    public toString () {
        return JSON.stringify(this);
    }

    get isLoggedIn (): boolean {
        return this._isLoggedIn;
    }

    get salutation (): string {
        return this._salutation;
    }

    get firstname (): string {
        return this._firstname;
    }

    get lastname (): string {
        return this._lastname;
    }

    get msisdn (): string {
        return this._msisdn;
    }

    get tariffname (): string {
        return this._tariffname;
    }

    get tariffKiasCode (): string {
        return this._tariffKiasCode;
    }

    get customerSegment (): string {
        return this._customerSegment;
    }

    get hasFF (): boolean {
        return this._hasFF;
    }

    get hasBlackTariff (): boolean {
        return this._hasBlackTariff;
    }

    get hasDataTariff (): boolean {
        return this._hasDataTariff;
    }

    get isYoungCustomer (): boolean {
        return this._isYoungCustomer;
    }

    get isSohoCustomer (): boolean {
        return this._isSohoCustomer;
    }

    get isVVLEligible (): boolean {
        return this._isVvlEligible;
    }

    get isMultisimEligible (): boolean {
        return this._isMultisimEligible;
    }

    get multisimVacantSlots (): number {
        return this._multisimVacantSlots;
    }

    get isInlifeEligible (): boolean {
        return this._isInlifeEligible;
    }

    get prolongationType (): string {
        return this._vvlType;
    }

    get vvlCellcode (): string {
        return this._vvlCellcode;
    }

    get inlifeCellcode (): string {
        return this._inlifeCellcode;
    }

    get vvlCamptype (): string {
        return this._vvlCamptype;
    }

    get vacantRedPlusSlots (): number {
        return this._vacantRedPlusSlots;
    }
    get numberOfExistingRedPlusMembers (): number {
        return this._numberOfExistingMembers;
    }

    get hasPrivatePricing (): boolean {
        return this._hasPrivatePricing;
    }

    get isSimOnlyVvlAllowed (): boolean {
        return this._isSimOnlyVvlAllowed;
    }

    get hastHandyInsurance (): boolean {
        return this._hasHandyInsurance;
    }

    get tariffSwapNecessary (): boolean {
        return this._tariffSwapNecessary;
    }

    get hasExistingRedPlus (): boolean {
        return (this.vacantRedPlusSlots < 4);
    }

    get physicalSimSlotsInUse (): number {
        return this._physicalSimSlotsInUse;
    }

    get esimSlotsInUse (): number {
        return this._esimSlotsInUse;
    }

    get isTariffMultisimEligible (): boolean {
        return this._tariffCompatibleWithMultisim;
    }

    get isSocMultisimEligible (): boolean {
        return this._socCompatibleWithMultisim;
    }

    get allowedTariffs (): AllowedTariffs {
        return this._allowedTariffs;
    }

    get currentTariffSupportsRedPlus (): boolean {
        return this._currentTariffSupportsRedPlus;
    }

    get redPlusMemberType (): string {
        return this._redPlusMemberType;
    }

    /**
     * Check, if customer ist redplus elegible:
     *
     *  'Y' === redplusEligibility.currentTariffSupportsRedPlus
     *  'O' === redplusEligibility.memberType
     *   0 < redplusEligibility.vacantSlots
     */
     get isRedPlusElegible (): boolean {

        let elegible = true;

        if (false === this._currentTariffSupportsRedPlus) {
            elegible = false;
        }

        if ('0' !== this._redPlusMemberType) {
            elegible = false;
        }

        if (0 < this._vacantRedPlusSlots) {
            elegible = false;
        }

        return elegible;

    }

    get isGigaKombiCustomer (): boolean {
        return this._isGigaKombiCustomer;
    }
    get isWinbackCustomer (): boolean {
        return this._isWinbackCustomer;
    }
 }
