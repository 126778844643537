/**
 * @module SalesFlow/view
 */
import {BusinessTransactionContext} from 'core/ids';
import { Constants } from 'core/constants';

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';

import {Renderable} from 'view/renderable';

import DeviceOffer from 'view/view/shared/offer/device-offer';
import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';

import Subscription from 'model/type/subscription';

export class ViewEvolvedElementBntCmRedplusDeviceTile extends Renderable<DeviceOffer> {

    private _container: JQuery;
    private _element: JQuery;
    private _type: string;
    private _deviceOffer: DeviceOffer;
    private _subscription: Subscription;
    protected _btx: BusinessTransactionContext;

    constructor (injector: Injector, container: JQuery, type: string, subscription: Subscription) {

        super(injector);

        this._btx = injector.getBtx();
        this._container = container;
        this._type = type;
        this._subscription = subscription;

    }

    public render (deviceOffer: DeviceOffer): void {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        this._deviceOffer = deviceOffer;
        this._container.append(this.getInjector().getTemplates().render('cm_device_tile', deviceOffer));
        this._element = this._container.find('.device-module-tile[data-atomic-device-id="' + deviceOffer.atomicDeviceId + '"]');

        let flowOfferIds;

        if (Constants.RedPlus_Allnet === this._type) {

            flowOfferIds = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements;

        }

        if (Constants.RedPlus_Data === this._type) {

            flowOfferIds = this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements;

        }

        /**
         * If the user selected an offer that can be found within this tile, the offer needs to be changed if it is not the default one selected
         */
        if (0 < flowOfferIds.length) {

            if (flowOfferIds[0] !== deviceOffer.offer.offerId) {

                const generalOfferServiceInterface = this.getInjector().getGeneralSalesObjectInterface();
                const offer = generalOfferServiceInterface.getRedPlusOfferById(
                    flowOfferIds[0],
                    salesChannel);

                const atomicDevices = this._deviceOffer.atomicDevice.device.getAtomicDevices();
                let atomicDeviceFound = false;

                for (const i in atomicDevices) {

                    if (offer.deviceId === atomicDevices[i].id) {

                        atomicDeviceFound = true;

                    }

                }

                if (atomicDeviceFound) {

                    let atomicDevice;

                    if (Constants.RedPlus_Data === this._type) {

                        atomicDevice = this.getInjector().getReposSupervisor().getPurchasableTabletRepo().getAtomicDevice(
                            offer.deviceId,
                            salesChannel,
                            this._subscription
                        );

                    } else {

                        atomicDevice = this.getInjector().getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                            offer.deviceId,
                            salesChannel,
                            this._subscription
                        );

                    }

                    const deviceOffer = new DeviceOffer(atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId), this._subscription, offer);
                    this.update(deviceOffer);
                    this._element.addClass('selected');

                }

            }
            else {

                this._element.addClass('selected');

            }

        }

    }

    public update (deviceOffer: DeviceOffer): void {

        this._deviceOffer = deviceOffer;

        /**
         * Save some information of the old tile
         */
        const tileWidth = this._element[0].getBoundingClientRect().width;
        const tileWasSelected = this._element.hasClass('selected');
        const colorPickerHeight = this._element.find('.color').height();

        /**
         * Replace the old tile with the new offer
         * Important: Keep information about the color picker height
         */
        this._element.replaceWith(this.getInjector().getTemplates().render('cm_device_tile', deviceOffer));
        this._element = this._container.find('.device-module-tile[data-atomic-device-id="' + deviceOffer.atomicDeviceId + '"]');
        this._element.css('width', tileWidth);
        this._element.find('.color').height(colorPickerHeight);

        /**
         * Events need to reinit because the element has changed
         */
        this.events();

        /**
         * If the tile was selected we trigger a click on the new tile
         */
        if (tileWasSelected) {

            this._element.find('.selectionRadio').trigger('click');

        }

    }

    public events (): void {

        this._element.on('click', '.selectionRadio', (evt) => {

            if (this._element.hasClass('selected')) {

                return;

            }

            /**
             * Remove the selected class from "Ohne Smartphone bestellen"
             */
            this._container.parents('.stateTwo').find('.selectionRedplusOnly.selected').removeClass('selected');

            /**
             * Remove the class from selected tile and add to the new selection
             */
            this._element.siblings('.device-module-tile.selected').removeClass('selected');
            this._element.addClass('selected');

            /**
             * Update monthly price in accrodion head
             */
            this._container.parents('.mod-cm-option').find('.baseBox .price').html(this._deviceOffer.monthlyDiscountPrice.toFixed(2).replace('.', ',') + ' &euro;');

            /**
             * Update Red+ offer
             */
            this.setFlowRedPlusOffer();
            this._injector.getEvent().trigger('redPlusOffer@changed');
        });

        this._element.on('click', '.picker .valueBox', (evt) => {

            /**
             * If the picker is disabled or already selected we don't need to update the deviceOffer
             */
            if (true === $(evt.currentTarget).hasClass('disabled') || true === $(evt.currentTarget).hasClass('selected')) {

                return;

            }

            const atomicDeviceId = parseInt($(evt.currentTarget).data('atomicdeviceid'), 10);

            if (true === isNaN(atomicDeviceId)) {

                return;

            }

            let atomicDevice;

            if (Constants.RedPlus_Data === this._type) {

                atomicDevice = this.getInjector().getReposSupervisor().getPurchasableTabletRepo().getAtomicDevice(
                    atomicDeviceId,
                    this.getInjector().getFlowState().getSalesChannel(),
                    this._subscription
                );

            }
            else {

                atomicDevice = this.getInjector().getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                    atomicDeviceId,
                    this.getInjector().getFlowState().getSalesChannel(),
                    this._subscription
                );

            }

            const generalOfferServiceInterface = this.getInjector().getGeneralSalesObjectInterface();

            const offer = generalOfferServiceInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                atomicDevice.id,
                this._subscription.id,
                Constants.BTX_GIGAKOMBI === this._btx ? Constants.BTX_BNT : this._btx,
                this.getInjector().getFlowState().getSalesChannel()
            );

            const deviceOffer = new DeviceOffer(atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId), this._subscription, offer);

            /**
             * Update the device tile with the new offer
             */
            this.update(deviceOffer);

        });

        this._element.on('click', '.device-details', (evt) => {
            const viewOverlay = new ViewOverlayDeviceDetails(this._injector, this._deviceOffer, this.getInjector().getReposSupervisor().getAttributeRepo());
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
            vf.tabs.init();

            vf['responsive-table'].init();

        });
    }

    private setFlowRedPlusOffer (): void {

        if (Constants.RedPlus_Allnet === this._type) {

            this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.resetAllElements();
            this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.addElement(this._deviceOffer.offer.offerId);

        }

        if (Constants.RedPlus_Data === this._type) {

            this.getInjector().getFlowStateWithSalesChannel().redPlusData.resetAllElements();
            this.getInjector().getFlowStateWithSalesChannel().redPlusData.addElement(this._deviceOffer.offer.offerId);

        }

    }

}
