(function() {
    'use strict';
    vf['insurance-tt'] = {
        settings: {
            target: '.mod-insurance-tt',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {

                $('.checkBox').click(function(e) {

                    e.preventDefault();
                    e.stopPropagation();

                    if ( true === $(this).hasClass('selected')) {
                        $('.insuranceTT').css( 'display', 'block' );

                        $('body').one('click', function(){
                            $('.insuranceTT').css( 'display', 'none' );
                        });

                        setTimeout(function() {
                            $('.insuranceTT').css( 'display', 'none' );
                        }, 3000);

                    } else {
                        $('.insuranceTT').css( 'display', 'none' );
                    };
                });
            });
        },
    };
}(vf));
