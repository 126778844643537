/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import Customer from 'shopbackend/customer';
import Nba from 'shopbackend/nba';

import {RouterEvolvedInlifeRecommendation} from 'router-evolved/inlife/router-evolved--inlife--recommendation';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class InlifeRouting extends Routing {

    protected _validPages: string[] = [
        'inlife_recommendation'
    ];

    /**
     * CO-3209, This function gets the URL that we will redirect the user to if he is not allowed to enter inlife curiosity flow
     * We redirect Soho, Young and FF users to inlife legacy flow.
     * We redirect black users and users with no allowed tariffs to hotline notification.
     * If the customer has private pricing, we redirect him to recommendation page. Else we redirect him to tariff selection page
     * @param _customer
     */
    private getRedirectURL (_customer: Customer): string {
        let redirectUrl: string = '';
        const customer = _customer;

        if (undefined === typeof customer.allowedTariffs || 0 === customer.allowedTariffs.allowedTariffDefinition.length) {

            /**
             * really impossible case, but ca happen. so better catch it up
             */

            redirectUrl = this._injector.getOptions().get('vlux_url_404');

        } else  if (customer.hasFF) {

            if (customer.hasPrivatePricing) {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_faf_privatepricing');
            } else {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_faf');
            }

        } else if (customer.isSohoCustomer) {

            if (customer.hasPrivatePricing) {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_soho_privatepricing');
            } else {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_soho');
            }

        } else if (customer.isYoungCustomer) {

            if (customer.hasPrivatePricing) {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_young_privatepricing');
            } else {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_young');
            }

        } else if (customer.hasPrivatePricing) {
            redirectUrl = this._injector.getOptions().get('inlife_slotmachine_consumer_privatepricing');
        }

        if (customer.hasBlackTariff) {
            redirectUrl = this._injector.getOptions().get('inlife_black_tariff');
        }

        return redirectUrl;
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();
        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();
        const nba: Nba = new Nba();

        nba.getData(forApp, 'tariffchange', 'online.consumer').then((customer: Customer) => {
            this._injector.getFlow().setCustomer(customer);
            const redirectUrl = this.getRedirectURL(customer);

            if ('' === redirectUrl) {

                deferred.resolve(new RouterEvolvedInlifeRecommendation(this._injector));

            } else {
                window.location.href = redirectUrl;
            }
        }, (error) => {
            if (401 === error.status) {
                const redirectUrl = document.location.pathname + decodeURIComponent(document.location.search);
                window.location.href = '/shop/authentifizierung.html?btype=tariffChange&goto=' + this.getRedirectUrlWithAffiliateParameters(redirectUrl);
            } else {
                deferred.reject();
            }
        });

        return deferred.promise();

    }

}
