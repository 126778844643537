/**
 * @module SalesFlow/view
 */

import Stepper from '../shared/stepper';
import Step from '../../view/shared/step';

/**
 * Check div#sales-flow-stepper (rendered by middleman or opentext) and resolve current step
 * While sales flow is dispatched based on current step, this class is not part of frontend
 *
 * Animates, too
 */
export default class StepperGigakombi extends Stepper {

    protected _stepNames: string[] = [
        'device_overview', 'device_detail', 'connectmore'
    ];

    public events (): void {

        this.getInjector().getEvent().listen('stepper@device_detail_url', (eventObject: JQueryEventObject, url: string) => {
            this.setDeviceDetailUrl(url + '?giga=kombi');
        });

        super.events();

    }

    /**
     * Duplicated code form shared/stepper.ts !!
     */
    protected getData (): {steps: Step[], basket: Step} {

        let activeIndex: number|null = null;

        const steps: Step[] = this._stepNames.map((name, index) => {

            const text = this.getInjector().getStrings().get('stepper.' + name);
            let href = this.getInjector().getOptions().get(name + '_url');

            if ('device_detail' === name) {
                href = this.getInjector().getOptions().get(name + '_prefix');
            }
            else {
                href += '?giga=kombi';
            }

            let status;
            if (this._currentStep === this._stepNames[index]) {
                activeIndex = index;
                status = 'active';
            }

            if (activeIndex !== null && activeIndex < index) {
                status = 'inactive';
            }

            if (activeIndex > index || activeIndex === null) {
                status = 'completed';
            }

            return new Step(name, text, href, status, index + 1);

        });

        const text = this.getInjector().getStrings().get('stepper.basket');
        const href = this.getInjector().getOptions().get('basket_url');
        let active = 'inactive';
        if (this._currentStep === 'basket') {
            active = 'active';
        }
        const basket = new Step('basket', text, href, active, this._stepNames.length + 1);

        return {steps: steps, basket: basket};

    }

}
