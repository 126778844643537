/**
 * @module SalesFlow/view
 */

import { Constants } from 'core/constants';

import {ViewEvolvedCtasBase} from 'view-evolved/ctas/vvl/view-evolved--ctas--base';
import {FlowCta} from 'view/ctas/shared/flow-cta';
import Offer from 'view/view/shared/offer/offer';

declare var $: JQueryStatic;

/**
 * Two buttons here
 * Right: Link to x-sellpage
 * Left: If user came from recommendation page, button to go back
 *
 * @export
 * @class ViewEvolvedCtasDeviceOverview
 * @extends {ViewEvolvedCtasBase}
 */
export class ViewEvolvedCtasDeviceOverview extends ViewEvolvedCtasBase {

    protected getLeftButton (): FlowCta {

        // only add the back button if the user came from the recommendation page
        if (true === this.wasOnRecommendationPage()) {
            return new FlowCta(
                ['btn-alt', 'nfs-device-overview-back'],
                this.getInjector().getStrings().get('vvl.pricebox.back.text')
            );
        } else {
            return undefined;
        }
    }

    protected getRightButton (): FlowCta {

        if (true === this.wasOnRecommendationPage()) {
            return new FlowCta(
                ['nfs-device-overview-confirm', 'nsf-small-button-width'],
                this.getInjector().getStrings().get('vvl.pricebox.confirm.recommendation.text1')
            );
        } else {
            return new FlowCta(
                ['nfs-device-overview-confirm'],
                this.getInjector().getStrings().get('vvl.pricebox.confirm.recommendation.text1')
            );
        }

    }

    protected wasOnRecommendationPage () {
        return this.getInjector().getStorage().hasItem('wasOnRecommendationPage') && this.getInjector().getStorage().getItem('wasOnRecommendationPage') === 'true';
    }

    /*
    * Functions to return the urls for the CTAs based on a string coming from nsf-options
    * We created these functions in order to override them inside the different sales-chnanel i.e. soho-vvl
    * and deliver different left and right buttons with custom urls, without implementing again the update method
    * when extending the class i.e. for SOHO VVL
    */
    protected getRightCtaUrl (): string {
        return this.getInjector().getOptions().get('faf_vvl_x_sell_url');

    }

    protected getLeftCtaUrl (): string {

        return this.getInjector().getOptions().get('vvl_recommendation_url');

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        let rightCtaUrl: string = this.getRightCtaUrl();

        /**
         * Smart Tariffs do not have red plus cards, so go to basket
         * @TODO Is it possible to check for the smart subscription group instead
         */
        if (
            Constants.SmartS_Id === this._offer.subscriptionId ||
            Constants.SmartM_Id === this._offer.subscriptionId ||
            Constants.SmartS_2013_Id === this._offer.subscriptionId ||
            Constants.SmartM_2013_Id === this._offer.subscriptionId
        ) {

            rightCtaUrl = '#proceedToBasket';

        }

        /**
         * Customer already has used all red plus slots, so go to basket
         */
        if ( this.getInjector().getFlow().customer.vacantRedPlusSlots < 1) {
            rightCtaUrl = '#proceedToBasket';
        }

        this._right.setHref(
            rightCtaUrl
        );

        if (true === offer.isSimOnly()) {

            const simonlyWaring = this.getInjector().getTemplates().render('simonly-notification-faf', {}, 'partials');

            this._right.setNotification(simonlyWaring);

        }

        // Only set the back button if user is coming from recommendation page
        if (true === this.wasOnRecommendationPage()) {
            const leftCtaUrl: string = this.getLeftCtaUrl();

            this._left.setHref(
                leftCtaUrl
            );

            this._element.html(
                this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
            );
        } else {
            this._element.html(
                this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
            );
        }

        // This event is only used in simonly-handling
        this.getInjector().getEvent().trigger('confirmButton@set', {});

    }

    protected events (): void {
        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            this.getInjector().getLoadingIndicator().show();

            if ('#proceedToBasket' === this._right.href) {

                evt.preventDefault();

                this.createBasketAndProceed();

                return false;
            }

        });

    }

}
