/**
 * @module SalesFlow/controller-evolved
 */

import {Constants} from 'core/constants';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';
import Pricebox from 'view/element/shared/pricebox';

import DeviceOffer from 'view/view/shared/offer/device-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import Customer from 'shopbackend/customer';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';

import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';

import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {SalesChannelName} from 'core/ids';
import {ViewEvolvedElementVvlContractData} from 'view-evolved/element/vvl/view-evolved--element-vvl--contract-data';
import PrivatePricingBadge from 'view/element/vvl/private-pricing-badge';
import {ViewEvolvedElementVvlSubscriptionRecommendation} from 'view-evolved/element/vvl/view-evolved--element-vvl--subscription-recommendation';
import {ViewEvolvedElementVvlDetailDetail} from 'view-evolved/element/vvl/view-evolved--element-vvl--detail-detail';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {ViewEvolvedCtasRecommendation} from 'view-evolved/ctas/vvl/view-evolved--ctas--recommendation';
import {ViewEvolvedElementSharedExitIntent} from 'view-evolved/element/shared/view-evolved--element-shared--exit-intent-overlay';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

export class ControllerEvolvedVvlRecommendation extends ControllerEvolvedFlowBase {

    private _salesChannel: SalesChannelName;

    protected _customer: Customer;
    protected _contractData: ViewEvolvedElementVvlContractData;
    protected _privatePricingBadge: PrivatePricingBadge;
    protected _deviceOffer: DeviceOffer;

    protected _exitIntentOverlay: ViewEvolvedElementSharedExitIntent;

    protected _atomicDeviceId: number;
    protected _subscriptionId: number;

    protected _atomicDevice: AtomicDevice;

    private _tariffTiles: JQuery;

    private _subscription: Subscription;

    private _deviceDetail: ViewEvolvedElementVvlDetailDetail;

    private _pricebox: Pricebox;

    private _element: JQuery;

    protected _subscriptionSelection: ViewEvolvedElementVvlSubscriptionRecommendation;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('Recommendation Controller VVL ....');
        }

        this._salesChannel = salesChannel;

        this._subscriptionId = subscriptionId;
        this._atomicDeviceId = atomicDeviceId;

        this._exitIntentOverlay = new ViewEvolvedElementSharedExitIntent(injector);
        this._exitIntentOverlay.bind();

        this._element = $('#nsf-subscription-device-detail-wrapper');

        this._tariffTiles = this._element.find('.tariff-module-tile[data-subscription-id]');

        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        this._contractData = new ViewEvolvedElementVvlContractData(injector);
        this._privatePricingBadge = new PrivatePricingBadge(injector, this._element);
        this._deviceDetail = new ViewEvolvedElementVvlDetailDetail(injector);
        this._subscriptionSelection = new ViewEvolvedElementVvlSubscriptionRecommendation(this._subscriptionId, injector);
        this._pricebox = new Pricebox(injector);

        this._generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

    }

    protected createCtas (): FlowCtas {

        return new ViewEvolvedCtasRecommendation(this._injector);

    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected render (data?: any): void {
        // Set recommended class to the module belt to to define specific styles for pricebox and cost overview
        $('body').find('.module-belt').addClass('recommendation-page');

        if ('mobile' !== vf.util.layout(true)) {
            $('.vll-list-container').toggleClass('vll-accordion-is-open');
            $('.vll-accordion-toggle').css('display', 'none');
        }

    }

    public events () {

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            this._atomicDeviceId = data.atomicDeviceId;

            this._deviceOffer = this.getDeviceOfferWithSubscription();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this._deviceOffer
            });
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'personal offer',
                pageType: 'product detail',
                deviceOffer: this._deviceOffer,
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            });
        });

        this.getInjector().getEvent().listen('device-detail@atomicIdChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleDeviceTileAtomicIdChanged(atomicDevice, subscription);

        });

        this.getInjector().getEvent().listen('device-detail@insuranceChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleInsuranceChanged(atomicDevice, subscription);

        });

        this.getInjector().getEvent().listen('device-detail@openDetailOverlay', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.deviceOffer;

            const viewOverlay = new ViewOverlayDeviceDetails(this._injector, deviceOffer, this.getReposSupervisor().getAttributeRepo());
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
            vf.tabs.init();

            vf['responsive-table'].init();

            this.getInjector().getEvent().trigger('tracking@Layover', {
                name: 'product details ' + deviceOffer.atomicDevice.name
            });

        });

        this.getInjector().getEvent().listen('check@InsuranceSelection', () => {

            const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                this._atomicDeviceId,
                this._subscriptionId,
                this._btx,
                this.getSalesChannel()
            );

            const optionalServices = vluxOffer.getOptionalServices()[0].services;
            const optionalServiceSelected = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;
            for (const serviceId of optionalServiceSelected) {
                for (const index in optionalServices) {
                    if (serviceId === optionalServices[index].id) {
                        /**
                         * Special case handy insurance: Service is already included in Red XL and Red XXL. Do not show option if one of these tariffs are selected
                         */
                        if (optionalServices[index].id === vluxOffer.getHandyInsuranceServiceId() && vluxOffer.hasHandyInsuranceIncluded()) {
                            continue;
                        }

                        this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.removeElement(serviceId);
                    }
                }
            }
        });

    }

    private handleDeviceTileAtomicIdChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        const deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);

        this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id);
        this.getInjector().getFlowStateWithSalesChannel().lockDevice();

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: deviceOffer
        });

        this.getInjector().getEvent().trigger('device-detail@offerChanged:' + deviceOffer.atomicDevice.device.id, {
            deviceOffer: deviceOffer
        });

    }

    private handleInsuranceChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        this._deviceOffer = this.getDeviceOfferWithSubscription();

        let insuranceSelected: boolean = false;

        // the recommended subscription does not have insurance included
        if (false === this._deviceOffer.offer.hasHandyInsuranceIncluded()) {

            for (const serviceId of this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements) {

                if ((158 === serviceId || 159 === serviceId)) {
                    insuranceSelected = true;
                }

            }

        }

        this.getInjector().getFlowStateWithSalesChannel().setRecommendedInsurance(insuranceSelected);

        this.getInjector().getEvent().trigger('device-detail@offerChanged:' + atomicDevice.device.id, {
            deviceOffer: this._deviceOffer
        });

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: this._deviceOffer
        });

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this._deviceOffer,
                pageName: 'personal offer',
                pageType: 'product detail',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            }
        );
    }

    /**
     * @TODO getDeviceOfferWithSubscription and getOneDeviceOffer seems to duplicate functionality from each other
     * Please decide or one and stick to it
     * getDeviceOfferWithSubscription takes care on red plus and getOneDeviceOffer does not
     */
    protected getDeviceOfferWithSubscription (): DeviceOffer {

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this._subscriptionId);

        this._atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                this._atomicDeviceId,
                this.getSalesChannel(),
                this._subscription
        );

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
            this._atomicDeviceId,
            this._subscription.id,
            this._btx,
            this.getSalesChannel()
        );

        /**
         *  Did the user opts in a RedPlus Option
         */
        const offersRedPlus: any[] = [];

        for (const offerId of this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.concat(this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.concat(this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements))) {

            const generalOfferServiceInterface = this.getInjector().getGeneralSalesObjectInterface();
            const redPlusOffer = generalOfferServiceInterface.getRedPlusOfferById(
                offerId,
                this.getSalesChannel());
            const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(redPlusOffer.subscriptionId);

            offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));

        }

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;
        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this._subscriptionId);
        this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(this._atomicDeviceId);

        return new DeviceOffer(
            this._atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            this._subscription,
            vluxOffer,
            offersRedPlus,
            optionalServiceIds
        );

    }

    protected getOneDeviceOffer (atomicDevice: AtomicDevice, subscription: Subscription): DeviceOffer {

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
            atomicDevice.id,
            subscription.id,
            this._btx,
            this.getSalesChannel()
        );

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        // @TODO Why is redplus unhandle
        return new DeviceOffer(
            atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            subscription,
            vluxOffer,
            [],
            optionalServiceIds
        );

    }

    /*
     * @TODO singular and change _subscriptionSelection to remove unused immediately
    */
    protected getSubscriptions (): DeviceOffer[] {
        const viewDeviceOffers: DeviceOffer[] = [];
        let viewDeviceOffer: DeviceOffer;

        for (const subscriptionId of this._subscriptionSelection.getSubscriptionIds(this._subscriptionId)) {
            if (this._subscriptionId === subscriptionId) {
                const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
                const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                    this._atomicDevice.id,
                    subscriptionId,
                    this._btx,
                    this.getSalesChannel()
                );

                if (undefined !== offer) {

                    viewDeviceOffer = new DeviceOffer(this._atomicDevice, subscription, offer);

                    viewDeviceOffers.push(viewDeviceOffer);

                }
            }
        }

        return viewDeviceOffers;
    }

    public bind (): void {
        this.resetRedPlusCards();

        this._deviceOffer = this.getDeviceOfferWithSubscription();
        this._stepper.bind();

        // @TODO Don't add repos to an view element!!
        this._deviceDetail.purchasableDeviceRepo = this.getReposSupervisor().getPurchasableDeviceRepo();
        this._deviceDetail.bind(this._deviceOffer);

        this._contractData.bind(this._customer);
        this._privatePricingBadge.bind(this._deviceOffer);

        this._subscriptionSelection.bind(this.getSubscriptions());

        if (undefined !== this._deviceOffer) {
            this.getInjector().getLoadingIndicator().hide();
        }

        this.render();
        this.events();

        this._pricebox.bind(this._deviceOffer);

        this._pricebox.setAnnotationPriceOnce('hide');

        if (undefined !== this._ctas) {
            this._ctas.bind(this._deviceOffer);
        }

        this.tracking();
    }
}
