/**
 * @module SalesFlow/evolved/router
 */

import {SalesChannelName, BusinessTransactionContext} from 'core/ids';
import {RouterEvolvedBntRouter} from 'router-evolved/bnt/router-evolved--bnt-router';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {ControllerEvolvedBntConnectMore} from 'controller-evolved/bnt/controller-evolved--bnt-connect-more';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

export class RouterEvolvedBntConnectMore extends RouterEvolvedBntRouter {

    /**
     * Overwrite method here because
     * Cross sales page should always get it's sales channel from storage
     * @TODO What should happen, when not sales channel is in storage
     */
    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName;

        const salesChannelFromStorage: string = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

        if (undefined !== salesChannelFromStorage) {

            if (
                Constants.SALESCHANNEL_CONSUMER === salesChannelFromStorage
                || Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage
                || Constants.SALESCHANNEL_SOHO === salesChannelFromStorage
            ) {
                salesChannel = salesChannelFromStorage;
            }

        }

        this._salesChannel = salesChannel;

    }

    /**
     * All three different vvl controller are needing the some data
     * Expect that X-Sell page does not need attributes.
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();
        const btx: BusinessTransactionContext = this.getInjector().getBtx();
        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);
        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.RedPlus_Data);

        this.getReposSupervisor().loadAttributeRepo();
        this.getReposSupervisor().loadDependencyRepo();
        this.getReposSupervisor().loadPurchasableAccessoryRepo(salesChannel, generalSalesObjectInterface);
    }

    /**
     * On x sell page the only incoming subscription is from storgae
     */
    public getIncomingSubscriptionIds (): number[] {

        // [Storage]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        return incomingSubscriptionIds.reverse();

    }

    public getIncomingDeviceIds (): number[] {

        // [Storage]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        return incomingDeviceIds.reverse();

    }

    public resolveDeviceId (incomingDeviceIds: number[]): number {

        const subscriptionId = this.resolveSubscriptionId(this.getSubscriptionIds());

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            subscriptionId
        );

        // are incomingAtomicDeviceIds purchasable with subscription
        const validatedDAtomicDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            const salesChannelFromStorage: string = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

            const checkAtomicDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            return (true === checkAtomicDeviceId);

        });

        if (0 === validatedDAtomicDeviceIds.length) {
            return undefined;
        }

        return validatedDAtomicDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getIncomingSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<ControllerEvolvedFlowBase> {
        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            deferred.resolve(
                new ControllerEvolvedBntConnectMore(
                    this.getSalesChannel(),
                    this._atomicDeviceId,
                    this.getSubscriptionId(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();
    }
}
