
/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import FoldUpAndDown from 'view/element/shared/fold-up-and-down';
import {Renderable} from 'view/renderable';

export class ViewEvolvedElementVvlContractData extends Renderable<Customer> {

    protected _element: JQuery;

    constructor (injector: Injector) {
        super(injector);

        this._element = $('.nsf-contract-data-recommendation');
    }

    public render (customer: Customer): void {

        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-contract-data'), this._injector);
        foldUpAndDown.bind();

        const introtextElement = $('#contract-data-introtext');
        const templateVars = {
            customer: customer,
            introtext: '',
            headline: ''
        };

        if (customer.isInlifeEligible) {
            templateVars.introtext = this.getInjector().getStrings().get('inlife.contractdata.introtext');
            if (customer.isGigaKombiCustomer) {
                templateVars.introtext = this.getInjector().getStrings().get('inlife.gigakombi.contractdata.introtext');
            }
            templateVars.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
        }

       else if (customer.isVVLEligible) {

        if (customer.isWinbackCustomer) {
            templateVars.introtext = this.getInjector().getStrings().get('vvl.winback.contractdata.introtext');
            templateVars.headline = this.getInjector().getStrings().get('vvl.winback.contractdata.headline');
            if (customer.isSohoCustomer) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.soho.winback.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl.soho.winback.contractdata.headline');
            }
        }

        else if (customer.isGigaKombiCustomer) {
            templateVars.introtext = this.getInjector().getStrings().get('vvl.gigakombi.contractdata.introtext');
            templateVars.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            if (customer.isSohoCustomer) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.soho.gigakombi.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl.soho-contractdata.headline');
            }
        }
        else {
            if ('STD' === customer.prolongationType) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.std.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            else if ('ART' === customer.prolongationType) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.art.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            if (customer.isYoungCustomer) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.young.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            else if (customer.isSohoCustomer) {
                templateVars.introtext = this.getInjector().getStrings().get('vvl.soho.contractdata.introtext');
                templateVars.headline = this.getInjector().getStrings().get('vvl.soho-contractdata.headline');
            }
         }
    }
        this._element.html(this.getInjector().getTemplates().render('contract-data-recommendation', templateVars));
        introtextElement.html(this.getInjector().getTemplates().render('contract-data-introtext', templateVars));
    }

    public events (): void {

    }

    public bind (customer: Customer): void {
        this.render(customer);
        this.events();
    }
}
