/**
 * @module SalesFlow/evolved/view
 * @todo: compare to gigakombi mode branch
 */
import subscription from 'model/type/subscription';

declare var $: JQueryStatic;

import Injector from 'core/injector';
import {Constants} from 'core/constants';

import Offer from 'view/view/shared/offer/offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import SlideMeIfYouCan from 'view/element/shared/slide-if-you-can';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';
import {SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel, TariffGroupName} from 'core/ids';
import {ViewEvolvedElementBaseClassSubscriptionDetail} from 'view-evolved/element/base-class/view-evolved--element-base-class--subscription-detail';
import {TariffGroupInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import {ViewEvolvedElementBntTariffGroupSwitcher} from 'view-evolved/element/bnt/view-evolved--element-bnt--tariffgroup-switcher';
import ViewEvolvedElementGigakombiTariffgroupSwitcher from 'view-evolved/element/gigakombi/view-evolved--element-gigakombi--tariffgroup-switcher';
import {ViewEvolvedElementSharedSubscriptionList} from 'view-evolved/element/shared/view-evolved--element-sharded--subscription-list';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

export abstract class EvolvedBaseClassSubscriptionSelection extends ViewEvolvedElementSharedSubscriptionList<SimOnlyOffer[]> {

    protected _currentPage: string;

    protected _element: JQuery;

    public _slider: SlideMeIfYouCan;

    protected _subscriptionTilesSelector: string = '.tariff-module-tile[data-subscription-id]';

    // @TODO this should not stored here
    private _activeSubscriptionId: number;

    // @TODO this should not stored here
    protected _offers: SimOnlyOffer[];

    private _subscriptionDetail: ViewEvolvedElementBaseClassSubscriptionDetail;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    private _focusSubscriptionIds: any;

    protected _subscriptionTiles: JQuery;

    /**
     *  _vfPassOverlay is overloaded by inherited class by
     *  - ViewEvolvedElementBntClassVfPassOverlay
     */
    protected _vfPassOverlay: any;

    protected _scrollToFocus: boolean;
    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    protected abstract updateStrikePrice (offer: Offer, tile: JQuery): void;

    protected abstract updateAsteriskText (offer: Offer, tile: JQuery): void;

    protected abstract renderPassArea (offer: Offer, tile: JQuery): void;

    /**
     * Binds Events to CTAs on subscription_overview
     */
    protected abstract bindEventsToCTA (offer: Offer, tile: JQuery): void;

    constructor (subscriptionId: number, injector: Injector, focusSubscriptionIds: SubscriptionIdPerSalesChannel, gigakombiDeviceDetailService: GigakombiDeviceDetailService) {

        super(injector);

        this._activeSubscriptionId = subscriptionId;
        this._element = $('#nsf-subscription-list-slide');
        this._gigakombiDeviceDetailService = gigakombiDeviceDetailService;
        this._subscriptionTiles = $(this._subscriptionTilesSelector);
        this._slider = new SlideMeIfYouCan(
            this._element,
            'tariff',
            injector
        );
        this._currentPage = this.getInjector().getRouting().getCurrentPage();
        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);
        this._focusSubscriptionIds = focusSubscriptionIds;
        this._subscriptionDetail = new ViewEvolvedElementBaseClassSubscriptionDetail(this._element, injector);
    }

    public getEvolvedSubscriptionIds (tariffGroup: TariffGroupName): number[] {

        const subscriptionIds: number[] = [];

        this._element.find('[data-tariff-group="' + tariffGroup  + '"]').each((index, tariffTile) => {

            const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);
            subscriptionIds.push(subscriptionId);

        });

        return subscriptionIds;
    }

    /**
     * this is (and should not be) used anymore!
     */
    public getSubscriptionIds (): number[] {

        const subscriptionIds: number[] = [];

        /*
        this._element.find(this._subscriptionTilesSelector).each((index, tariffTile) => {

            const tileDataGroupName = $(tariffTile).data('tariff-group');

            let tileSubscriptionGroupName: SubscriptionGroupName;

            if ('consumer' === tileDataGroupName || 'young' === tileDataGroupName || 'soho' === tileDataGroupName) {
                tileSubscriptionGroupName = tileDataGroupName;
            }

            if (this.getInjector().getFlow().getSubscriptionGroup() === tileSubscriptionGroupName) {
                const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);

                subscriptionIds.push(subscriptionId);

            } else {
                $(tariffTile).addClass('hide');
            }

        });
        */

        console.log ('getSubscriptionIds is outdated!');

        return subscriptionIds;

    }

    public setActiveSubscriptionId (subscriptionId: number) {
        this._activeSubscriptionId = subscriptionId;
    }

    public setTariffName (name: string) {
        $('#nsf-tariff-name').html(name);
    }

    /**
     * Update triggered after every tariff change and business transaction change
     * Builds the tile informaiton
     * @param offers
     */
    public update (offers: Offer[], scrollToFocus = true): void {

        this._offers = offers;
        this._element.find(this._subscriptionTilesSelector).parent().addClass('noOffer');

        const tariffgroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        for (const offer of offers) {

            const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"][data-tariff-group="' + tariffgroup + '"]');

            tile.parent().removeClass('noOffer');

            tile.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials'));

            this.updateStrikePrice(offer, tile);

            this.updateAsteriskText(offer, tile);

            this.renderPassArea(offer, tile);

            if ('subscription_overview' === this._currentPage) {

                /**
                 * Subscription Overview has CTAs "Ohne Smartphone bestellen" and "Ich will ein Smartphone dazu"
                 */
                this.bindEventsToCTA(offer, tile);

            }

            tile.find('.price-info.onetime').parent().remove();
            if (offer.isDevice()) {
                tile.find('.price-info.monthly').parent().before(this.getInjector().getTemplates().render('subscription_device_price', offer));
            }

            if (undefined === offer.subscription.pib) {
                tile.find('.productinformationVlux').remove();
            }
            else {
                tile.find('.productinformationVlux').attr('href', offer.subscription.pib);
            }

            /**
             * Update data volume for Gigakombi and going back on the bnt through the CTA-Zuruck on the green badge.
             * @todo: needed for gigakombi flow or only for mode? If latter, remove
             */
             this._gigakombiDeviceDetailService.updateDataVolumeOnTile(tile, offer);

        }

        if (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx()) {
            this._gigakombiDeviceDetailService.updateTariffTileWithGigaKombiPromoBadge(offers);
        }

        // Exclude the slider from hiding when it has the class .noOffer, because it has no height anyway without tiles in it and we need it to be "visible" for height calculation (CO-7304)
        $('.noOffer:not(.tariff-module-tiles)').hide();

        this._slider.updateHeight();

        if (false === scrollToFocus) {
            this._slider.setScrollToFocus(false);
        }

        this._slider.update();

        if (true === scrollToFocus) {
            this._slider.scrollToFocus();
        }

    }

    public render (offers: Offer[]): void {

        // Hide IN promotion teaser initially if we are not in BNT or we are in BNT and Spar-Tarife is not selected (see CO-7304)
        if (Constants.BTX_BNT !== this._injector.getBtx() || (Constants.BTX_BNT === this._injector.getBtx() && Constants.SUBSCRIPTION_GROUP_IN !== this._injector.getFlowState().getSubscriptionGroup())) {
            $('#vf-promotion-in-tariff').hide();
        }
        else {
            $('#vf-promotion-in-tariff').show();
        }

        const tariffgroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();
        const tariffTiles = this._element.find(this._subscriptionTilesSelector);

        tariffTiles.removeClass('selected');
        tariffTiles.show();

        this.update(offers);

        if (Constants.BTX_GIGAKOMBI !== this.getInjector().getBtx()) {
            // This loops through tariff tiles and creates the hardcoded promo badge for each tile : BNT
            tariffTiles.each((index, tariffTile) => {
                const discountText = $(tariffTile).data('discount-text');
                if (undefined !== discountText && undefined === $(tariffTile).find('.price-detail .discount').html()) {
                    this.addPromoBadgeToTariffTile(tariffTile, discountText, 'subscription_discount_text');
                }

            });
        }

        // Todo: check if still needed after gigakombi migration
        this._element.find(
            this._subscriptionTilesSelector + '[data-tariff-group!="' + tariffgroup + '"]'
        ).addClass('hide');

        // Bind slider again
        this._slider.bind(
            $('.tariff-module-tile[data-subscription-id="' + this._activeSubscriptionId + '"]')
        );

        /**
         * just initialize if active (displayed)
         * // Todo: check if still needed after gigakombi migration
         */
        if (0 < $('.mod-tariff-row-module .jspScrollable').length && this._element.find('.tg-head').hasClass('.active')) {

            this._slider.update();

        }

        /**
         * This condition takes care of showing the subscription the customer had selected on page reload
         */
        if (undefined !== this._activeSubscriptionId) {
            const gigakombiFlag = this.getInjector().getBtx() === Constants.BTX_GIGAKOMBI;
            this._element.find('[data-subscription-id="' + this._activeSubscriptionId + '"][data-tariff-group="' +  tariffgroup + '"][data-gigakombi="' + gigakombiFlag.toString() + '"]').addClass('selected');
        }

    }

    /**
     * Adds the promo badge to the tariff tiles
     *
     * @param tariffTile
     * @param discountText
     * @param templateName
     */
    protected addPromoBadgeToTariffTile (tariffTile: Element, discountText: string, templateName: string) {
        $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
            this.getInjector().getTemplates().render(templateName, discountText)
        );
    }

    protected bindSlider (): void {

        const tariffgroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        let focusSubscriptionId: number;

        if (undefined !== this._activeSubscriptionId) {

            focusSubscriptionId = this._activeSubscriptionId;

        }
        else if (undefined !== this._focusSubscriptionIds[this.getInjector().getFlow().getSalesChannel()]) {

            focusSubscriptionId = this._focusSubscriptionIds[this.getInjector().getFlow().getSalesChannel()];

        }

        this._slider.bind(
            $('.tariff-module-tile[data-subscription-id="' + focusSubscriptionId + '"][data-tariff-group="' + tariffgroup + '"]')
        );

    }

    public animateTileSwitch (animation: string, animationSpeeds: number, simOnlyOffers: SimOnlyOffer[]): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        const element = $('.tariff-module-tiles-wrapper');

        if ('fadeOut' === animation) {
            element.removeClass('blocked');
        }

        if ('fadeIn' === animation) {
            element.removeClass('hide');
            $('#tariff-loading-indicator').hide();
        }

        element.addClass('animated-' + animation).data('animating', 'animated-' + animation);

        element.data('animationTimeout', setTimeout((function () {
                element.removeClass(element.data('animating')).data('animating', null);
                deferred.resolve();
            }), animationSpeeds
        ));

        if ('setOpacity' === animation) {
            element.addClass('blocked');
            $('#tariff-loading-indicator').fadeIn(100).show();
        }

        return deferred.promise();
    }

    public events (): void {

        // $('#nsf-subscription-group-switcher').addClass('no-bg');

        if (true === this.getInjector().getFlow().isDevicesFirstFlow()) {
            $('#nsf-fold-up-and-down-tariff .tg-head').click();
        }

        this._element.on('click', '.selectionRadio', (evt: JQueryEventObject) => {

            const tile = $(evt.currentTarget).parents('.tariff-module-tile');

            if (true === tile.hasClass('selected')) {
                return;
            }

            this._element.find(this._subscriptionTilesSelector).removeClass('selected');

            tile.addClass('selected');

            this._slider.showSelectedElement();

            this.getInjector().getEvent().trigger('subscriptionId@changed', {
                subscriptionId: tile.data('subscription-id')
            });

            if (Constants.BTX_GIGAKOMBI !== this.getInjector().getBtx()) {
                this._element.find('.selectionHwOnly').removeClass('selected');
            }

            /**
             * Trigger the listener from vvl/device-overview-controller
             */
            this.getInjector().getEvent().trigger('notification-discounts@toggle');

        });

        $('#nsf-cost-overview-wrap').on('click', '.tariffLink', (evt) => {
            this._slider.scrollToSelectedElement();
        });

        this.getInjector().getEvent().listen('TariffGroupName@changed', (eventObject: JQueryEventObject, tariffGroupInterface: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupInterface.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupInterface.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;
            }

            if (Constants.BTX_BNT !== this._injector.getBtx() || (Constants.BTX_BNT === this._injector.getBtx() && Constants.SUBSCRIPTION_GROUP_IN !== subscriptionGroup)) {
                $('#vf-promotion-in-tariff').hide();
            }

            this.animateTileSwitch('setOpacity', 200, undefined);

            this._slider.hideButtons();

        });

        this._injector.getEvent().listen('TariffGroup@loaded', (eventObject: JQueryEventObject, tariffGroupInterface: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupInterface.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupInterface.subscriptionGroup;

            const tariffgroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this._element.find(this._subscriptionTilesSelector).each(function (index, tile) {

                if (tariffgroup === $(tile).data('tariff-group')) {

                    $(tile).removeClass('hide');

                }
                else {

                    $(tile).addClass('hide');

                }

            });

            // Showing of promo teaser has to happen in the loaded event because otherwise we would see tiles AND the teaser at the same time
            if (Constants.BTX_BNT === this._injector.getBtx() && Constants.SUBSCRIPTION_GROUP_IN === subscriptionGroup) {
                $('#vf-promotion-in-tariff').show();
            }
        });

        this._injector.getEvent().listen('currentTariff@selected', (eventObject: JQueryEventObject, data: any) => {

            this._slider.updateWidth();
            // this._slider.scrollToIndex(data.tileIndex);
            this._slider.scrollToSelectedElement();

        });

    }

    public bind (offers: Offer[]): void {

        this._offers = offers;

        this.render(offers);

        this._subscriptionDetail.bind(offers);

        this.events();

        // The subscription detail overlay is already binded in the SubscriptionDetail Class
        // this._subscriptionDetailOverlay.bind();

        this._vfPassOverlay.bind();

        this.bindSlider();

    }

    /**
     * Create subscription group switcher for Gigakombi or default BNT
     * @todo: needed? is not called
     */
    protected createSubscriptionGroupSwitcher () {
        if (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx()) {
            const gigakombiSubscriptionGroupSwitcher = new ViewEvolvedElementGigakombiTariffgroupSwitcher(this._injector);
            gigakombiSubscriptionGroupSwitcher.render();
        }
        else {
            const bntSubscriptionGroupSwitcher = new ViewEvolvedElementBntTariffGroupSwitcher(this._injector);
            bntSubscriptionGroupSwitcher.render();
        }
    }
}
