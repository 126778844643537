/**
 * @module SalesFlow/evolved/router
 */
import Injector from 'core/injector';

import {RouterEvolvedSharedBase} from 'router-evolved/shared/router-evolved--shared--base';
import {Constants} from 'core/constants';
import {SkeletonService} from '../../service/render/skeleton-service';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';

export abstract class RouterEvolvedVvlRouter extends RouterEvolvedSharedBase {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;
    }

    /**
     * In vvl sales channel could be consumer, young or soho.
     * Soho is implemented in soho routers and
     * here is only young and consumer implemented
     */
    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName;

        const defaultSalesChannel = this.getInjector().getOptions().get('default_sales_channel');

        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === defaultSalesChannel) {
            /**
             * [VGOF-5736]
             * In case, the default sales channel is "familyfriends",
             * the sales channel is always "familyfriends".
             * No need to get it from customer.
             */

            salesChannel = defaultSalesChannel;

        } else {

            const customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();

            const salesChannelFromCustomer: SalesChannelName = customer.getSubscriptionGroupName() as SalesChannelName;

            if (undefined !== salesChannelFromCustomer) {
                salesChannel = salesChannelFromCustomer;
            }

            // Refactor and fix frontend business logic regarding [INC-25488]
            const salesChannelFromStorage: SalesChannelName = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();
            const isYoungCustomer: boolean = customer.isYoungCustomer;

            if (Constants.SALESCHANNEL_CONSUMER === salesChannelFromStorage
                || Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage
            ) {
                if (Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage && !isYoungCustomer) {
                    // Storage salesChannel is young but customer is not young eligible so consumer is taken
                    salesChannel = Constants.SALESCHANNEL_CONSUMER;
                } else {
                    salesChannel = salesChannelFromStorage;
                }
            }

        }

        this._salesChannel = salesChannel;

    }

    /**
     * All three different vvl controller are needing the some data
     * Expect that X-Sell page does not need attributes.
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();
        const btx: BusinessTransactionContext = this.getInjector().getBtx();
        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);
        this.getReposSupervisor().loadAttributeRepo();
        this.getReposSupervisor().loadDependencyRepo();

    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        // ;) subscriptionGroup and salesChannel is the same in vvl
        // As soon as i got aligned with soho vvl i will tackle this
        const salesChannel = this.getSalesChannel();

        if (Constants.SALESCHANNEL_YOUNG === salesChannel || Constants.SALESCHANNEL_CONSUMER === salesChannel) {
            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionGroup(salesChannel);
        }

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

}
