/**
 * @module SalesFlow/core
 */

declare var $: JQueryStatic;

/**
 * A wrapper arond jQuery custom events
 * @TODO This should be rewritten with typed events the are only internal
 */
export default class Events {

    private _nsf: JQuery;

    constructor () {
        this._nsf = $('#nsf');
    }

    public listen (name: string, listener: (eventObject: JQueryEventObject, ...args: any[]) => any): void {

        $(this._nsf).on('nsf:' + name, (eventObject: JQueryEventObject, ...args: any[]) => {
            let data: any = {};

            if (0 !== args.length) {
                data = args[0];
            }
            listener(eventObject, data);
        });

    }

    public once (name: string, listener: (eventObject: JQueryEventObject, ...args: any[]) => any): void {

        $(this._nsf).one('nsf:' + name, (eventObject: JQueryEventObject, ...args: any[]) => {

            let data: any = {};

            if (0 !== args.length) {
                data = args[0];
            }
            listener(eventObject, data);
        });

    }

    public trigger (name: string, data = {}): void {

        this._nsf.trigger('nsf:' + name, data);

    }

}
