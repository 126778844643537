/**
 * @module SalesFlow/service
 */

import DeviceOffer from 'view/view/shared/offer/device-offer';
import Injector from 'core/injector';
import {Constants} from 'core/constants';
import Offer from 'view/view/shared/offer/offer';

/**
 * Class for adding gigakombi functionality to other flows (e.g. BNT)
 */
export default class GigakombiDeviceDetailService {
    /**
     * Injector
     */
    private _injector: Injector;

    constructor (injector: Injector) {
        this._injector = injector;
    }

    /**
     * Return the injector
     *
     * @returns Injector
     */
    private getInjector (): Injector {
        return this._injector;
    }

    /**
     * Update the tariff tiles with the Gigakombi promo badge
     *
     * Loops through the offers, removes any other discount badges and adds the Gigakombi discount badge
     *
     * @param offers
     */
    public updateTariffTileWithGigaKombiPromoBadge (offers: Offer[]) {
        for (const offer of offers) {
            const discountText = this.getInjector().getStrings().get('gigakombi.discount.text');
            const tariffTile = $('.tariff-module-tile[data-gigakombi="true"]');

            // Removing badges, they will be rendered below
            $(tariffTile).find('.price-detail .discount').remove();

            if (undefined !== discountText) {
                if ('BR5' !== this.getInjector().getGigakombi().getVorteil()) {
                    $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
                        this.getInjector().getTemplates().render('subscription_gigakombi_badge_text', discountText)
                    );
                    $(tariffTile).find('.MonthlyDiscountSum').text(offer.monthlyDiscountGigakombiSum);
                }
            }
        }
    }

    /**
     * Returns the strike price
     *
     * This method takes an offer and a strike price and returns the strike price if the offer is not a Gigakombi offer (in that case it returns the regular price)
     *
     * @param offer
     * @param strike_price
     * @returns number
     */
    public getStrikePrice (offer: Offer, strike_price: number) {

        if (0 < offer.offerTariffCosts.regularPrice) {
            return offer.offerTariffCosts.regularPrice;
        }

        return strike_price;
    }

    /**
     * Updates the data volume on a tariff tile
     *
     * This method takes a tile and an offer and updates the data volume on the tile to the Gigakombi data volume
     *
     * @param tile
     * @param offer
     */
    public updateDataVolumeOnTile (tile: JQuery, offer: Offer) {
        if (false === isNaN(offer.fullDataVolume)) {
            tile.find('.volumen-amount').html(offer.fullDataVolume + ' GB');
        }
    }

    /**
     * Show dynamic datavolume and discount in tariff detail overlay
     *
     * @param content
     * @param offer
     */
    public updateDataVolumeInOverlay (content: string, offer: Offer) {
        if (false === isNaN(offer.fullDataVolume)) {

            content = content.replace(/<span class="h3 volumen-amount">(\d+) GB<\/span>/, '<span class="h3 volumen-amount">' + offer.fullDataVolume.toString() + ' GB<\/span>');
            content = content.replace(/<span class="gigakombi-discount">(\d+)<\/span>/, '<span class="gigakombi-discount">' + offer.monthlyDiscountGigakombiSum.toString() + '<\/span>');
            content = content.replace(/<span class="gigakombi-volumen">(\d+)<\/span>/, '<span class="gigakombi-volumen">' + offer.extraDataVolume.toString() + '<\/span>');

        }

        return content;
    }
}
