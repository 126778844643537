import { BasketFactory, BasketFactoryOffer } from '@vfde-fe/general-sales-objects';
import { SalesChannelName, BusinessTransactionContext } from '../../core/ids';
import { Constants } from '../../core/constants';

import * as ŃsfOffer from 'view/view/shared/offer/offer';

import Offer from '@vfde-fe/general-sales-objects/dist/app/offer/model/type/offer';
import OfferServices from '@vfde-fe/general-sales-objects/dist/app/offer/model/type/offer-services';
import GeneralSalesObjectInterface from '../../service/general-sales-object/general-sales-object-interface';

/**
 * @module SalesFlow/shopbackend
 */

export default abstract class GeneralBasketServiceInterface {

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    /**
     * SalesChannelName in NSF Notation
     */
    protected _salesChannel: SalesChannelName;

    /**
     * Interface to BasketFactory
     */
    protected _basketFactory: BasketFactory;

    /**
     * Data coming from Frontend in NSF Notation
     */
    protected _NSF_Data: {

        mainOffer: ŃsfOffer.default;
        redPlusOffer: ŃsfOffer.default[];
        accessoryOffer: ŃsfOffer.default[];

    };

    /**
     * Data coming from General Offer Service (GSO). That's what
     * the basket object understands
     */
    protected _GSO_Data: {

        mainOffer: Offer;
        redPlusOffer: Offer[];
        accessoryOffer: Offer[];

    };

    constructor (salesChannel: SalesChannelName, forApp: boolean, forAppConnection: boolean) {

        this._salesChannel = salesChannel;

        this._basketFactory = new BasketFactory(
            Constants.SalesChannelGeneralOfferObjectMapping[salesChannel],
            forApp,
            forAppConnection);

    }

    /**
     * clears the internal data objects and initialize the basketFactory
     */
    protected init () {

        // Basket Factory understands the "not-translates" Sales-Channel from NSF
        this._basketFactory.salesChannel = this._salesChannel;

        this._NSF_Data = {
            mainOffer: undefined,
            redPlusOffer: [],
            accessoryOffer: []
        };

        this._GSO_Data = {
            mainOffer: undefined,
            redPlusOffer: [],
            accessoryOffer: []
        };

    }

    /**
     * Then generate the Main GSO Offers from NSF Offers (Including Red+)
     */
    protected generateMainOfferFromNSFData (): void {

        const btx: BusinessTransactionContext = this._NSF_Data.mainOffer.offer.btx;
        const salesChannel: SalesChannelName = this._NSF_Data.mainOffer.offer.salesChannel;

        // retrieve GeneralOfferService Offer from mainOffer
        this._GSO_Data.mainOffer = this._generalSalesObjectInterface.getRawOfferById(
            this._NSF_Data.mainOffer.offer.offerId,
            btx,
            salesChannel);

        // Add optional Selected Services (like insurance or VF Pass) to Offer
        for (const optionalService of this._NSF_Data.mainOffer.optionalServices) {
            this._GSO_Data.mainOffer.offerServices.addSelectedService(optionalService.id);
        }

        // Did the user select RedPlus?
        this._GSO_Data.redPlusOffer = [];
        for (const redPlusOffer of this._NSF_Data.redPlusOffer) {
            this._GSO_Data.redPlusOffer.push (
                this._generalSalesObjectInterface.redPlusOfferService.getOfferById(redPlusOffer.offer.offerId)
            );
        }

    }

    /**
     * Did the user select Accessory? Then generate the GSO Offers from NSF Offers
     */
    protected generateAccessorysOfferFromNSFData (): void {

        this._GSO_Data.accessoryOffer = [];
        for (const accessoryOffer of this._NSF_Data.accessoryOffer) {

            const salesChannel: SalesChannelName = accessoryOffer.offer.salesChannel;

            this._GSO_Data.accessoryOffer.push(
                this._generalSalesObjectInterface.getRawOfferById(
                    accessoryOffer.offer.offerId,
                    Constants.BTX_HARDWARE,
                    salesChannel)
            );

        }

    }

    /**
     * Send basket to bShop-Backend
     */
    protected sendBasketToShopBackend (): JQueryPromise<any> {

        const deferred = $.Deferred();

        this._basketFactory.sendBasketToShopBackend().then((response) => {
            deferred.resolve(response);
        }, (error) => {
            deferred.reject(error);
        });

        return deferred;

    }

    /**
     * emptys the basket (when it's not send to bShop Backend)
     */
    public clear (): void {
        this._basketFactory.clearBasket();
    }

    /**
     * Returns the number of items that are currently in the basket
     */
    public count (): JQueryPromise<number> {

        const deferred = $.Deferred();

        this._basketFactory.count().then((response: any) => {
            if (undefined !== response.numberOfPropositions) {
                return response.numberOfPropositions;
            }
        }, (error) => {
            deferred.reject(error);
        });

        return deferred.promise().then((response: any) => {
            if (undefined !== response.numberOfPropositions) {
                return response.numberOfPropositions;
            }
        }, (error) => {
            return 0;
        });
    }

}
