/**
 * @module SalesFlow/router
 */

import { InlifeRecommendationController } from 'controller-evolved/inlife/controller-evolved--inlife-recommendation';
import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';
import Customer from 'shopbackend/customer';
import {RouterEvolvedSharedBase} from 'router-evolved/shared/router-evolved--shared--base';
import {Constants} from 'core/constants';

export class RouterEvolvedInlifeRecommendation extends RouterEvolvedSharedBase {

    protected loadVluxDataIntoReposSupervisor (): void {
        this.getReposSupervisor().loadDependencyRepo();
    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        const salesChannel = this.getSalesChannel();

        if (Constants.SALESCHANNEL_CONSUMER === salesChannel) {
            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionGroup(salesChannel);
        }

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

    private getCheapestSubLevelSubscription (): VluxOffer {

        const allowedTariffIds = this.setCustomerAllowedTariffIds();
        const inlifeOffers = this.getInlifeOffersByAllowedTariffIds(allowedTariffIds);
        const orderedInlifeOffers = this.sortInlifeOffersByCheapestPrice(inlifeOffers);
        const cheapestSubLevelSubscription = orderedInlifeOffers[0];

        if (undefined !== cheapestSubLevelSubscription) {
            return cheapestSubLevelSubscription;
        }

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

    }

    /**
     * Let's fetch the allowed tariff IDs from the NBA call and save the ids in an array
     */
    private setCustomerAllowedTariffIds (): number[] {

        const customer: Customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();
        const customerAllowedTariffs = customer.allowedTariffs.allowedTariffDefinition;
        const customerAllowedTariffIds: number[] = [];

        /**
         * As per CO-3209
         * In case no allowed tariffs, main routing will block the user from entering the flow and redirect him to notification page with hotline
         * Check https://confluence.wf-de.vodafone.com/display/VFDEOPM/Recommendation+Inlife
         */
        if (0 < customerAllowedTariffs.length) {

            for (const allowedTariff of customerAllowedTariffs) {

                const mainSubscriptionGroup = this.getMainSubscriptionGroup(allowedTariff.subscriptionId);
                /**
                 * getMainSubscriptionGroup function handles the case where mainSubscriptionGroup is undefined
                 */
                if (undefined !== mainSubscriptionGroup) {
                    customerAllowedTariffIds.push(allowedTariff.subscriptionId);
                }

            }
        }

        return customerAllowedTariffIds;
    }

    /**
     *
     * @param customerAllowedTariffIds
     * Get the tariff change offers based on the allowed tariff IDs we got from the NBA call
     */

    private getInlifeOffersByAllowedTariffIds (customerAllowedTariffIds: number[]): any {

        const inlifeOffers = [];
        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        for (const customerAllowedTariffId of customerAllowedTariffIds) {

            const inlifeOffer = generalSalesObjectInterface.getSimOnlyOfferByAtomicSubscriptionId(
                customerAllowedTariffId,
                Constants.BTX_INLIFE,
                this.getSalesChannel());
            inlifeOffers.push(inlifeOffer);

        }

        return inlifeOffers;
    }

    /**
     *
     * @param inlifeOffers
     * Let's order the offers from cheapest to most expensive
     */

    private sortInlifeOffersByCheapestPrice (inlifeOffers: any): any {

        inlifeOffers.sort(function (a: any, b: any) {
            return a.subcriptionPriceMonthly.value - b.subcriptionPriceMonthly.value;
        });

        return inlifeOffers;

    }

    /**
     * @param subLevelSubscriptionId
     * Small little function to help us get the main subscription group
     */
    private getMainSubscriptionGroup (subLevelSubscriptionId: number): Subscription {

        const mainSubscriptionGroup = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subLevelSubscriptionId);

        if (undefined !== mainSubscriptionGroup) {
            return mainSubscriptionGroup;
        } else {
            /**
             * As per CO-3209, in case no matching between allowed tariffs and VLUX offers we redirect the user to notification page with hotline
             * Check https://confluence.wf-de.vodafone.com/display/VFDEOPM/Recommendation+Inlife
             */
            const redirectUrl = this.getInjector().getOptions().get('consumer_black_and_platin_path');
            window.location.href = redirectUrl;
        }
    }

    public createController (): JQueryPromise<InlifeRecommendationController> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            const cheapestSubLevelSubscription = this.getCheapestSubLevelSubscription();

            const mainSubscriptionGroup = this.getMainSubscriptionGroup(cheapestSubLevelSubscription.subscriptionId);

            const subLevelAmount = this.getReposSupervisor().getSubscriptionRepo().getSubLevelBySubsidizationId(cheapestSubLevelSubscription.subscriptionId);

            deferred.resolve(
                new InlifeRecommendationController(
                    this.getSalesChannel(),
                    mainSubscriptionGroup,
                    cheapestSubLevelSubscription,
                    subLevelAmount,
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        });

        return deferred.promise();

    }

}
