/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';
import {Renderable} from 'view/renderable';

export class ViewEvolvedElementVvlAdditionalCard extends Renderable<any> {

    private _element: JQuery;
    private _accordionToggle: JQuery;

    constructor (injector: Injector, type: string) {
        super(injector);
        this._injector = injector;
        this._element = $('.additional-card-tile[data-type="' + type + '"]');
        this._accordionToggle = this._element.find('.additional-cards-accordion');
    }

    public events (): void {

        this._element.on('click', '.btn-count', (evt: JQueryEventObject) => {

            if (true === this._element.find('.btn-count[data-count=' + evt.currentTarget.getAttribute('data-count') + ']').hasClass('selected')) {
                this._element.data('value', 0);
                this._element.find('.btn-count[data-count=' + evt.currentTarget.getAttribute('data-count') + ']').removeClass('selected');
                this._element.find('.add-cards-header').removeClass('active');
                this._injector.getEvent().trigger('redPlusCardCount@changed');
            } else {

                if (false === this._element.find('.btn-count[data-count=' + evt.currentTarget.getAttribute('data-count') + ']').hasClass('selected')
                    && false === this._element.find('.btn-count[data-count=' + evt.currentTarget.getAttribute('data-count') + ']').hasClass('disabled')) {

                    const that = this;
                    this._element.find('.btn-count').each(function () {

                        $(this).removeClass('selected');
                        if (parseInt($(this).data('count'), 10) === parseInt(evt.currentTarget.getAttribute('data-count'), 10)) {
                            that._element.data('value', evt.currentTarget.getAttribute('data-count'));
                            $(this).addClass('selected');
                            that._element.find('.add-cards-header').addClass('active');
                        }
                    });

                    that._injector.getEvent().trigger('redPlusCardCount@changed');

                }
            }
        });

        this._element.on('click', '.x-sell-accordion-foot', (evt: JQueryEventObject) => {
            const currentElement: JQuery = $(evt.currentTarget);

            currentElement
                .find('.more, .less')
                .toggleClass('is-hidden')
                .parents('.additional-cards-accordion')
                .find('.x-sell-list-container')
                .toggleClass('x-sell-accordion-is-open');

        });
    }

    public render (): void {

    }

    public bind (): void {
        this.render();
        this.events();

    }
}
