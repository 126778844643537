/**
 * @module SalesFlow/model/type
 */
import OfferServiceGroup from './offer-service-group';
import OfferService from './offer-service';
import OfferComponent from './offer-component';
import OfferPrice from './offer-price';

import {VluxJsonOffer} from '../vlux-interface';
import { Constants } from '../../core/constants';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {ModelEvolvedVluxMapStringsBetweenSalesAndVlux} from 'model-evolved/vlux/model-evolved--vlux--map-strings-between-sales-and-vlux';

/**
 * @TODO If this can be (de-)serialized, on productDetailPage less repo calls are needed
 */
export default class Offer {

    private readonly _btx: BusinessTransactionContext;
    private _salesChannel: SalesChannelName;

    private _id: string;
    private _sourceId: number;
    private _sourceLabel: string;
    private _offertype: string;
    /* Auspraegungen in Anlehnung an KIAS: HmK = Tarif und Handy
                                                                          HoK = HardwareOnly
                                                                          SimOnly */

    private _subscriptionId: number;
    private _deviceId: number;
    private _simcardId: number;

    private _components: OfferComponent[] = [];

    /**
     * @TODO Prices have an ugly interface and don't include goodies
     */
    private _subcriptionPrice: OfferPrice[];
    private _devicePrice: OfferPrice[];
    private _simcardPrice: OfferPrice[];

    /**
     * @TODO services contains only the raw data. Implementation needed
     */
    private _servicesGroups: OfferServiceGroup[] = [];
    private _contextAttribute: any = [];

    private _markAsLowestOffer: boolean = false;

    private _unlimitedSocApplied: boolean = false;

    private _isGigakombi: boolean = false;

    constructor (btx: BusinessTransactionContext, data: VluxJsonOffer) {

        this._btx = btx;

        if (Constants.BTX_GIGAKOMBI === btx) {
            this._isGigakombi = true;
        }

        this._id = data.id;
        this._sourceId = parseFloat(data.sourceId);
        this._sourceLabel = data.sourceLabel;

        /**
         * eSIM Workaround by Mercury
         */
        for (const component of data.components.component) {
            if ('multisim' !== this.btx && '3359' === component.refId) {
                // skip Component...
                console.log ('skip component');
            } else {
                this._components.push(new OfferComponent(component));
            }

        }

        /**
         * components are refferd by refId
         * services as id
         *
         * not every offer has a service group
         */
        try {
            this._servicesGroups = data.services.serviceGroup.map((serviceGroup: any) => {
                return new OfferServiceGroup(serviceGroup);
            });
        } catch (e) {
        }

        this._contextAttribute = data.contextAttributes.contextAttribute;

        for (const attribute of this._contextAttribute) {

            if ('btx' === attribute.type) {
                this._btx = attribute.value;
                continue;
            }

            if ('salesChannel' === attribute.type) {
                this._salesChannel = attribute.value;
                continue;
            }
        }
        const mapSalesStringsToVluxString = new ModelEvolvedVluxMapStringsBetweenSalesAndVlux();
        this._btx = mapSalesStringsToVluxString.vluxToSalesBtx(this._btx);
        this._salesChannel = mapSalesStringsToVluxString.vluxToSalesSalesChannel(this._salesChannel);

        for (const component of this._components) {
            if ('tariffMobile' === component.type) {
                this._subscriptionId = component.refId;

                this._subcriptionPrice = component.getPrices();

            }
            if ('hardwareMobile' === component.type || 'accessoryMobile' === component.type) {
                this._deviceId = component.refId;

                this._devicePrice = component.getPrices();

            }

            if ('simMobile' === component.type) {
                this._simcardId = component.refId;

                this._simcardPrice = component.getPrices();

            }
        }

        if (0 < this._subscriptionId && 0 < this._deviceId) {
            this._offertype = 'HmK';
        } else if (0 < this._subscriptionId) {
            this._offertype = 'SimOnly';
        } else if (0 < this._deviceId) {
            this._offertype = 'HoK';
        }

    }

    public getServices (): OfferServiceGroup[] {
        return this._servicesGroups;
    }

    public getOptionalServices (): OfferServiceGroup[] {
        let optionals: OfferServiceGroup[] = [];

        optionals = this._servicesGroups.filter((serviceGroup: OfferServiceGroup) => {
            return ('service' === serviceGroup.type && 'optional' === serviceGroup.selectable);
        });

        if (0 === optionals.length) {
            return [];
        }

        return optionals;
    }

    public getIncludedServices (): OfferServiceGroup[] {
        let includes: OfferServiceGroup[] = [];

        includes = this._servicesGroups.filter((serviceGroup: OfferServiceGroup) => {
            return ('service' === serviceGroup.type && 'included' === serviceGroup.selectable);
        });

        if (0 === includes.length) {
            return [];
        }

        return includes;
    }

    public getOptionalDiscounts (): OfferServiceGroup[] {
        let optionals: OfferServiceGroup[] = [];

        optionals = this._servicesGroups.filter((serviceGroup: OfferServiceGroup) => {
            return ('discount' === serviceGroup.type && 'optional' === serviceGroup.selectable);
        });

        if (0 === optionals.length) {
            return [];
        }

        return optionals;
    }

    /**
     * special treatment of Device Insurance as this comes in optionalServices
     * but has price of 0 in case of tariff Xl and XXL
     */
    public hasHandyInsuranceIncluded (): boolean {

        for (const serviceGroup of this.getOptionalServices()) {

            for (const service of serviceGroup.services) {

                if ((158 === service.id || 159 === service.id) && 0 === service.monthlyPrice.value) {
                    return true;
                }

            }

        }

        return false;
    }

    public getHandyInsuranceIncludedLabel (): string {

        for (const serviceGroup of this.getOptionalServices()) {

            for (const service of serviceGroup.services) {

                if ((158 === service.id || 159 === service.id) && 0 === service.monthlyPrice.value) {
                    return service.label;
                }

            }

        }

        return '';
    }

    public getHandyInsuranceIncluded (): OfferService {

        for (const serviceGroup of this.getOptionalServices()) {

            for (const service of serviceGroup.services) {

                if ((158 === service.id || 159 === service.id) && 0 === service.monthlyPrice.value) {
                    return service;
                }

            }

        }

        return undefined;
    }

    /* currently not needed
    public getIncludedDiscountsFilteredForGigakombi (discounts: OfferServiceGroup[]): OfferServiceGroup[] {

        if (this.subscriptionId === Constants.RedS_Id || this.subscriptionId === Constants.YoungM_Id || this.subscriptionId === Constants.RedXL_Id) {
            return discounts;
        }

        if (0 === discounts.length) {
            return discounts;
        }

        const discount = discounts[0];

        let services = discount.services;

        services = services.filter((service: OfferService) => {

            if (Constants.Data50Percent7Gb === service.id) {
                return false;
            }

            return true;
        });

        discount.overwriteServices(services);

        return [discount];

    }
    */

    public getIncludedDiscounts (): OfferServiceGroup[] {

        let discounts: OfferServiceGroup[] = [];

        discounts = this._servicesGroups.filter((serviceGroup: OfferServiceGroup) => {
            return ('discount' === serviceGroup.type && 'included' === serviceGroup.selectable);
        });

        if (0 === discounts.length) {
            return [];
        }

        /*
        if (true === this._isGigakombi) {
            discounts = this.getIncludedDiscountsFilteredForGigakombi(discounts);
        }
        */

        return discounts;
    }

    public getHandyInsuranceServiceId (): number {
        for (const serviceGroup of this.getOptionalServices()) {
            for (const service of serviceGroup.services) {
                if (158 === service.id || 159 === service.id) {
                    return service.id;
                }
            }
        }

        return undefined;
    }

    public getHandyInsurancePrice (): number {
        for (const serviceGroup of this.getOptionalServices()) {
            for (const service of serviceGroup.services) {
                if (158 === service.id || 159 === service.id) {
                    return service.monthlyPrice.value;
                }
            }
        }

        return undefined;
    }

    get btx (): BusinessTransactionContext {
        return this._btx;
    }

    get salesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    /**
     * Caution This is not the subscriptionId, it is the sublevel id. Vlux ;)
     */
    get subscriptionId (): number {
        return this._subscriptionId;
    }

    get deviceId (): number {
        return this._deviceId;
    }

    get simId (): number {
        return this._simcardId;
    }

    get offerId (): string {
        return this._id;
    }

    get subcriptionPrice (): OfferPrice[] {
        return this._subcriptionPrice;
    }

    get subcriptionPriceOnetime (): OfferPrice {
        if (this._subcriptionPrice) {
            for (const price of this._subcriptionPrice) {
                if ('onetime' === price.recurrenceUnit) {
                    return price;
                }
            }
        }

        return undefined;
    }

    get subcriptionPriceMonthly (): OfferPrice {
        if (this._subcriptionPrice) {
            for (const price of this._subcriptionPrice) {
                if ('month' === price.recurrenceUnit) {
                    return price;
                }
            }
        }

        return undefined;
    }

    get devicePrice (): OfferPrice[] {
        return this._devicePrice;
    }

    get simPrice (): OfferPrice[] {
        return this._simcardPrice;
    }

    get simPriceOnetime (): OfferPrice {
        if (this._simcardPrice) {
            for (const price of this._simcardPrice) {
                if ('onetime' === price.recurrenceUnit) {
                    return price;
                }
            }
        }

        return undefined;
    }

    get simPriceMonthly (): OfferPrice {
        if (this._simcardPrice) {
            for (const price of this._simcardPrice) {
                if ('month' === price.recurrenceUnit) {
                    return price;
                }
            }
        }

        return undefined;
    }

    get devicePriceOnetime (): OfferPrice {
        if (this._devicePrice) {
            for (const price of this._devicePrice) {
                if ('onetime' === price.recurrenceUnit) {
                    return price;
                }
            }
        }

        return undefined;
    }

    get offerType (): string {
        return this._offertype;
    }

    public markAsLowestOffer (isLowest = true) {
        this._markAsLowestOffer = isLowest;
    }

    get isMarkedAsLowestOffer (): boolean {
        return this._markAsLowestOffer;
    }

    private getUnlimitedChargingSocPrice (): number {

        for (const servicesGroup of this._servicesGroups) {
            for (const service of servicesGroup.services) {
                if (service.id === Constants.RedUnlimitedCharging_Id) {
                    return service.monthlyPrice.value;
                }
            }
        }

        return 0;
    }

    /**
     * Redplus for unlimited should use a charging soc.
     * this will be added to basket, here it is added to reflect pricebox and costoverview
     */
    public addUnlimitedCharingSoc (): void {

        if (true === this._unlimitedSocApplied) {
            return;
        }

        this._unlimitedSocApplied = true;

        for (const price of this._subcriptionPrice) {

            if ('month' === price.recurrenceUnit) {
                price.addToValue(this.getUnlimitedChargingSocPrice());
            }
        }

    }

    public removeUnlimitedCharingSoc (): void {

        if (false === this._unlimitedSocApplied) {
            return;
        }

        this._unlimitedSocApplied = false;

        for (const price of this._subcriptionPrice) {

            if ('month' === price.recurrenceUnit) {
                price.addToValue(-1 * this.getUnlimitedChargingSocPrice());
            }
        }

    }

    /**
     * Get active gigakombi discounts in offer
     */
    public getGigakombiDiscountIDs (): number[] {

        if (false === this._isGigakombi) {
            return [];
        }

        const gigakombiDiscountIDs: number[] = [];

        const discounts = this._servicesGroups.filter((serviceGroup: OfferServiceGroup) => {
            return ('discount' === serviceGroup.type && 'included' === serviceGroup.selectable);
        });

        if (undefined === discounts || 0 === discounts.length) {
            return gigakombiDiscountIDs;
        }

        const discountIds: number[] = discounts[0].services.map((service: OfferService): number => {
            return service.id;
        });

        for (const discountId of discountIds) {
            if ( -1 !== Constants.GigakombiDiscountIDs.lastIndexOf(discountId)) {
                gigakombiDiscountIDs.push(discountId);
            }
        }

        return gigakombiDiscountIDs;

    }

    /**
     * This applies the relevant gigakombi discount service to every offer
     * @param gigakombiIds
     * deprecated once all the gigakombi stuff has moved to evolved
     */
    public applyGigakombi (gigakombiIds: number[]): void {

        // TODO: This line of setting isGigakombi needs to be set for our case
        this._isGigakombi = true;

        const offerServices: OfferService[] =  [];

        for (const servicesGroup of this._servicesGroups) {
            if ('GigaKombi Workaround' === servicesGroup.label) {

                for (const service of servicesGroup.services) {

                    if (-1 !== gigakombiIds.indexOf(service.id)) {
                        offerServices.push(service);
                    }
                }

                break;
            }
        }

        if (0 === offerServices.length) {
            return undefined;
        }

        // @TODO Dependencies form vlux

        let foundDiscountGroup = false;
        for (const servicesGroup of this._servicesGroups) {
            if ('Rabatte' === servicesGroup.label) {

                servicesGroup.addService(offerServices);
                foundDiscountGroup = true;
                break;

            }
        }

        // If no discount group found check for Gigakombi workaround label and add discount services to the serviceGroups
        if (false === foundDiscountGroup) {
            for (const servicesGroup of this._servicesGroups) {
                if ('GigaKombi Workaround' === servicesGroup.label) {

                    servicesGroup.addServicesFromGigakombiIds(gigakombiIds);

                }
            }

        }

    }

    get hasUnlimitedChargingSoc (): boolean {
        return this._unlimitedSocApplied;
    }

}
