
(function() {
    vf.dropdowncheckbox = {
        settings: {
            target: '.mod-dropdowncheckbox',

        },

        init: function(context) {
            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);
                //console.log("foo");

                var pane = $this.find('.scroll-pane');
                pane.jScrollPane();

                pane.css('display', 'none');

                var api = pane.data('jsp');

                $this.find('.baseBox').bind(
                    'click',
                    function()
                    {
                        $(this).parent().toggleClass('active');
                    }
                );

            });
        },
    };
}(vf));
