(function() {
    'use strict';
    vf['cm-accordion'] = {
        settings: {
            target: '.mod-cm-accordion',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {

                $(this).find('.checkBox').click(function() {
                    var myParent = $(this).parent().parent();
                    if (myParent.hasClass('active')) {
                        myParent.find('.stateOne').animate({
                            height: 'toggle'
                        }, 500, function() {
                            $(this).hide();
                        });
                        myParent.removeClass('active');
                        $(this).removeClass('selected');
                    } else {
                        myParent.find('.stateOne').animate({
                            height: 'toggle'
                        }, 500, function() {
                            $(this).show();
                        });
                        myParent.addClass('active');
                        $(this).addClass('selected');
                    }
                    if (myParent.find('.stateOne').hasClass('active')) {
                        myParent.find('.stateTwo').animate({
                            height: 'toggle'
                        }, 500, function() {
                            $(this).hide();
                        });
                        myParent.find('.stateOne').removeClass('active');
                    }
                });

                $('.stateOneHead').click(function() {
                    var myParent = $(this).parent();
                    if (myParent.hasClass('active')) {
                        myParent.find('.stateTwo').animate({
                            height: 'toggle'
                        }, 500, function() {
                            $(this).hide();
                        });
                        myParent.removeClass('active');
                    } else {
                        myParent.find('.stateTwo').animate({
                            height: 'toggle'
                        }, 500, function() {
                            $(this).show();
                        });
                        myParent.addClass('active');
                    }
                });
            });
        },
    };
}(vf));
