import { VOUCHER_API_URL } from '../../constants/api-config';

export function generatorVoucherCode (msisdn: string): JQueryPromise<any> {
    const _msisdn = msisdn.replace('+49 ', '0');

    const formData = new FormData();
    formData.append('mi', _msisdn);
    const deferred = $.Deferred<any>();

    $.ajax({
        url: VOUCHER_API_URL,
        data: formData,
        type: 'POST',
        processData: false,
        contentType: false
    }).then(function (success) {
        deferred.resolve(success);
    }, function (error) {
        console.log('Ajax Error: ', error);
            const customError = {
                result: 'error',
                reason: 'defaultError'
            };
        deferred.reject(customError);
    });

    return deferred.promise();
}
