/**
 * @module SalesFlow/evolved/view
 */

import Injector from 'core/injector';

import {SubscriptionList} from 'view/element/shared/subscription-list';

import Offer from 'view/view/shared/offer/offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import SlideMeIfYouCan from 'view/element/shared/slide-if-you-can';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';

import {SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {ViewEvolvedElementVvlVfPassOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--vf-pass-overlay';
import {ViewEvolvedElementVvlSubscriptionDetail} from 'view-evolved/element/vvl/view-evolved--element-vvl--subscription-detail';
import {Constants} from 'core/constants';

declare var $: JQueryStatic;

export class EvolvedSubscriptionSelectionDeviceOverviewVvl extends SubscriptionList<SimOnlyOffer[]> {

    private _element: JQuery;

    public _slider: SlideMeIfYouCan;

    private _subscriptionTilesSelector: string = '.tariff-module-tile[data-subscription-id]';

    // @TODO this should not stored here
    private _activeSubscriptionId: number;

    // @TODO this should not stored here
    private _offers: SimOnlyOffer[];

    private _subscriptionDetail: ViewEvolvedElementVvlSubscriptionDetail;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    private _focusSubscriptionIds: any;

    private _vfPassOverlay: ViewEvolvedElementVvlVfPassOverlay;

    protected _scrollToFocus: boolean;

    private salesChannel: SalesChannelName;

    constructor (subscriptionId: number, injector: Injector, focusSubscriptionIds: SubscriptionIdPerSalesChannel) {

        super(injector);

        this._activeSubscriptionId = subscriptionId;

        this._element = $('#nsf-subscription-list-slide');

        this._slider = new SlideMeIfYouCan(
            this._element,
            'tariff',
            injector
        );

        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);

        this._focusSubscriptionIds = focusSubscriptionIds;

        this._subscriptionDetail = new ViewEvolvedElementVvlSubscriptionDetail(this._element, injector);

        this._vfPassOverlay = new ViewEvolvedElementVvlVfPassOverlay(injector);

        this.salesChannel = this.getInjector().getOptions().get('default_sales_channel');

    }

    /*
     * @TODO Implement subscriptionGroupName
     *
     */
    public getEvolvedSubscriptionIds (salesChannel: SalesChannelName, subscriptionGroupName: SubscriptionGroupName): number[] {

        const subscriptionIds: number[] = [];

        this._element.find('[data-tariff-group="' + salesChannel + '"]').each((index, tariffTile) => {

            const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);
            subscriptionIds.push(subscriptionId);

        });

        return subscriptionIds;
    }

    public getSubscriptionIds (): number[] {

        const subscriptionIds: number[] = [];

        this._element.find(this._subscriptionTilesSelector).each((index, tariffTile) => {

            const tileDataGroupName = $(tariffTile).data('tariff-group');

            let tileSubscriptionGroupName: SubscriptionGroupName;

            if ('consumer' === tileDataGroupName || 'young' === tileDataGroupName || 'soho' === tileDataGroupName || 'familyfriends' === tileDataGroupName ) {
                tileSubscriptionGroupName = tileDataGroupName;
            }

            if (this.getInjector().getFlow().getSubscriptionGroup() === tileSubscriptionGroupName) {
                const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);

                subscriptionIds.push(subscriptionId);

            } else {
                $(tariffTile).addClass('hide');
            }

        });

        return subscriptionIds;

    }

    public setActiveSubscriptionId (subscriptionId: number) {
        this._activeSubscriptionId = subscriptionId;
    }

    public setTariffName (name: string) {
        $('#nsf-tariff-name').html(name);
    }

    public update (offers: Offer[], scrollToFocus = true): void {

        this._offers = offers;
        this._element.find(this._subscriptionTilesSelector).parent().addClass('noOffer');
        for (const offer of offers) {

            const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"]');

            tile.parent().removeClass('noOffer');

            tile.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials'));

            if (offer.monthlyRegularPrice !== offer.monthlyDiscountPrice) {

                tile.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyRegularPrice, 'partials'));

            }
            else {

                tile.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat').addClass('no-strike');

                // Workaround to remove asterisk if tariff has no strike price but asterisk is data-attribute to runtime and no price [CO-14319]
                tile.find('.asterisk-text').addClass('with-sub');
                tile.find('.asterisk').remove();
            }

            if (offer.offerTariffCosts.discountPrices.length) {

                if (undefined !== offer.offerTariffCosts) {
                    if (0 < offer.offerTariffCosts.discountPrices.length) {

                        let undiscountedPrice: string = '' + (Math.round(offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount] * 100) / 100).toFixed(2);
                        undiscountedPrice = undiscountedPrice.replace('.', ',');

                        tile.find('.asterisk-text').html(
                            'Ab dem ' + (offer.offerTariffCosts.discountPrices[0].runtime) +
                            '. Monat ' + this.getInjector().getTemplates().render('price-element', undiscountedPrice, 'partials') + '<br> 24 Monate Mindestlaufzeit');
                    }
                }
            }

            tile.find('.price-info.onetime').parent().remove();
            if (offer.isDevice()) {
                tile.find('.price-info.monthly').parent().before(this.getInjector().getTemplates().render('subscription_device_price', offer));
            }

            if (undefined === offer.subscription.pib) {
                tile.find('.productinformationVlux').remove();
            }
            else {
                tile.find('.productinformationVlux').attr('href', offer.subscription.pib);
            }

        }

        $('.noOffer').hide();

        this._slider.updateHeight();

        if (false === scrollToFocus) {
            this._slider.setScrollToFocus(false);
        }

        this._slider.update();

        if (true === scrollToFocus) {
            this._slider.scrollToFocus();
        }

    }

    public render (offers: Offer[]): void {

        this._element.find(this._subscriptionTilesSelector).removeClass('selected');
        this._element.find(this._subscriptionTilesSelector).show();

        this.update(offers);

        this._element.find(this._subscriptionTilesSelector).each((index, tariffTile) => {
            const discountText = $(tariffTile).data('discount-text');
            if (undefined !== discountText && undefined === $(tariffTile).find('.price-detail .discount').html()) {
                $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
                    this.getInjector().getTemplates().render('subscription_discount_text', discountText)
                );
            }

            if ('true' === $(tariffTile).attr('data-old-portfolio')) {
                $(tariffTile).addClass('hide');
            }

        });

        this._element.find(
            this._subscriptionTilesSelector + '[data-tariff-group!="' + this.getInjector().getFlowStateWithSalesChannel().getSalesChannel() + '"]'
        ).addClass('hide');

        /**
         * just initialize if active (displayed)
         */
        if (0 < $('.mod-tariff-row-module .jspScrollable').length && this._element.find('.tg-head').hasClass('.active')) {

            this._slider.update();

        }

        /**
         * This condition takes care of showing the subscription the customer had selected on page reload
         */
        if (undefined !== this._activeSubscriptionId) {
            // And only in the tariffgroup!
            this._element.find('[data-subscription-id="' + this._activeSubscriptionId + '"][data-tariff-group="' + this.getInjector().getFlowStateWithSalesChannel().getSalesChannel() + '"]').addClass('selected').removeClass('hide');

        }

    }

    private bindSlider (): void {

        let focusSubscriptionId: number;

        if (undefined !== this._activeSubscriptionId) {

            focusSubscriptionId = this._activeSubscriptionId;

        }
        else if (undefined !== this._focusSubscriptionIds[this.getInjector().getFlow().getSubscriptionGroup()]) {

            focusSubscriptionId = this._focusSubscriptionIds[this.getInjector().getFlow().getSubscriptionGroup()];

        }

        this._slider.bind(
            $('.tariff-module-tile[data-subscription-id="' + focusSubscriptionId + '"]')
        );

    }

    public animateTileSwitch (animation: string, animationSpeeds: number, simOnlyOffers: SimOnlyOffer[]): JQueryPromise<any> {
        const deferred = $.Deferred<any>();
        const element = $('.tariff-module-tiles-wrapper');

        if ('fadeOut' === animation) {
            element.removeClass('blocked');
        }

        if ('fadeIn' === animation) {
            element.removeClass('hide');
            $('#tariff-loading-indicator').hide();
        }

        element.addClass('animated-' + animation).data('animating', 'animated-' + animation);

        element.data('animationTimeout', setTimeout((function () {
                element.removeClass(element.data('animating')).data('animating', null);
                deferred.resolve();
            }), animationSpeeds
        ));

        if ('setOpacity' === animation) {
            element.addClass('blocked');
            $('#tariff-loading-indicator').fadeIn(100).show();
        }

        return deferred.promise();
    }

    public events (): void {

        $('#nsf-subscription-group-switcher').addClass('no-bg');

        if (true === this.getInjector().getFlow().isDevicesFirstFlow()) {
            $('#nsf-fold-up-and-down-tariff .tg-head').click();
        }

        this._element.on('click', '.selectionRadio', (evt: JQueryEventObject) => {

            const tile = $(evt.currentTarget).parents('.tariff-module-tile');

            if (true === tile.hasClass('selected')) {
                return;
            }

            this._element.find(this._subscriptionTilesSelector).removeClass('selected');

            tile.addClass('selected');

            this._slider.showSelectedElement();

            this.getInjector().getEvent().trigger('subscriptionId@changed', {
                subscriptionId: tile.data('subscription-id')
            });

            /**
             * Trigger the listener from vvl/device-overview-controller
             */
            this.getInjector().getEvent().trigger('notification-discounts@toggle');

        });

        this._element.on('click', '.vll-accordion-toggle', (evt) => {
            setTimeout(() => {
                this._slider.updateHeight();
            }, 850);
        });

        $('#nsf-cost-overview-wrap').on('click', '.tariffLink', (evt) => {
            this._slider.scrollToSelectedElement();
        });

        this._injector.getEvent().listen('SalesChannelName@changed', (eventObject: JQueryEventObject, salesChannel: SalesChannelName) => {

            if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

                return undefined;

            }

            this.animateTileSwitch('setOpacity', 200, undefined);

            this._slider.hideButtons();

        });

        this._injector.getEvent().listen('SalesChannel@loaded', (eventObject: JQueryEventObject, salesChannel: SalesChannelName) => {

            if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

                return undefined;

            }

            this._element.find(this._subscriptionTilesSelector).each(function (index, tile) {

                if (salesChannel === $(tile).data('tariff-group')) {

                    $(tile).removeClass('hide');

                }
                else {

                    $(tile).addClass('hide');

                }

            });

            $.when(this.animateTileSwitch('fadeOut', 400, undefined)).done(() => {

                this.render(
                    this.getInjector().getOfferCollection().getSubscriptions()
                );

                this._slider.update();

                $.when(this.animateTileSwitch('fadeIn', 300, [])).done( () => {

                    this._slider.updateHeight();
                    this._slider.scrollToFocus();

                });

            });

        });

        this._injector.getEvent().listen('currentTariff@selected', (eventObject: JQueryEventObject, data: any) => {

            this._slider.updateWidth();
            // this._slider.scrollToIndex(data.tileIndex);
            this._slider.scrollToSelectedElement();

        });

    }

    public bind (offers: Offer[]): void {

        this._offers = offers;

        this.render(offers);

        this._subscriptionDetail.bind(offers);

        this.events();

        // The subscription detail overlay is already binded in the SubscriptionDetail Class
        // this._subscriptionDetailOverlay.bind();

        this._vfPassOverlay.bind();

        this.bindSlider();

    }

}
