/**
 * @module SalesFlow/view
 */
import {Renderable} from 'view/renderable';
import SimCardRepo from 'model/repo/simcard-repo';
import SlideMeIfYouCan from 'view/element/shared/slide-if-you-can';
import Offer from 'model/type/offer';
import SimCardOffer from 'view/view/shared/offer/simcard-offer';
import PurchasableDeviceRepo from 'model/repo/purchasable-device-repo';
import Injector from 'core/injector';

declare var $: JQueryStatic;

export default class SimCardList extends Renderable<SimCardOffer[]> {

    private _simCardTiles: JQuery;
    private _simCardRepo: SimCardRepo;

    private _slider: SlideMeIfYouCan;

    private _offer: Offer;
    private _simcardOffer: SimCardOffer;
    private _deviceRepo: PurchasableDeviceRepo;

    private _watchOnlySelector: JQuery;

    private _isWatchOnlySelected: boolean;

    constructor (tariffTiles: JQuery, injector: Injector) {

        super(injector);

        this._simCardTiles = tariffTiles.parent();

        this._slider = new SlideMeIfYouCan(
            $('#nsf-subscription-list-slide'),
            'tariff',
            injector
        );

        this._watchOnlySelector = $('#nsf-hardware-only-toggle');

    }

    private setWatchOnly (isWatchOnlySelected: boolean): void {
        this._isWatchOnlySelected = isWatchOnlySelected;

    }

    public getSimCardIds (): number[] {

        const simCardIds: number[] = [];

        this._simCardTiles.each((index, simCardTile) => {

            const simCardId: number = parseInt($(simCardTile).data('simcard-id'), 10);
            simCardIds.push(simCardId);
        });

        return simCardIds;
    }

    public render (offers: SimCardOffer[]): void {

        const deviceOverviewUrl = this.getInjector().getOptions().get('device_overview_url');
        const basketUrl = this.getInjector().getOptions().get('basket_url');

        for (const offer of offers) {
            const tile: JQuery = this._simCardTiles.find('.multisim-tile').filter('[data-simcard-id="' + offer.simCardId + '"]');

            tile.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials'));
            tile.find('.additional-text-bottom').html(this.getInjector().getTemplates().render('price-element-bottom', offer.onetimePrice, 'partials'));
        }

        this._slider.update();
        this._slider.updateHeight();
        this._slider.scrollToFocus();

        this._watchOnlySelector.html(
            this.getInjector().getTemplates().render('hardware_only', {})
        );
        this._watchOnlySelector.find('.selectionHwOnly').addClass('selectionWatchOnly');

        if (this._isWatchOnlySelected) {
            this._watchOnlySelector.find('.selectionHwOnly').addClass('selected');
        }
    }

    public events (): void {

        this._simCardTiles.on('click', '.multisim-tile .selectionRadio', (evt) => {

            const simcardTile: JQuery = $(evt.currentTarget).parents('.multisim-tile');
            if (true === simcardTile.hasClass('selected')) {
                return true;
            } else {
                simcardTile.addClass('selected');
            }

            const simcardId = simcardTile.data('simcard-id');

            this.getInjector().getFlow().setMultiSimId(simcardId);

            this._watchOnlySelector.find('.selectionHwOnly').removeClass('selected');

            if (simcardId === 3360) {
                this._injector.getEvent().trigger('multisim-product-detail@unselectAllDevices');
            }

        });

        this.getInjector().getEvent().listen('multisim@changed', (eventObject: JQueryEventObject, data: any) => {
            const simcardId = data.simcardId;
            this._simCardTiles.find('.multisim-tile.selected').removeClass('selected');

            // Show info alert box only on esim selection
            if (3362 === simcardId) {
                $('.alert-on-esim').removeClass('access');
            } else {
                $('.alert-on-esim').addClass('access');
            }

            if (undefined === simcardId) {
                return;
            }

            const watchCaroussel = $('#nsf-product-list-slide, .hide-on-physical-sim');
            if (3360 === simcardId) {
                watchCaroussel.hide();
            } else {
                watchCaroussel.show();
            }

            this._simCardTiles.find('.multisim-tile[data-simcard-id="' + simcardId + '"]').addClass('selected');

        });

        this._simCardTiles.on('click', '.subscription_add_card .tg-head, .mod-tariff-detail-akko .foot', (evt) => {
            setTimeout(() => {
                this._slider.updateHeight();
            }, 500);
        });

        this._simCardTiles.on('click', '.i-check', (evt) => {

            const subscriptionId = $(evt.currentTarget).parents('.tariff-module-tile').data('subscription-id');

            const addcardBoxChecked = $(evt.currentTarget).parents('.addcard-box').hasClass('selected');
        });

        this._watchOnlySelector.on('click', (evt) => {
            this.setWatchOnly(true);
            this.getInjector().getFlow().lockSubscription();

            $('#nsf-subscription-list-slide').find('div.tariff-module-tile.selected').removeClass('selected');

            this._watchOnlySelector.find('.selectionHwOnly').addClass('selected');

            const watchCaroussel = $('#nsf-product-list-slide, .hide-on-physical-sim');
            watchCaroussel.show();

            this.getInjector().getEvent().trigger('watchonly@selected');
        });

    }

    public setTimeouts (): JQueryPromise<any>  {
        const deferred = $.Deferred<any>();
        setTimeout(function () {
            deferred.resolve();
        }, 500);

        return deferred.promise();
    }

    public bind (): void {
        $.when(
            this.getInjector().getRepos().getSimCardRepo()
        ).done((simCardRepo: SimCardRepo) => {

            this._simCardRepo = simCardRepo;
            const simCardOffers: SimCardOffer[] = [];

            const that = this;
            this._simCardTiles.find('.multisim-tile').each(function () {
                const simCardId: number = $(this).data('simcard-id');
                const simCard = simCardRepo.getSimCard(simCardId);
                const offer = that.getInjector().getGeneralSalesObjectInterface().getSimCardOfferBySimCardId(
                    simCard.id,
                    that.getInjector().getBtx(),
                    that.getInjector().getFlowState().getSalesChannel());
                simCardOffers.push(new SimCardOffer(simCard, offer));
            });
            this.render(simCardOffers);

            this.events();

            this._slider.bind();
            this._slider.hideButtons();

            this.getInjector().getLoadingIndicator().hide();
        });
    }
}
