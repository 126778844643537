/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import ViewOffer from '../../view/shared/accessory-offer';

import CmAccessoryTile from './cm-accessory-tile';
import SlideMeIfYouCan from '../shared/slide-if-you-can';

export default class CmAccessories extends Renderable<ViewOffer[]> {

    private _element: JQuery;
    private _slider: SlideMeIfYouCan;

    constructor (injector: Injector) {
        super(injector);

        this._injector = injector;
        this._element = $('#cm-accessories');

        this._slider = new SlideMeIfYouCan(
            this._element.find('#nsf-accessories-list-slide'),
            'device',
            injector
        );
    }

    public render (accessoryOffers: ViewOffer[]): void {

        for (const accessoryOffer of accessoryOffers) {

            const accessoryTile = new CmAccessoryTile(this._injector, this._element.find('.device-module-tiles'));
            accessoryTile.bind(accessoryOffer);
        }

        this._element.show();

        this._slider.bind();
        this._slider.update();
        this._slider.updateHeight();

        /**
         * Set the height of each headline to the heighest one
         */
        let maxHeight = 0;

        this._element.find('.device-module-tile').each((index, element) => {

            const height = $(element).find('.h2').outerHeight();

            if (height > maxHeight) {

                maxHeight = height;

            }

        });

        this._element.find('.device-module-tile .h2').height(maxHeight);

    }

    public events (): void {

    }

}
