/**
 * @module SalesFlow/core
 */

import {CoreFlowState} from 'core/core-flow-state';
import {SalesChannelName} from 'core/ids';
import Routing from 'router/shared/routing';
import Storage from 'core/storage';
import Event from 'core/event';
import Options from 'core/options';
import Injector from 'core/injector';
import {Constants} from 'core/constants';

export interface SubscriptionIds {
    [index: string]: number;

    consumer: number;
    young: number;
    easy: number;
    soho: number;

}

export class CoreFlowStateWithoutSalesChannel extends CoreFlowState {

    protected _flowStateType = 'Bnt, Gigakombi or Multisim';

    constructor (injector: Injector) {

        super(injector);

        this.loadFromStorage();

        if (undefined === this._subscriptionGroup) {
            this._subscriptionGroup = this.getOptions().get('default_subscription_group');
        }

        this.setFirstFlow();

        this.log();

    }

    public getSalesChannel (): SalesChannelName {

        if ('easy' === this.getSubscriptionGroup()) {
            return Constants.SALESCHANNEL_CONSUMER;
        }

        return this.getSubscriptionGroup() as SalesChannelName;

    }

}
